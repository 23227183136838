@import url("https://fonts.googleapis.com/css?family=Muli|Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

.round_border_card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #00ad76;
  background-clip: border-box;
  border: none;
  box-shadow: 0 12px 15px rgba(0, 0, 0, 0.1), 0 17px 50px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
  padding: 1rem;
}
body {
  margin: 0;
  padding: 0;
  font-family: muli;
}
.shadow_card {
  margin: 10px 10px;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none;
  box-shadow: 0 12px 15px rgba(0, 0, 0, 0.1), 0 17px 50px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
}
.card_row {
  flex-direction: row !important;
}
.card_image {
  padding: 20px;
  display: flex;
}
.card_image img {
  width: 100%;
  border-radius: 20px;
  transition: 0.25s ease-in-out;
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  image-rendering: pixelated;
}
.contact_image_height {
  height: 550px;
  width: 100%;
  border-radius: 20px;
}
.card_content {
  padding: 16px;
}
.card_content h3 {
  font-size: 24px;
  line-height: 36px;
  color: #303133;
  font-weight: 500;
  margin-bottom: 20px;
  letter-spacing: -0.5;
}
.card_content p {
  font-size: 16px;
  color: #777;
  line-height: 36px;
}
.card_content a {
  text-decoration: none;
}
.card_content a h6 {
  font-size: 12px;
  letter-spacing: 1.5px;
  font-weight: 500;
  text-transform: uppercase;
  color: #c8a482;
  margin-bottom: 33px;
}
.card:hover .card_image img {
  transform: translatey(-3%);
}
.algo-tutorial-custo {
  display: flex;
  flex-direction: row !important;
}
.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50% !important;
  margin-top: 0px !important;
}

@media only screen and (min-device-width: 280px) and (max-device-width: 1020px) {
  .center-image {
    width: 100% !important;
  }
}
@media only screen and (min-device-width: 280px) and (max-device-width: 1020px) {
  .card_row {
    flex-direction: column !important;
  }
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: "Roboto", sans-serif;
}
.scroll-indicator {
  position: fixed;
  top: 0;
  left: 0;
  height: 4px;
  background-color: #0061a0;
  z-index: 2;
  width: 0%;
  transition: all 0.3s linear;
}
.content {
  display: grid;
  width: 60%;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.8em;
}
.content .poster {
  width: 100%;
  height: 350px;
  display: grid;
  border-radius: 10px;
  margin-top: 40px;
  overflow: hidden;
  background: center no-repeat;
  position: relative;
  box-shadow: 0px 4px 100px -3px #00059733;
}
.content .poster .poster-title {
  position: absolute;
  bottom: 40px;
  left: 40px;
  width: 40%;
}
.content .poster .poster-title h1 {
  font-size: 40px;
  color: white;
  font-weight: 300;
  margin: 20px 0;
}
.content .poster .poster-title .line {
  background-color: #b4c4c5;
  width: 100%;
  height: 1px;
}
.content .poster .poster-title p {
  color: #b4c4c5;
  font-size: 12px;
  line-height: 1.8em;
}
.content .poster .poster-buttons {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  color: white;
  text-align: center;
}
.content .poster .poster-buttons div {
  cursor: pointer;
}
.content .poster .poster-buttons div:hover {
  color: #ccc;
}
.footer {
  width: 100%;
  min-height: 150px;
  margin-top: 100px;
  padding: 100px 0;
  background: linear-gradient(0deg, #ffffff 0%, #efefef 100%);
}
.footer .content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  font-size: 14px;
  color: #777;
}
.info {
  border-radius: 10px;
  border: 1px solid #e9e9f6;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 40px auto;
  background-color: #fdfdfd;
  overflow: hidden;
  height: 60px;
}
.info .block {
  text-align: center;
  font-size: 22px;
  font-weight: 300;
  color: #242e47;
}
.info .block .mini-title {
  font-size: 12px;
  color: #999999;
  font-weight: 700;
}
.words {
  padding: 0 0 0 45px;
  position: relative;
}
.words p {
  margin: 20px auto;
  position: relative;
  color: #777;
}
.words p .buttons {
  position: absolute;
  right: 10px;
  width: 40px;
  height: 100px;
  background-color: #777;
}
.words p .letter {
  font-size: 100px;
  position: absolute;
  top: 15px;
  left: -45px;
  color: #8787870f;
  font-weight: 700;
}
.words .buttons {
  position: absolute;
  opacity: 0;
  top: 0px;
  right: -30px;
  font-size: 20px;
  transition: all 0.3s linear;
  cursor: pointer;
}
.words:hover .buttons {
  opacity: 1;
}
.quote {
  position: relative;
  padding: 40px 40px 40px 45px;
  background-color: #fdfdfd;
  border-radius: 10px;
  min-height: 140px;
  font-size: 22px;
  line-height: 1.5em;
  font-family: "Times New Roman", Times, serif;
  color: #777;
}
.quote .letter {
  font-size: 150px;
  position: absolute;
  top: 50px;
  color: #ebebeb;
}
.quote .author {
  font-size: 12px;
}
.quote .author::before {
  content: "";
  width: 40px;
  height: 1px;
}
@media (max-width: 700px) {
  .content {
    width: 90%;
  }
  .info {
    grid-template-columns: repeat(2, 1fr);
    height: 120px;
  }
  .content .poster {
    height: 250px;
  }
  .content .poster .poster-title h1 {
    font-size: 25px;
  }
  .content .poster .poster-title p,
  .content .poster .poster-title .line {
    display: none;
  }
  .quote {
    font-size: 16px;
  }
  .quote .letter {
    left: 0px;
  }
}

.max-lines {
  display: block; /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  /* max-height: 3.6em;
    line-height: 1.8em; */
}
/* ------------------------
    Padding
------------------------*/
.p-2 {
  padding: 20px !important;
}

.refresh-btn {
  /* position: fixed; */
  right: 0;
  bottom: 200;
  z-index: 9999;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #5cb85c;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.addGameBtn {
  border-radius: 10px;
  padding: 5px 5px;
  font-weight: 700;
  color: #5cb85c;
  background-color: white;
  text-align: center;
  vertical-align: baseline;
}

.white-header {
  color: #FFFFFF;
}