html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  background-color: #fff;
  color: #000;
  font-family: roboto-regular !important;
  font-size: 15px;
  overflow-x: hidden;
  font-weight: 400;
  font-display: swap !important;
}

html * {
  outline: 0;
  scroll-behavior: smooth;
}

ol,
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none !important;
  outline: none !important;
}

outline {
  border: 0;
}

.active {
  outline: 0;
}

.lft {
  float: left;
}

.rgt {
  float: right;
}

.clear {
  clear: both;
  line-height: 0;
  font-size: 0;
}

a,
input,
textarea {
  transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -webkit-transition: 0.5s all ease-in-out;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

.loader-screen {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 9999999;
  display: block;
  left: 0;
  right: 0;
}

#loader {
  -webkit-animation-name: spinner;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 2s;
  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  text-align: center;
  margin: 0 auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 30%;
  max-width: 300px;
}

.blck-tape a {
  color: #fff;
}

.blck-tape a:hover {
  color: #fff;
  text-decoration: none;
}

.cd-top {
  display: inline-block;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 60px;
  right: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: #ff3720 url() no-repeat center 50%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s 0s, visibility 0 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0 0.3s;
  transition: opacity 0.3s 0s, visibility 0 0.3s;
  border-radius: 50%;
  z-index: 999;
}

.cd-top:hover {
  background: #000 url() no-repeat center 50%;
}

.cd-top.cd-is-visible,
.cd-top.cd-fade-out,
.no-touch .cd-top:hover {
  -webkit-transition: opacity 0.3s 0s, visibility 0 0;
  -moz-transition: opacity 0.3s 0s, visibility 0 0;
  transition: opacity 0.3s 0s, visibility 0 0;
}

.cd-top.cd-is-visible {
  visibility: visible;
  opacity: 1;
}

.cd-top.cd-fade-out {
  opacity: 1;
}

.no-touch .cd-top:hover {
  background-color: #f9a11b;
  opacity: 1;
}

.main-header {
  background: rgba(1, 154, 83, 0.9);
  padding: 10px 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 0 100% 0;
  right: 0;
}

.menu-toggle {
  display: none;
}

.main-menu {
  text-align: right;
  padding: 12px 0 0;
}

.main-menu ul {
  margin: 0;
}

.left-slide-close {
  position: absolute;
  right: 23px;
  font-size: 26px;
  color: #fff;
  display: none;
}

.main-menu ul li {
  display: inline-block;
}

.main-menu ul li a {
  color: #fff;
  text-transform: uppercase;
  padding: 13px 11px;
  font-size: 17px;
  display: inline-block;
}

.gold-menu ul li a:hover {
  color: #fb0;
}

.gold-menu ul li.active a {
  color: #fb0;
}

.logo-area h1 {
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 30px;
}

.login-area a {
  color: #000;
  background: #fb0;
  padding: 9px 35px;
  display: inline-block;
  border-radius: 50px;
  font-size: 16px;
  margin: 0;
}

.login-area a:hover {
  color: #000;
}

.login-area {
  text-align: center;
  padding: 6px 0 8px;
  position: absolute;
  top: 135px;
  right: 125px;
}

.logout {
  text-align: right;
  position: inherit;
  right: 125px;
  z-index: 9999;
}

.logout a {
  color: #fff !important;
  padding: 0 !important;
  display: inline-block;
  font-size: 14px !important;
  margin: 8px 0 0;
}

.logout a i {
  padding: 0 5px 0 0;
}

.logout a:hover {
  color: #000;
}

.logout a i {
  padding: 0 5px 0 0;
}

.banner-area {
  position: relative;
}

.head-banner img {
  width: 100%;
  height: 380px;
  object-fit: cover;
}

.client {
  position: absolute;
  bottom: 0;
  left: 100px;
}

.client img {
  width: 34%;
}

.client-deta {
  padding: 5px 23px;
}

.client-deta h2 {
  font-size: 32px;
  text-transform: uppercase;
  color: #fff;
  margin: 0 0 12px;
}

.client-deta h2 i {
  padding: 0 6px 0 0;
  font-size: 29px;
}

.client-deta a {
  display: inline-block;
  color: #000;
  font-size: 21px;
}

.client-deta a i {
  padding: 0 8px 0 0;
  font-size: 25px;
}

.logout img {
  margin: 0 3px 5px 0;
}

.offer-area {
  background: rgba(1, 154, 83, 0.9);
  padding: 12px 50px;
}

.offer-area marquee {
  color: #fff;
  font-size: 25px;
}

.how-play-area {
  padding: 45px 0;
}

.how-left {
  background: #fff;
  padding: 30px 29px;
  display: inline-block;
  border-radius: 30px 0 30px 0;
  border-left: 4px solid #00ad76;
  border-bottom: 4px solid #00ad76;
  box-shadow: 0 0 15px -6px;
  width: 100%;
  min-height: 500px;
}

.how-left h2 {
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 600;
  color: #01439a;
  margin: 0;
}

.how-left h3 {
  font-size: 22px;
  margin: 12px 0 10px;
  color: #000;
}

.how-left p {
  line-height: 30px;
  font-size: 16px;
  margin: 17px 0 6px;
}

.how-right {
  background: #f3f3f3;
  padding: 30px 29px;
  display: inline-block;
  border-radius: 0 30px 0 30px;
  border-right: 4px solid #00ad76;
  border-bottom: 4px solid #00ad76;
  width: 100%;
  box-shadow: 0 0 15px -6px;
  min-height: 500px;
}

.how-right h2 {
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 600;
  color: #01439a;
  margin: 0 0 26px;
}

.how-right ul li {
  border-top: 1px solid #bfbfbf;
  margin: 0;
  padding: 10px 0;
}

.how-right ul li p {
  margin: 0;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 42px;
  color: #000;
}

.how-right ul li p span {
  float: right;
  display: inline-block;
}

.result-area {
  padding: 45px 0;
  background: url() no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.title h3 {
  text-align: center;
  margin: 0;
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 26px;
}

.single-result h2 {
  color: #fff;
  background: #fe005a;
  font-size: 26px;
  padding: 13px 15px;
  font-weight: 600;
}

.single-result h2 span {
  font-size: 12px;
  float: right;
  padding: 7px 0;
  color: #fff;
}

.single-result h2 {
  color: #fff;
  background: #00ad76;
  font-size: 19px;
  padding: 13px 15px;
  font-weight: 600;
  color: #fb0;
  border-radius: 8px 8px 0 0;
  text-align: center;
}

.single-result ul li {
  display: inline-block;
  width: 32%;
  text-align: center;
}

.single-result ul li p {
  color: #fff;
  padding: 16px 0;
  font-size: 29px;
  color: #000000;
}

.bold-sec {
  font-size: 41px !important;
  font-weight: 600;
}

.bold-sec button {
  font-size: 20px;
  font-weight: 6;
}

.single-result {
  background: #fff;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 0 22px -7px;
}

.game-win-area {
  background: url() no-repeat;
  padding: 45px 0;
}

.win-left {
  background: #fff;
  padding: 0 0 15px;
  box-shadow: 0 0 13px -3px;
  border-radius: 8px;
  height: 100%;
  border-radius: 8px;
  width: 100%;
  margin: 0 auto;
}

.time-table table {
  margin: 12px 17px;
  width: 95%;
}

.time-table table tr th {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  color: #01439a;
  border: 1px solid #bfbfbf;
  width: 59px;
  padding: 10px 0;
}

.time-table table tr td {
  text-align: center;
  color: #000;
  font-size: 15px;
  width: 64px;
  border: 1px solid #bfbfbf;
  padding: 9px 0;
}

.panel-detail table {
  text-align: center;
  color: #000;
  font-size: 15px;
  width: 64px;
  border: 1px solid #bfbfbf;
  padding: 9px 0;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}
.panel-detail table tr td {
  text-align: center;
  color: #000;
  font-size: 15px;
  /* width: 64px; */
  border: 1px solid #bfbfbf;
  padding: 9px 0;
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}
.panel-detail tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
}
.panel-detail th {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
  text-align: center;
  vertical-align: middle;
}
@media (max-width: 300px) {
  .panel-detail { 
      width: 100% !important;
      overflow-x: scroll; 
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  /* th, td {min-width: 300px; } */
}

.fatafat-card table {
  text-align: center;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  border: 1px solid #bfbfbf;
  padding: 12px 0;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  display: flex;
  overflow-x: scroll; 
}
@media (max-width: 700px) {
  .fatafat-card table{
    justify-content: start;
  }
}
.fatafat-card table tr td {
  text-align: center;
  color: #000;
  font-size: 15px;
  width: 100px;
  border: 1px solid #bfbfbf;
  padding: 12px 0;
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}
.fatafat-card table th {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  min-width: 100px;
  border: 1px solid #bfbfbf;
  padding: 12px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* @media (max-width: 300px) {
  .fatafat-card { 
      width: 100% !important;
      overflow-x: scroll; 
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  th, td {
    min-width: 300px; 
    justify-content: center;
    align-items: center;
  }
} */
.fatafat-card-row {
  display: flex;
  flex-direction: row;
}


.win-left h2 {
  font-size: 36px;
  font-weight: 600;
  background: #00ad76;
  margin: 0;
  padding: 17px 62px;
  color: #fb0;
  border-radius: 8px 8px 0 0;
}

.time-heading {
  background: #000;
  padding: 0 32px;
}

.time-heading ul li p {
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
}

.time-heading ul {
  margin: 0;
}

.time-heading ul li {
  display: inline-block;
  padding: 8px 100px 0 0;
}

.time-heading ul li:nth-child(3) {
  padding-right: 0;
}

.time-cont {
  padding: 0 30px;
}

.time-heading {
  background: #fff;
  padding: 11px 0;
  height: 55px;
  text-align: center;
  font-size: 20px;
  color: #fff;
}

.time-cont p {
  text-align: center;
  font-size: 18px;
  padding: 14px 0;
}

.win-right {
  background: #fff;
  box-shadow: 0 0 15px -3px;
  border-radius: 8px;
  padding: 0 0 6px;
}

.win-right h2 {
  font-size: 36px;
  background: #000;
  margin: 0;
  border-radius: 8px 8px 0 0;
  padding: 17px 26px;
  color: #fff;
  font-weight: 600;
}

.win-left h2 span {
  float: right;
  color: #fff;
}

.win-right ul li p {
  font-size: 18px;
  padding: 15px 24px;
  margin: 11px 18px;
  background: #000;
  color: #fff;
  position: relative;
}

.win-right ul li p i {
  color: #fff;
  padding: 0 10px 0 0;
  font-size: 23px;
}

.win-right h2 span {
  float: right;
}

.win-right ul li p span {
  float: right;
  color: #fff;
  font-weight: 600;
}

.single-time {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px -3px;
  margin: 20px 0 0;
}

.single-time h3 {
  margin: 0;
  background: #000;
  color: #fff;
  margin: 0;
  background: #fe005a;
  color: #fff;
  font-size: 20px;
  padding: 9px 14px;
  border-radius: 8px 8px 0 0;
  text-align: center;
}

.single-time p {
  color: #000;
  font-size: 14px;
  padding: 10px 8px;
}

.single-time p span {
  float: right;
}

.foot-area {
  background: #01439a;
  padding: 18px 0 81px;
  margin: 0;
}

.foot-left p {
  color: #fff;
  font-size: 16px;
  margin: 0;
  line-height: 35px;
}

.foot-mid {
  text-align: right;
}

.foot-mid ul li {
  display: inline-block;
}

.foot-mid ul {
  margin: 0;
}

.foot-mid ul li a {
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  margin: 0 6px;
}

.foot-right a {
  text-align: right;
  display: block;
  margin: 0;
  line-height: 35px;
}

.logo-area h2 {
  text-align: center;
  margin: 4px 0 6px;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
}

.main-win h2::after {
  background: url() no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 45px;
  height: 45px;
}

.all-win {
  background: url() no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.win-all {
  padding: 15px 11px;
  border-radius: 8px;
  background: rgba(1, 154, 83, 0.9);
  width: 60%;
  margin: 9px auto !important;
}

.main-win {
  padding: 0 !important;
  margin: 0 0 30px;
}

.all-win-main h1 {
  margin: 32px 0 17px;
  text-align: center;
  color: #fff;
}

.win-all {
  padding: 15px 11px;
  border-radius: 8px;
  margin: 0 0 8px;
  background: rgba(1, 154, 83, 0.9);
}

.win-all h2 {
  color: #fff;
  font-size: 24px;
  line-height: 26px;
  margin: 0;
  position: relative;
  padding: 0 0 0 58px;
}

.win-all p {
  color: #fb0;
  padding: 5px 0 0 55px;
  margin: 0;
  font-size: 17px;
}

.social-ara {
  bottom: 17px;
  left: 111px;
}

.social-ara ul li {
  display: inline-block;
  width: 5%;
  padding: 0 15px 0 0;
}

.social-ara ul {
  margin: 13px auto 17px;
  text-align: center;
}

.offer-area h1 {
  text-align: center;
  font-size: 36px;
  color: #fff;
}

.demo {
  text-align: center;
  font-size: 30px;
  color: #fff;
  font-weight: 600;
}

.left-time {
  float: left !important;
}

.single-result ul li {
  animation: shake 4s;
  animation-iteration-count: infinite;
  transition: all ease-in-out 0.6s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.main-register-pop form input[type="submit"]:hover {
  background: #d8d7d7 !important;
  color: #000;
}

.all-option2 form input[type="submit"]:hover {
  background: #d8d7d7 !important;
  color: #000;
}

.all-option form input[type="submit"]:hover {
  background: #d8d7d7 !important;
  color: #000;
}

.main-login-pop form input[type="submit"]:hover {
  background: #d8d7d7 !important;
  color: #000;
}

.main-forgot-pop form input[type="submit"]:hover {
  background: #d8d7d7 !important;
  color: #000;
}

.chat form input[type="submit"]:hover {
  background: #d8d7d7 !important;
  color: #000;
}

.big-win {
  padding: 40px 0;
  background: url() no-repeat;
}

.big-win h1 {
  margin: 0;
  text-align: center;
  text-transform: capitalize;
  font-size: 30px;
  font-weight: 600;
  color: #000;
}

.big-win-main {
  width: 60% !important;
  margin: 37px auto;
  background: #fff;
  border-radius: 10px;
  border-right: 5px solid #fb0;
  border-left: 5px solid #fb0;
  padding: 20px 30px;
  box-shadow: 0 0 34px -9px;
}

.online-m-king h2 {
  margin: 0 0 14px;
  font-weight: 600;
  color: #01439a;
  position: relative;
  padding: 0;
}

.online-m-king img {
  width: 95% !important;
  padding: 30px 0 0;
}

.online-m-king p {
  font-size: 17px;
  margin: 0;
  line-height: 27px;
  color: #000;
  padding: 0;
}

.online-m-king h3 {
  margin: 10px 0 0;
  font-size: 30px;
  color: #01439a;
  font-weight: 600;
  padding: 0;
}

.pop-login-area {
  background: #000 url() no-repeat;
  top: 0;
  left: 0;
  z-index: 9999;
  right: 0;
  margin: 0 auto;
  text-align: center;
  height: 100%;
  position: fixed;
  display: none;
}

.main-login-pop h2 {
  color: #fff;
  font-size: 30px;
  text-transform: uppercase;
  margin: 0 0 32px;
  font-weight: 600;
}

.main-login-pop form input[type="text"],
.main-login-pop form input[type="password"] {
  width: 100%;
  padding: 22px 30px;
  border-radius: 50px;
  border: 0;
  font-size: 17px;
  color: #000;
  margin: 0 0 15px;
}

.main-login-pop form input[type="submit"] {
  width: 50%;
  padding: 22px 30px;
  border-radius: 50px;
  border: 0;
  font-size: 17px;
  color: #000;
  margin: 0 0 15px;
  background: #fb0;
}

.main-login-pop {
  width: 43%;
  margin: 35px auto;
  padding: 25px 30px;
  border: 1px solid #fff;
  border-radius: 30px;
}

.pop-close {
  color: #fb0;
  position: absolute;
  right: 62px;
  top: 30px;
  border: 1px solid #fb0;
  padding: 8px 21px;
  border-radius: 50px;
  cursor: pointer;
}

.main-login-pop h3 a {
  color: #0a66e1;
  margin: 0 0 24px;
  display: block;
}

.main-login-pop h4 a {
  color: #0a66e1;
  padding: 0 5px;
}

.main-login-pop h4 {
  color: #fff;
  font-size: 19px;
}

.pop-register-area {
  background: #000 url() no-repeat;
  top: 0;
  left: 0;
  z-index: 9999;
  right: 0;
  margin: 0 auto;
  text-align: center;
  height: 100%;
  position: fixed;
  display: none;
}

.pop-back {
  position: absolute !important;
  top: -67px !important;
  right: -51px !important;
}

.main-register-pop h2 {
  color: #fff;
  font-size: 30px;
  text-transform: uppercase;
  margin: 0 0 32px;
  font-weight: 600;
}

.main-register-pop form input[type="text"],
.main-register-pop form input[type="password"] {
  width: 100%;
  padding: 19px 20px;
  border-radius: 50px;
  border: 0;
  font-size: 17px;
  color: #000;
  margin: 0 0 15px;
}

.main-register-pop form input[type="submit"] {
  width: 50%;
  padding: 22px 20px;
  border-radius: 50px;
  border: 0;
  font-size: 17px;
  color: #000;
  margin: 0 0 15px;
  background: #fb0;
}

.main-register-pop {
  width: 43%;
  margin: 29px auto;
  padding: 24px 30px;
  border: 1px solid #fff;
  border-radius: 30px;
}

.pop-close {
  color: #fb0;
  position: absolute;
  right: 62px;
  top: 30px;
  border: 1px solid #fb0;
  padding: 8px 21px;
  border-radius: 50px;
  cursor: pointer;
}

.main-register-pop h3 a {
  color: #0a66e1;
  margin: 0 0 24px;
  display: block;
}

.main-register-pop h4 a {
  color: #0a66e1;
  padding: 0 5px;
}

.main-register-pop h4 {
  color: #fff;
  font-size: 19px;
}

.pop-forgot-area {
  background: #000 url() no-repeat;
  top: 0;
  left: 0;
  z-index: 9999;
  right: 0;
  margin: 0 auto;
  text-align: center;
  height: 100%;
  position: fixed;
  display: none;
}

.main-forgot-pop h2 {
  color: #fff;
  font-size: 30px;
  text-transform: uppercase;
  margin: 0 0 32px;
  font-weight: 600;
}

.main-forgot-pop form input[type="text"],
.main-forgot-pop form input[type="password"] {
  width: 100%;
  padding: 22px 20px;
  border-radius: 50px;
  border: 0;
  font-size: 17px;
  color: #000;
  margin: 0 0 15px;
}

.main-forgot-pop form input[type="submit"] {
  width: 50%;
  padding: 22px 20px;
  border-radius: 50px;
  border: 0;
  font-size: 17px;
  color: #000;
  margin: 0 0 15px;
  background: #fb0;
}

.main-forgot-pop {
  width: 43%;
  margin: 71px auto;
  padding: 45px 30px;
  border: 1px solid #fff;
  border-radius: 30px;
}

.main-forgot-pop h3 a {
  color: #0a66e1;
  margin: 0 0 24px;
  display: block;
}

.main-forgot-pop h4 a {
  color: #0a66e1;
  padding: 0 5px;
}

.main-forgot-pop h4 {
  color: #fff;
  font-size: 19px;
}

.pop-otp-area {
  background: #000 url() no-repeat;
  top: 0;
  left: 0;
  z-index: 9999;
  right: 0;
  margin: 0 auto;
  text-align: center;
  height: 100%;
  position: fixed;
  display: none;
}

.main-otp-pop h2 {
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  margin: 0 0 27px;
}

.main-otp-pop form input[type="text"],
.main-otp-pop form input[type="password"] {
  width: 100%;
  padding: 14px 20px;
  border-radius: 4px;
  border: 0;
  font-size: 15px;
  color: #000;
  margin: 0 0 8px;
}

.main-otp-pop form input[type="submit"] {
  width: 100%;
  padding: 15px 20px;
  border-radius: 4px;
  border: 0;
  font-size: 16px;
  color: #000;
  margin: 0 0 15px;
  background: #fb0;
}

.main-otp-pop {
  width: 85%;
  margin: 71px auto;
  padding: 27px 18px;
  border: 1px solid #fff;
  border-radius: 30px;
}

.main-otp-pop h3 a {
  color: #0a66e1;
  margin: 0 0 24px;
  display: block;
}

.main-otp-pop h4 a {
  color: #0a66e1;
  padding: 0 5px;
}

.main-otp-pop h4 {
  color: #fff;
  font-size: 19px;
}

.single-game-area {
  padding: 0 0 50px;
}

.inn-banner-area {
  position: inherit !important;
  margin: 0;
  background: #fb0;
}

.left-wal h2 {
  text-align: right;
  font-size: 20px;
  margin: 15px 0;
  color: #fff;
}

.left-user h2 {
  text-align: left;
  font-size: 20px;
  margin: 15px 0;
  color: #fff;
}

.sing-gam {
  background: #d2e6ff;
  padding: 12px 9px;
  border-radius: 6px;
  font-size: 16px;
  color: #000;
  margin: 0 0 5px;
  height: 113px;
}

.sec-exp {
  padding: 0 2px 0 0 !important;
}

.sing-gam h2 {
  margin: 0;
}

.game-time {
  width: 100%;
  padding: 3px 0 0;
  display: inherit;
  font-size: 11px;
}

.sing-gam p {
  margin: 0;
  font-size: 13px;
}

.sing-gam span input {
  padding: 0 16px 0 0;
  margin: 0 4px 0 0;
}

.game-title h2 {
  text-align: center;
  font-size: 33px;
  margin: 26px 0;
  color: #000;
}

.sing-gam-clo {
  background: #e7e4e4;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.errormessage {
  background: #fcb6b6;
  color: #9a0000;
  border-radius: 3px;
  box-shadow: 0 0 14px -3px rgba(0, 0, 0, 0.75);
  width: 650px;
}

.errormessage,
.successmessage {
  -webkit-box-shadow: 0 0 14px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 14px -3px rgba(0, 0, 0, 0.75);
  -o-box-shadow: 0 0 14px -3px rgba(0, 0, 0, 0.75);
  border: 5px solid #c2c2c2;
  height: 50px;
  left: 50%;
  z-index: 9999;
  margin-left: -330px;
  margin-top: -30px;
  padding: 10px;
  position: fixed;
  top: 50%;
}

.successmessage {
  background: #befebe;
  color: #005b00;
  border-radius: 3px;
  box-shadow: 0 0 14px -3px rgba(0, 0, 0, 0.75);
  width: 650px;
}

.errormessage div,
.successmessage div {
  display: inline-block;
  margin-left: 10px;
}

.successmessage span {
  display: inline-block;
}

.errormessage span {
  display: inline-block;
}

.closebutn {
  float: right;
  cursor: pointer;
}

.toppanel_txt {
  margin-right: 0 !important;
}

.all-option {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 106px 0 45px;
  box-shadow: 0 0 16px -5px;
  border-top: 5px solid rgba(1, 154, 83, 0.9);
  border-bottom: 5px solid rgba(1, 154, 83, 0.9);
}

.all-option h2 {
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  color: #000;
}

.nc-che {
  margin: 0 9px 0 0 !important;
}

.play-butt input[type="submit"] {
  width: 33%;
  padding: 13px 0;
  font-size: 17px;
  background: rgba(1, 154, 83, 0.9);
  color: #fff;
  border-radius: 50px;
  border: 0;
  margin: 20px 0 15px;
}

.play-butt {
  margin: 0 auto;
  display: block;
  text-align: center;
}

.sele-area form select {
  width: 40%;
  padding: 14px 10px;
  border-radius: 4px;
  border: 1px solid #000;
  margin: 9px 0 34px;
  color: #000;
}

.sing1 span {
  background: #fff;
  border: 1px solid rgba(1, 154, 83, 0.9);
  padding: 10px 18px;
  border-radius: 6px 0 0 6px;
  background: rgba(1, 154, 83, 0.9);
  font-size: 19px;
  color: #fff;
}

.sing1 ul li {
  display: inline;
  margin: 0 8px 0 0;
}

.sing1 input {
  padding: 14px 15px 9px 9px;
  border-radius: 0 6px 6px 0;
  border: 1px solid rgba(1, 154, 83, 0.9);
  width: 20%;
  margin: 0 0 6px;
}

.all-option h4 {
  text-align: center;
  color: #000;
  text-transform: capitalize;
  font-size: 23px;
  padding: 16px 0 0;
}

.all-option h4 span {
  padding: 0 0 0 11px;
  color: #000;
}

.popup {
  background: #fff;
  box-shadow: 0 0 19px -4px;
  border-radius: 10px;
  text-align: center;
  width: 38%;
  margin: 0 auto;
  position: absolute;
  top: 56%;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
}

.popup h2 {
  margin: 22px 0 12px;
  color: #1a61bf;
  font-weight: 600;
}

.play-game-area {
  position: relative;
}

.popup p {
  font-size: 16px;
  color: #000;
  padding: 0 0 7px;
}

.sele-area select {
  width: 100%;
  height: 52px;
  padding: 0 17px;
  border-radius: 6px;
  border: 1px solid #000;
  margin: 9px 0 33px;
}

.sele-date-area select {
  width: 100%;
  height: 50px;
  border-radius: 6px;
  border: 1px solid #000;
  padding: 0 17px;
  margin: 9px 0 0;
}

.all-option2 {
  width: 57%;
  margin: 112px auto 52px;
  background: #e7e7e7;
  border-radius: 20px;
  padding: 30px 44px;
}

.all-option2 h2 {
  text-align: center;
  margin: 0;
  color: #000;
  font-weight: 600;
}

.all-option2 h3 {
  text-align: center;
  padding: 17px 0 11px;
}

.all-option2 .sele-area form select {
  width: 100%;
  margin: 8px 0 46px;
}

.number-area {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #c9c8c8;
  height: 244px;
}

.number-area ul li span {
  font-size: 19px;
  color: #000;
}

.number-area ul li {
  padding: 6px 24px;
  border-bottom: 1px solid #dedada;
}

.number-area ul {
  margin: 0;
  padding: 5px 0;
  overflow-y: auto;
  height: 241px;
}

.number-area ul li:hover {
  cursor: pointer;
  background: rgba(1, 154, 83, 0.9);
}

.num.select-cls {
  background: rgba(1, 154, 83, 0.9);
}

.num.select-cls span {
  color: #fff;
}

.number-area ul li:hover span {
  color: #fff;
}

.all-option2 h4 {
  text-align: center;
  margin: 43px 0 6px;
  text-transform: capitalize;
  color: #000;
  font-size: 23px;
}

.show-input input[type="number"] {
  background: #fff;
  border: 1px solid rgba(1, 154, 83, 0.9);
  padding: 11px 2px 7px 7px;
  margin: 0 0 9px;
  width: 18%;
}

.show-input .b-num {
  background: rgba(1, 154, 83, 0.9);
  color: #fff;
  font-size: 17px;
  padding: 10px;
  border-radius: 6px 0 0 6px;
}

.show-input {
  margin: 30px 0 0;
}

.show-input .d-box {
  background: rgba(1, 154, 83, 0.9);
  color: #fff;
  font-size: 17px;
  padding: 10px 8px;
  border-radius: 0 6px 6px 0;
  cursor: pointer;
}

.show-input p {
  display: inline;
  margin: 0 2px;
}

.select-cls {
  background: #6d0000;
}

.how-to-p {
  margin: 106px 0 0;
}

.g-vider iframe {
  width: 100%;
  height: 560px;
}

.g-content h2 {
  margin: 0 0 17px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(1, 154, 83, 0.9);
  font-size: 20px;
}

.g-content p {
  font-size: 14px;
  line-height: 30px;
}

.g-content h4 {
  font-size: 18px;
  font-weight: 600;
  color: rgba(1, 154, 83, 0.9);
}

.g-content h5 {
  font-size: 18px;
  color: #1751b6;
  font-weight: 600;
}

.g-content h3 {
  font-size: 19px;
  color: rgba(1, 154, 83, 0.9);
}

.g-content h1 {
  font-size: 20px;
  margin: 15px 0 30px;
  color: #000;
}

.g-content h1 a {
  color: rgba(1, 154, 83, 0.9);
  padding: 18px 0 0;
  display: block;
  font-size: 24px;
  text-align: center;
}

.h-hindi p {
  font-size: 17px;
}

.g-content.h-hindi h6 {
  font-size: 19px;
  font-weight: 600;
  margin: 19px 0 13px;
  color: rgba(1, 154, 83, 0.9);
}

.h-hindi {
  border-top: 1px solid #ccc;
  padding: 23px 0 0;
}

.inner-header {
  position: inherit !important;
}

.row.offer-area h2 span a {
  color: #fff;
  font-weight: 600;
}

.main-body {
  background: url() repeat;
  background: #f0f0f0;
}

.foot-mid p {
  color: #fff;
  font-size: 23px;
  text-align: center;
}

.main-register-pop p {
  font-size: 16px;
  color: #fff;
}

.matka-terms {
  width: 60%;
  margin: 40px auto;
  background: #ece9e9;
  padding: 30px 40px;
}

.matka-terms h2 {
  text-transform: uppercase;
  margin: 0 0 36px;
  font-weight: 600;
  color: #1a55a4;
}

.matka-terms p {
  font-size: 16px;
  line-height: 27px;
  margin: 10px 0;
  color: #000;
}

.fore-but {
  background: rgba(1, 154, 83, 0.9);
  padding: 5px 0;
  text-align: center;
}

.ref-but {
  background: #e5cc4e;
  padding: 5px 0;
  text-align: center;
}

.ref-but p a {
  color: #000;
  text-transform: capitalize;
  font-size: 15px;
}

.fore-but p a {
  color: #fb0;
  text-transform: capitalize;
  font-size: 15px;
}

.fore-but p {
  margin: 0;
  color: #fff;
  text-transform: capitalize;
}

.ref-but p {
  margin: 0;
  color: #000;
  text-transform: capitalize;
}

.fore-but i {
  color: #fff;
  font-size: 20px;
}

.ref-but i {
  color: #000;
  font-size: 20px;
}

.reffer-area {
  position: absolute;
  bottom: 0;
  position: fixed;
  width: 100%;
  left: 15px;
  z-index: 9999;
}

.refer-area {
  background: url() no-repeat;
  padding: 45px 0;
  background-size: cover;
}

.ref-top {
  background: #fff;
  padding: 30px 35px;
  border-radius: 15px;
  width: 60%;
  margin: 0 auto;
  border-left: 7px solid #e1a707;
}

.ref-top h2 {
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  color: #1a55a4;
  border-top: 2px solid #1a55a4;
  border-bottom: 2px solid #1a55a4;
  padding: 10px 0;
  text-align: center;
  font-size: 30px;
  background: #eae9e9;
  border-radius: 50px;
}

.ref-top h2 span i {
  font-size: 35px;
  padding: 0 12px 0 0;
  color: #e1a707;
}

.ref-top h3 {
  margin: 26px 0 23px;
  font-size: 25px;
  line-height: 36px;
  color: #000;
  font-weight: 600;
}

.ref-top h4 {
  font-size: 21px;
  color: #000;
  margin: 0 0 15px;
}

.ref-top p {
  font-size: 17px;
  padding: 0 0 8px;
  line-height: 28px;
}

.ref-top p b {
  color: #1a55a4;
}

.ref-top h5 {
  font-size: 23px;
  font-weight: 600;
  line-height: 35px;
}

.refe-num-area {
  background: url() no-repeat;
  padding: 45px 0;
  background-size: cover;
}

.invi-area {
  background: #01439a;
  width: 60%;
  margin: 0 auto;
  border-radius: 15px;
  padding: 30px 40px;
  border-right: 7px solid #e1a707;
}

.invi-area h2 {
  text-align: center;
  margin: 0 0 54px;
  color: #e1a707;
  font-weight: 600;
  font-size: 30px;
  border-top: 2px solid #e1a707;
  border-bottom: 2px solid #e1a707;
  padding: 13px 0;
  background: rgba(225, 225, 225, 0.2);
  border-radius: 50px;
}

.invi-area h2 span i {
  padding: 0 12px;
}

.invi-area form input[type="number"] {
  width: 100%;
  padding: 22px 30px;
  border-radius: 50px;
  border: 0;
  font-size: 15px;
  margin: 0 0 23px;
}

.invi-area form input[type="submit"] {
  width: 50%;
  padding: 22px 30px;
  border-radius: 50px;
  border: 0;
  background: #e1a707;
  font-size: 19px;
  font-weight: 600;
  margin: 0 auto;
  text-align: center;
  display: block;
}

.refe-sta-area {
  background: url() no-repeat;
  padding: 45px 0;
  background-size: cover;
}

.stat-area {
  background: #fff;
  width: 60%;
  margin: 0 auto;
  padding: 30px 40px;
  border-radius: 15px;
  border-left: 6px solid #e1a707;
}

.stat-area h2 {
  text-align: center;
  margin: 0 0 33px;
  color: #1a55a4;
  border-top: 2px solid #1a55a4;
  border-bottom: 2px solid #1a55a4;
  padding: 12px 0;
  border-radius: 50px;
  background: #efefef;
}

.stat-area h2 span i {
  padding: 0 9px 0 0;
}

.stat-area p {
  margin: 0;
  padding: 7px 0;
  font-size: 20px;
  text-align: center;
  color: #000;
}

.stat-area p span {
  color: #01439a;
  font-weight: 600;
  padding: 0 0 0 12px;
}

.hindi {
  border-top: 1px solid #ccc;
  margin: 32px 0 0;
}

.forum-area {
  background: url() no-repeat;
  padding: 24px 0;
  background-size: cover;
  margin: 69px 0 0;
}

.for-top {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  padding: 16px 15px;
  border-radius: 15px;
  border-top: 6px solid rgba(1, 154, 83, 0.9);
  border-bottom: 6px solid rgba(1, 154, 83, 0.9);
}

.for-top h2 {
  margin: 0 0 27px;
  border-bottom: 2px solid rgba(1, 154, 83, 0.9);
  color: rgba(1, 154, 83, 0.9);
  font-size: 20px !important;
  font-weight: 600;
  padding: 7px 0 18px;
}

.for-top ul li p {
  font-size: 18px;
  color: #000;
  line-height: 34px;
}

.for-top ul li p i {
  padding: 0 5px 0 0;
  color: #6d0000;
}

.for-top h2 span i {
  padding: 0 14px 0 0;
}

.forum-chat-area {
  background: url() no-repeat;
  padding: 45px 0;
  background-size: cover;
}

.chat {
  background: #6d0000;
  width: 60%;
  margin: 0 auto;
  border-radius: 0 20px 0 20px;
  padding: 30px 40px;
}

.chat form input[type="text"] {
  width: 100%;
  border-radius: 8px;
  border: 0;
  padding: 19px 0 107px 23px;
  margin: 0 0 17px;
}

.chat form input[type="submit"] {
  padding: 13px 32px;
  font-size: 17px;
  text-transform: capitalize;
  border-radius: 4px;
  border: 0;
  background: #f8a20e;
  color: #fff;
}

.chat-user {
  background: #e5cc4e;
  width: 100%;
  margin: 0 auto 20px;
  border-radius: 50px;
  padding: 14px 24px;
  color: #000;
  border-left: 6px solid rgba(1, 154, 83, 0.9);
  border-right: 6px solid rgba(1, 154, 83, 0.9);
}

.chat-user h2 {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
}

.chat-user h2 span {
  float: right;
  color: #fff;
}

.sing-post {
  width: 60%;
  margin: 20px auto;
  background: #fff;
  border-radius: 8px;
  padding: 15px 40px;
  border-top: 4px solid rgba(1, 154, 83, 0.9);
  border-bottom: 4px solid rgba(1, 154, 83, 0.9);
  box-shadow: 0 0 17px -5px;
}

.sing-post p {
  color: #000;
  font-size: 17px;
  line-height: 28px;
}

.sing-post h4 {
  color: rgba(1, 154, 83, 0.9);
  margin: 24px 0 0;
  font-size: 15px;
}

.sing-post h4 span {
  float: right;
  font-size: 13px;
  color: #000;
}

.copy-area {
  margin: 0 auto 20px;
  width: 60%;
  padding: 30px 40px;
  border-radius: 15px;
  background: #01439a;
  border-left: 6px solid #e1a707;
}

.copy-area h2 {
  text-align: center;
  margin: 0 0 40px;
  color: #e1a707;
  font-size: 30px;
  font-weight: 600;
  border-top: 2px solid #e1a707;
  border-bottom: 2px solid #e1a707;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  padding: 13px 0;
}

.copy-area form textarea {
  width: 100%;
  border-radius: 50px;
  border: 0;
  vertical-align: middle;
  height: 57px;
  padding: 17px 29px;
}

.copy-area form input[type="submit"] {
  border: 0;
  border-radius: 50px;
  padding: 18px 40px;
  font-size: 17px;
  background: #e1a707;
  font-weight: 600;
  text-align: center;
  display: block;
  margin: 21px auto 0;
}

.copy-area h2 span i {
  padding: 0 12px 0 0;
}

ul.tsc_pagination li a {
  border: solid 1px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  padding: 6px 9px;
}

ul.tsc_pagination li {
  padding-bottom: 1px;
}

ul.tsc_pagination li a:hover,
ul.tsc_pagination li a.current {
  color: #fff;
  box-shadow: 0 1px #ededed;
  -moz-box-shadow: 0 1px #ededed;
  -webkit-box-shadow: 0 1px #ededed;
}

ul.tsc_pagination {
  padding: 15px 397px;
  height: 100%;
  overflow: hidden;
  font: 15px Tahoma;
  list-style-type: none;
}

ul.tsc_pagination li {
  margin: 0;
  padding: 0;
  margin-left: 5px;
}

.tsc_pagination li a {
  display: inline-block;
  float: left;
  margin: 0 3px;
}

ul.tsc_pagination li a {
  color: #000;
  display: block;
  text-decoration: none;
  padding: 7px 15px;
}

ul.tsc_pagination li a img {
  border: 0;
}

ul.tsc_pagination li a {
  color: #000;
  border-color: rgba(1, 154, 83, 0.9);
  background: #f8fcff;
  margin: 0 4px 4px 0;
}

ul.tsc_pagination li a:hover,
ul.tsc_pagination li a:hover,
ul.tsc_pagination li a.current {
  text-shadow: 0 1px #9b0000;
  border-color: #9b0000;
  background: #58b0e7;
  background: -moz-linear-gradient(top, #b4f6ff 1px, #63d0fe 1px, #58b0e7);
  background: #9b0000;
}

#pagination {
  margin: 40px 40px 0;
}

.Regis-area a {
  background: #01439a;
  color: #fff;
  padding: 11px 26px;
  border-radius: 50px;
  font-size: 15px;
}

.Regis-area a:hover {
  color: #fff;
}

.Regis-area a i {
  padding: 0 4px 0 0;
}

.Regis-area {
  position: absolute;
  right: 124px;
  top: 195px;
  z-index: 99;
}

.login-with ul li {
  display: inline-block;
}

.login-with ul li img {
  width: 90%;
  text-align: center;
  margin: 11px 0;
}

#fexample1_length label {
  padding: 0 0 21px;
  text-transform: capitalize;
  font-size: 17px;
}

#fexample1_length label select {
  margin: 0 6px;
  padding: 3px 4px;
  border-radius: 4px;
  border: 1px solid #bab9b9;
}

#fexample1_filter label input {
  padding: 7px;
  border-radius: 4px;
  border: 1px solid #b6b4b4;
  margin: 0 0 0 8px;
}

#fexample1 thead {
  background: #6d0000;
  color: #fff;
}

#fexample1 thead tr th {
  padding: 15px 30px;
  font-size: 16px;
  text-transform: uppercase;
  border: 1px solid #6d0000;
  text-align: center;
}

#fexample1 tbody tr td {
  padding: 11px 12px;
  text-align: center;
}

#fexample1_filter label {
  font-size: 16px;
}

#fexample1_paginate span a {
  background: #6c6c6c;
  color: #fff;
  width: 30px;
  display: inline-block;
  height: 30px;
  text-align: center;
  border-radius: 50px;
  line-height: 30px;
  margin: 0 0 0 6px;
  cursor: pointer;
}

.paginate_button.current {
  background: #6d0000 !important;
}

@font-face {
  font-family: roboto-bold;
  src: url() format("embedded-opentype"), url() format("woff"),
    url() format("truetype"), url() format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: roboto-medium;
  src: url() format("embedded-opentype"), url() format("woff"),
    url() format("truetype"), url() format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: roboto-regular;
  src: url() format("embedded-opentype"), url() format("woff"),
    url() format("truetype"), url() format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: trajanpro-bold;
  src: url() format("embedded-opentype"), url() format("opentype"),
    url() format("woff"), url() format("truetype"), url() format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  background-color: #fff;
  color: #000;
  font-family: roboto-regular !important;
  font-size: 15px;
  overflow-x: hidden;
  font-weight: 400;
}

html * {
  outline: 0;
}

ol,
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none !important;
  outline: none !important;
}

outline {
  border: 0;
}

.active {
  outline: 0;
}

.lft {
  float: left;
}

.rgt {
  float: right;
}

.clear {
  clear: both;
  line-height: 0;
  font-size: 0;
}

a,
input,
textarea {
  transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -webkit-transition: 0.5s all ease-in-out;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

.top-header {
  background: #01439a;
}

.head-area h3 {
  text-align: center;
  margin: 20px 0 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
}

.head-area h1 {
  text-align: center;
  color: #ffd800;
  margin: 11px 0 4px;
  font-family: trajanpro-bold;
  font-size: 22px;
  font-weight: 700;
}

.banner-area2 {
  position: relative;
}

.row.banner-area2 img {
  width: 100%;
}

.android-app {
  text-align: center;
}

.banner-text2 {
  position: absolute;
  top: 254px;
  left: 50%;
  z-index: 999;
}

.banner-text2 p {
  color: #fff;
  font-size: 12px;
  width: 96%;
}

.row.key-area {
  background: #02449a;
  padding: 15px 0;
}

.row.kew-text p {
  color: #fff;
}

.live-area {
  background: url() no-repeat;
  object-fit: cover;
  padding: 0 0 30px;
  background-size: cover;
}

.live-u h2 {
  font-size: 27px;
  text-align: center;
  font-family: trajanpro-bold;
  margin: 21px 0;
  position: relative;
}

.live-u h2::after {
  background: url() no-repeat;
  position: absolute;
  width: 30px;
  height: 27px;
  content: "";
  z-index: 999;
  top: -4px;
  right: 18px;
}

.live-u h2::before {
  background: url() no-repeat;
  position: absolute;
  width: 30px;
  height: 27px;
  content: "";
  z-index: 999;
  top: -4px;
  left: 22px;
}

.time-b h3 {
  text-align: center;
  text-transform: uppercase;
}

.time-b h1 {
  text-align: center;
  font-weight: 600;
  color: #1363cc;
}

.element {
  margin: 0 auto;
  animation-name: stretch;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}

@keyframes stretch {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1.2);
  }
}

.live-result {
  background: url() no-repeat;
  padding: 30px 0;
  background-size: cover;
}

.row.sing-result h2 {
  color: #fec500;
  font-size: 20px;
  font-family: trajanpro-bold;
  text-align: center;
  font-weight: 600;
  margin: 0 0 23px;
}

.result h3 {
  text-align: center;
  color: #fec500;
  margin: 16px 0 0;
}

.result h1 {
  text-align: center;
  color: #fff;
  font-weight: 600;
  margin: 10px 0;
}

.result p {
  color: #fff;
  font-size: 20px;
  text-align: center;
}

.result {
  border-bottom: 1px solid #456ca5;
  padding: 0 0 2px;
}

.matka-chat-area {
  background: url() no-repeat;
  background-size: cover;
  padding: 40px 0;
}

.row.chat-head p {
  font-size: 20px;
  text-align: center;
  line-height: 35px;
}

.row.chat-head h3 {
  text-align: center;
  margin: 18px 0 46px;
}

.june-area {
  background: url() no-repeat;
  background-size: cover;
  padding: 18px 0;
}

.title h3 {
  text-align: center;
  margin: 10 0 16px;
  padding: auto;
  color: #fb0;
}

.title h2 {
  text-align: start;
  margin: 10 0 16px;
  padding: auto;
  color: #fb0;
}
.single-result h2 {
  text-align: center;
  margin: 10 0 16px;
  padding: auto;
  color: #fb0;
}

.title button {
  text-align: right;
  margin: -4px 5px 0px;
}

.text-june ul li p {
  color: #000;
  text-align: center;
  font-size: 17px;
  line-height: 27px;
}

.text-june ul li {
  border-bottom: 1px solid #b3b3b3;
  padding: 5px 0 2px;
}

.row.june-booking h4 {
  color: #000;
  text-align: center;
  margin: 3px 0 0;
  font-size: 20px;
}

.row.june-booking h3 {
  text-align: center;
  margin: 14px 0 0;
  color: #000;
  font-size: 17px;
}

.row.june-booking h1 {
  text-align: center;
  color: #1b24d8;
  font-weight: 600;
  font-size: 30px;
  margin: 16px 0 0;
}

.row.june-booking p {
  text-align: center;
  color: #000;
  font-size: 18px;
  margin: 14px 0 0;
}

.row.june-booking p a {
  color: #000cff;
  padding: 0 0 0 9px;
}

blink {
  -webkit-animation: 0.4s linear infinite condemned_blink_effect;
}
/* check later //for android 0*/
@-webkit-keyframes condemned_blink_effect {
  0% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
@keyframes condemned_blink_effect {
  0% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
.login-area2 {
  background: url() no-repeat;
  background-size: cover;
  padding: 20px 0;
}
.row.login-main2 h2 {
  text-align: center;
  margin: 0 0 17px;
  font-size: 25px;
  color: #ffd800;
  font-family: trajanpro-bold;
}
.row.login-main2 p {
  color: #ffd800;
}
.row.login-main2 p a {
  color: #fff;
}
.login-main2 form input[type="email"],
.login-main2 form input[type="text"],
.login-main2 form input[type="tel"],
.login-main2 form input[type="password"] {
  width: 100%;
  height: 50px;
  border-radius: 50px;
  border: 0;
  background: rgba(225, 225, 225, 0.6);
  color: #000;
  font-size: 16px;
  padding: 0 31px;
  margin: 0 0 11px;
}
.login-main2 form input[type="submit"] {
  width: 100%;
  height: 50px;
  border-radius: 50px;
  border: 0;
  background: #ffd800;
  color: #000;
  font-size: 16px;
  padding: 0 31px;
  margin: 0 0 11px;
}
.member-area {
  background: url() no-repeat;
  background-size: cover;
  padding: 19px 0;
}
.row.member-main h2 {
  color: #ffd800;
  font-size: 26px;
  text-align: center;
  margin: 0 0 14px;
  line-height: 37px;
}
.row.member-main ul li p span img {
  width: 12%;
}
.row.member-main ul li p {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  border-bottom: 1px solid #5375a2;
  padding: 18px 0;
  background: #ba5425;
}
.phone h2 {
  text-align: center;
  color: #ffd800;
  font-size: 32px;
  font-weight: 600;
  margin: 8px 0 0;
}
.all-rec-area {
  background: url() no-repeat;
  background-size: cover;
  padding: 20px 0 18px;
  margin: 85px 0 0;
}
.head h2 {
  margin: 0;
  font-size: 17px;
  background: rgba(1, 154, 83, 0.9);
  color: #fff;
  padding: 15px 22px;
  text-align: center;
  border-radius: 50px;
}
.row.sing-chat-area ul li p {
  text-align: center;
  font-size: 18px;
  padding: 10px 0;
  background: #9b1f00;
  color: #f4bd22;
}
.bod {
  border: 0 !important;
}
.head {
  margin: 0 0 20px;
}
.head2 {
  margin: 21px 0 20px;
}
.row.foot-area {
  background: #02449a;
}
.row.foot-top h2 {
  text-align: center;
  color: #ffe105;
  margin: 0 0 24px;
  font-family: trajanpro-bold;
  font-size: 25px;
}
.row.foot-top p {
  color: #fff;
  line-height: 24px;
}
.foot-bott h1 {
  color: #ffe105;
  font-size: 24px;
  text-align: center;
  margin: 9px 0 15px;
  font-family: trajanpro-bold;
}
.foot-bott h2 {
  text-align: center;
  color: #fff;
  margin: 0;
  font-size: 20px;
}
.row.sing-chat-area ul li p {
  text-align: center;
  font-size: 18px;
  padding: 10px 0;
  background: #e5cc4e;
  color: #000;
  border-radius: 50px;
  border-left: 6px solid rgba(1, 154, 83, 0.9);
  border-right: 6px solid #9b0000;
}
.row.foot-bott {
  border-top: 1px solid #4170ad;
  border-bottom: 1px solid #4170ad;
  padding: 0;
  margin: 1px 0 0;
}
.copy p {
  text-align: center;
  color: #fff;
  padding: 17px 0 12px;
}
.android-app a {
  background: #114994;
  padding: 15px 20px;
  display: inline-block;
  margin: 7px 0 -5px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 19px;
  border-radius: 50px;
}
.android-app a:hover {
  color: #fff;
}
.android-app a span {
  color: #ffcf00;
  font-size: 15px;
  padding: 0 0 0 12px;
}
.row.login-main2 h3 {
  text-align: center;
  color: #fff;
  margin: 8px 0 0;
}
.row.login-main2 h3 a {
  color: #ffd800;
  padding: 0 0 0 12px;
}
.main-resu-area {
  background: url() no-repeat;
  background-size: cover;
  padding: 23px 0;
}
.star-line h2 {
  text-align: center;
  margin: 0 0 30px;
  color: #fff;
  font-size: 23px;
  font-family: trajanpro-bold;
  background: #0e4c9f;
  padding: 12px 0;
}
.star-line2 h2 {
  margin: 19px 0 20px;
}
.row.star-line table {
  width: 100%;
}
.row.star-line table tr th {
  background: #0e4c9f;
  color: #fff;
  padding: 11px 4px;
  text-align: center;
  border: 1px solid #1d61bc;
}
.row.star-line table tr td {
  text-align: center;
  color: #000;
  border: 1px solid #969da7;
  padding: 10px 0;
  background: rgba(255, 255, 255, 0.6);
}
.astro-area {
  background: url() no-repeat;
  background-size: cover;
  padding: 7px 0 25px;
}
.sing-astro p {
  color: #000;
  text-align: center;
  font-size: 17px;
  line-height: 25px;
}
.row.sing-astro ul li p {
  color: #083b94;
  font-size: 20px;
  line-height: 25px;
  margin: 0;
}
.row.sing-astro {
  border: 3px solid #ffd800;
  border-radius: 12px;
  padding: 21px 16px;
  margin-top: 21px;
  background: #fff;
}
.row.sing-astro ul {
  padding: 0;
}
.row.sing-astro ul li {
  padding: 0 0 5px;
}
.row.member-main ul li p span {
  color: #f9f605;
}
.head-area h2 {
  text-align: center;
  font-size: 15px;
  color: #fff;
  margin: 0 0 2px;
}
.row.kalyan h3 {
  background: #01439a;
  text-align: center;
  margin: 0;
  padding: 11px 0;
  color: #fff;
}
.row.mumbai h3 {
  background: #efb609;
  color: #000;
}
.k-text {
  padding: 5px 0;
  background: #fff;
}
.row.kalyan p {
  margin: 0;
  text-align: center;
  font-size: 20px;
  color: #000;
  background: #fff;
  padding: 0 0 5px;
}
marquee {
  color: #ffd800;
}
.bann-img2 {
  position: absolute;
  top: 162px;
  left: 120px;
  width: 30%;
  display: block;
}
.jodi-chat-single h2 {
  text-align: center;
  color: rgba(1, 154, 83, 0.9);
  margin: 22px 0 20px;
}
.jodi-chat-single table {
  width: 100%;
  margin: 0 0 45px;
}
.jodi-chat-single table tbody tr th {
  background: #00ad76;
  color: #fff;
  text-align: center;
  padding: 19px 10px;
  border: 1px solid #00ad76;
}
.jodi-chat-single table tbody tr td {
  text-align: center;
  padding: 8px 10px;
  border: 1px solid #ccc;
}
.mini {
  width: 10px;
}
.fix-butt a {
  background: #ffe105;
  color: #000;
  padding: 16px 40px;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px 0 0 4px;
}
.fix-butt {
  position: fixed;
  bottom: 15px;
  z-index: 999;
  right: 0;
}
.logout1 {
  background: #f7d800;
  text-align: center;
  padding: 9px 0;
  border-radius: 50px;
  width: 19%;
  margin: 13px auto 11px;
  position: absolute;
  z-index: 999;
  right: 2px;
  top: 116px;
}
.logout1 a i {
  padding: 0 4px;
}
.logout1 a {
  color: #000;
}
.star-top-area {
  background: #01439a;
  padding: 15px 0;
}
.star-clock h2 {
  text-align: center;
  margin: 0;
  color: #fff;
}
#clockDisplay {
  color: #fff300;
  text-align: center;
  margin: 11px 0 0;
  font-size: 25px;
}
.star-rate {
  background: #fff;
  box-shadow: 0 0 11px -4px;
  padding: 20px 50px;
  margin: 35px 0;
  border-radius: 8px;
  border-top: 5px solid rgba(1, 154, 83, 0.9);
  border-bottom: 5px solid rgba(1, 154, 83, 0.9);
}
.star-rate h2 {
  margin: 0 0 18px;
  color: #6d0000;
  padding: 13px 2px;
  border-radius: 50px;
  font-size: 19px;
}
.star-rate ul li p {
  font-size: 18px;
  color: #000;
  border-bottom: 1px solid #ccc;
  padding: 5px 0 17px;
}
.star-rate ul li p span {
  float: right;
}
.star-tab button {
  background: #f3f3f3;
  color: #000;
  padding: 11px 22px;
  border-radius: 50px;
  border-left: 5px solid #9a0000;
  font-size: 17px;
  transition: all ease-in-out 0.4s;
  border-right: 5px solid #9a0000;
  border-top: 0;
  border-bottom: 0;
}
.star-tab button:hover {
  background: #980101;
  transition: all ease-in-out 0.4s;
  color: #fff;
}
.star-tab button.active {
  background: rgba(1, 154, 83, 0.9);
  color: #fff;
  border: 1px solid rgba(1, 154, 83, 0.9);
}
.star-sec {
  background: url();
  background-size: cover;
  padding: 40px 0;
}
.star-tab {
  text-align: center;
  margin: 0 auto 21px;
  display: flex;
  width: 100%;
  display: block;
}
.row.star-play-rate {
  margin: 85px 0 0;
}
.star-new {
  background: rgba(1, 67, 154, 0.8);
  padding: 40px 36px;
  margin: 0 0 36px;
  border: 1px solid #fee301;
  border-radius: 15px;
}
.star-left img {
  width: 51%;
}
.star-right ul li p {
  color: #fff;
  padding: 5px 0;
  font-size: 18px;
}
.single-star-game {
  background: #fff;
  border-radius: 100px;
  margin: 20px 0 10px;
  box-shadow: 0 0 12px -6px !important;
  border-left: 5px solid #fb0;
  border-right: 5px solid #fb0;
}
.main-sing-play h3 {
  font-size: 14px;
  margin: 15px 0 5px;
  color: #000;
}
.main-sing-play p {
  font-size: 20px;
  color: rgba(1, 154, 83, 0.9);
  padding: 5px 0 0;
  margin: 0;
}
.main-sing-play {
  margin: 0 0 0 13px;
}
.pad-hid {
  padding: 0 !important;
}
.main-sing-result p {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  padding: 27px 0;
  margin: 0;
  text-align: center;
}
.main-sing-play img {
  padding: 9px;
  width: 78%;
}
.real-bazar-cont {
  display: none;
  transition: all ease-in-out 0.4s;
}
.play-option-area {
  background: url();
  background-size: cover;
  padding: 40px 0;
}
.single-oprion {
  background: #fff;
  margin: 0 !important;
  border-radius: 8px;
  padding: 10px 0;
  border-left: 4px solid rgba(1, 154, 83, 0.9);
  border-top: 4px solid rgba(1, 154, 83, 0.9);
  box-shadow: 0 0 15px -5px;
}
.single-oprion p {
  font-size: 25px;
  text-transform: uppercase;
  color: rgba(1, 154, 83, 0.9);
  margin: 21px 0;
}
.play-option-area h2 {
  text-align: center;
  margin: 0 0 32px;
  color: #fff;
  font-size: 27px;
}
.play-option-area a {
  padding: 0 0 15px;
  display: block;
}
.s-p-hed {
  background: #e5cc4e;
  color: #000;
  width: 53px;
  text-align: center;
  padding: 10px 0;
  border-radius: 4px;
  margin: 0 0 16px;
}
.s-p-tit {
  border: 1px solid #ccc;
  padding: 10px 14px;
  border-radius: 4px;
  margin: 0 0 8px;
}
.client-deta a {
  margin: 0 auto;
  display: block;
  text-align: center;
}
.phone-num a i {
  padding: 0 8px 0 0;
}
.phone-num {
  margin: 18px auto;
  display: block;
  text-align: center;
}
.star-line-home {
  background: rgba(1, 154, 83, 0.9);
  padding: 25px 0;
}
.star-line-home .main-sing-play h3 {
  font-size: 20px;
  font-weight: 600;
  color: #6d0000;
  padding: 11px 0 0;
}
.star-line-home .single-star-game {
  width: 348px;
  height: auto;
  margin: 0 auto 33px;
}
.client-deta a {
  display: inline-block;
}
.client-deta {
  margin: 11px auto 3px;
  display: block;
  text-align: center;
}
.gold-top-hed {
  background: rgba(1, 154, 83, 0.9);
}
.gold-login-area ul li {
  display: inline-block;
}
.gold-login-area ul {
  margin: 0;
  text-align: right;
  padding: 0;
}
.gold-login-area ul li a {
  color: #fff;
  padding: 0 0 0 9px;
  font-size: 14px;
}
.gold-logo h2 {
  color: #fb0;
  margin: 0;
  font-size: 23px;
  font-weight: 600;
}
.gold-top-hed {
  padding: 12px 0;
}
.row.gold-menu-area {
  padding: 21px 0;
  background: #00ad76;
  position: inherit;
  left: 0;
  right: 0;
  width: auto;
  text-align: center;
  z-index: 99;
}
.gold-menu ul li {
  display: inline-block;
  text-transform: capitalize;
  padding: 0 6px;
}
.gold-menu ul li a {
  color: #fff;
}
.gold-menu ul {
  margin: 0;
}
.gold-notice {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 16px 0 11px;
}
.g-presult h2 {
  font-size: 21px;
  text-align: center;
  background: #e5cc4e;
  color: #000;
  padding: 11px 0;
  border-radius: 4px;
  margin: 0 0 10px;
}
.g-presult ul li p i {
  padding: 0 14px 0 0;
  color: #9b0000;
}
.g-presult ul li p {
  border-top: 2px solid rgba(1, 154, 83, 0.9);
  font-size: 16px;
  text-align: center;
  margin: 0 0 10px;
  padding: 13px 0;
  border-radius: 4px;
  background: #fff;
  color: #000;
  border-bottom: 2px solid rgba(1, 154, 83, 0.9);
  border-left: 0;
  border-right: 0;
  box-shadow: 0 4px 7px -4px;
}
.row.gold-presult {
  padding: 14px 0 9px;
}
.g-notice h2 {
  background: #e5cc4e;
  text-align: center;
  font-size: 21px;
  color: #000;
  padding: 11px 0;
  border-radius: 4px;
  margin: 0 0 10px;
}
.g-notice p {
  background: #fff;
  padding: 8px 15px;
  font-size: 13px;
  text-align: center;
  line-height: 21px;
  border-radius: 4px;
  box-shadow: 0 0 8px -4px;
}
.g-notice h3 {
  border-top: 2px solid rgba(1, 154, 83, 0.9);
  font-size: 16px;
  text-align: center;
  margin: 0 0 10px;
  padding: 13px 0;
  border-radius: 4px;
  background: #fff;
  color: #000;
  border-bottom: 2px solid rgba(1, 154, 83, 0.9);
  border-left: 0;
  border-right: 0;
  box-shadow: 0 4px 7px -4px;
}
.blinking {
  animation: blinkingText 0.8s infinite;
}
@keyframes blinkingText {
  0% {
    color: #000;
  }
  49% {
    color: #9b0000;
  }
  50% {
    color: red;
  }
  99% {
    color: tomato;
  }
  100% {
    color: red;
  }
}
.row.gold-payment-type h2 {
  background: rgba(1, 154, 83, 0.9);
  margin: 0 15px 8px;
  color: #fff;
  font-size: 21px;
  text-align: center;
  padding: 11px 0;
  border-radius: 4px;
}
.row.all-gold-pam {
  margin: 3px 0 7px;
  padding: 4px 15px;
  background: #fff;
  border-radius: 4px;
  border-left: 4px solid rgba(1, 154, 83, 0.9);
  border-right: 5px solid rgba(1, 154, 83, 0.9);
}
.main-gold-pam-det h3 {
  font-size: 20px;
  color: #000;
  margin: 3px 0 9px;
  text-align: center;
}
.main-gold-pam-det h4 {
  margin: 0;
  font-size: 34px;
  font-weight: 600;
  color: #20c000;
  text-align: center;
}
.main-gold-pam-det span {
  background: #000;
  color: #fff;
  text-align: center;
  padding: 5px 20px;
  font-size: 13px;
  margin: 12px 0 0;
  display: inline-block;
}
.dt-g p {
  margin: 15px 0 9px;
  text-align: left;
  padding: 0;
  display: block;
  color: #000;
}
.row.gold-top-palayer h2 {
  text-align: center;
  margin: 5px 14px;
  color: #fae18f;
  font-size: 25px;
  padding: 11px 0;
}
.sing-player {
  background: #fff;
  border-radius: 6px;
  padding: 0 12px;
  border-left: 4px solid #0e50a2;
  border-right: 4px solid #0e50a2;
}
.sing-player img {
  position: absolute;
  top: 0;
  width: 16%;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.sing-player h3 {
  text-align: center;
  margin: 0;
  padding: 8px 0;
  font-weight: 600;
  color: #000;
}
.sing-player ul {
  padding: 0;
  margin: 0;
}
.g-foot img {
  width: 4%;
  padding: 18px 0;
}
.sing-player ul li {
  padding: 7px 18px;
  margin: 0 0 6px;
  font-size: 15px;
  color: #fff;
  background: rgba(1, 154, 83, 0.9);
  border-radius: 4px;
}
.sing-player ul li p {
  margin: 0;
}
.sing-player ul li p span {
  float: right;
  font-weight: 600;
  color: #000;
}
.sing-player2 {
  margin-bottom: 0;
}
.g-result-main h2 {
  text-align: center;
  font-size: 19px;
  margin: 13px 0 5px;
  color: #fff;
}
.gold-res-area {
  background-size: cover;
  padding: 0 0 12px;
  background-attachment: fixed;
}
.g-main-area h2 {
  background: rgba(1, 154, 83, 0.9);
  text-align: center;
  font-size: 21px;
  color: #fff;
  padding: 12px 0;
  border-radius: 4px;
}
.g-sing-result {
  background: #fff;
  border-left: 5px solid rgba(1, 154, 83, 0.9);
  border-radius: 6px;
  border-right: 5px solid rgba(1, 154, 83, 0.9);
}
.g-jchat span i {
  font-size: 20px;
}
.g-sing-result h3 {
  margin: 0;
  text-align: center;
  font-size: 18px;
  color: rgba(1, 154, 83, 0.9);
  padding: 12px 0 0;
}
.g-sing-result ul li {
  display: inline-block;
  padding: 0 17px;
}
.g-sing-result ul {
  text-align: center;
  margin: 11px 0;
}
.g-sing-result ul li span {
  font-size: 27px;
}
.g-rtime p {
  padding: 0 15px 11px;
  color: #000;
}
.g-rtime p span {
  float: right;
}
.row.gold-cont {
  background: rgba(1, 154, 83, 0.9);
}
.gcon-area h2 {
  text-align: center;
  font-size: 22px;
  color: #fff;
  margin: 16px 0 6px;
}
.gcon-area p {
  text-align: center;
  font-size: 15px;
  color: #fff;
}
.gcon-area h3 {
  text-align: center;
  font-size: 24px;
  margin: 0 0 16px;
  color: #ffff;
}
.gold-jchat {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 16px 0 11px;
}
.g-jchat h2 {
  text-align: center;
  font-size: 20px;
  background: rgba(1, 154, 83, 0.9);
  color: #fff;
  padding: 12px 0;
  border-radius: 4px;
  margin: 0 0 11px;
}
.g-jchat a {
  width: 100%;
  border-top: 2px solid rgba(1, 154, 83, 0.9);
  font-size: 13px;
  display: block;
  background: #fff;
  color: #000;
  padding: 10px 18px;
  margin: 0 0 5px;
  border-radius: 4px;
  border-bottom: 2px solid rgba(1, 154, 83, 0.9);
  border-left: 0;
  border-right: 0;
  box-shadow: 0 4px 7px -4px;
  text-align: left;
}
s .g-jchat a span {
  color: rgba(1, 154, 83, 0.9);
}
.gold-payment-type {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 16px 0 11px;
}
.dt-g p span {
  float: right;
  background: rgba(1, 154, 83, 0.9);
  color: #fff;
  padding: 3px 12px;
  font-size: 12px;
  border-radius: 2px;
}
.gold-time-table {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 16px 0 13px;
}
.gcon-table h2 {
  text-align: center;
  background: rgba(1, 154, 83, 0.9);
  font-size: 21px;
  padding: 11px 0;
  border-radius: 4px;
  color: #fff;
  margin: 0 0 11px;
}
s .gcon-table table {
  width: 100%;
}
.gcon-table table tr th {
  background: #9b0000;
  color: #fff;
  padding: 8px 0;
  border: 1px solid #920202;
  text-align: center;
}
.gcon-table table tr td {
  border: 1px solid #9b0000;
  text-align: center;
  padding: 7px 0;
  font-size: 12px;
  background: #fff;
}
.gold-top-palayer {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 16px 0 13px;
}
.row.gold-top-palayer h2 {
  background: rgba(1, 154, 83, 0.9);
  color: #fff;
  font-size: 21px;
  border-radius: 4px;
}
.sing-player ul {
  padding: 10px 0 15px;
  margin: 0 0 6px;
}
.gold-footer {
  background: rgba(1, 154, 83, 0.9);
  text-align: center;
  text-transform: capitalize;
  color: #fff;
}
.g-foot h2 {
  margin: 12px 0 2px;
}
.g-jchat a span {
  color: rgba(1, 154, 83, 0.9);
  float: right;
}
.gcon-table table {
  width: 100%;
}
.gold-menu a {
  display: inline-block;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 15px;
  padding: 3px 11px;
}
.m1:hover {
  background: #000;
  color: #fff;
  border-radius: 50px;
}
.m1.active {
  background: #000;
  border-radius: 50px;
  color: #fff;
}
.m2:hover {
  background: #ffcf00;
  color: #000;
  border-radius: 50px;
}
.m2.active {
  background: #ffcf00;
  border-radius: 50px;
  color: #000;
}
.m3:hover {
  background: #69ff00;
  color: #000;
  border-radius: 50px;
}
.m3.active {
  background: #69ff00;
  border-radius: 50px;
  color: #000;
}
.m4:hover {
  background: #00ffdb;
  color: #000;
  border-radius: 50px;
}
.m4.active {
  background: #00ffdb;
  border-radius: 50px;
  color: #000;
}
.m5:hover {
  background: #b45ef2;
  color: #fff;
  border-radius: 50px;
}
.m5.active {
  background: #b45ef2;
  border-radius: 50px;
  color: #fff;
}
.m6:hover {
  background: #f641c1;
  color: #fff;
  border-radius: 50px;
}
.m6.active {
  background: #f641c1;
  border-radius: 50px;
  color: #fff;
}
.m7:hover {
  background: #dec196;
  color: #000;
  border-radius: 50px;
}
.m7.active {
  background: #dec196;
  border-radius: 50px;
  color: #000;
}
.m8:hover {
  background: #00ffdb;
  color: #000;
  border-radius: 50px;
}
.m8.active {
  background: #00ffdb;
  border-radius: 50px;
  color: #000;
}
.m9:hover {
  background: #000;
  color: #fff;
  border-radius: 50px;
}
.m9.active {
  background: #000;
  border-radius: 50px;
  color: #fff;
}
.m10:hover {
  background: #f641c1;
  color: #fff;
  border-radius: 50px;
}
.m10.active {
  background: #f641c1;
  border-radius: 50px;
  color: #fff;
}
.row.star-play-rate h1 {
  font-size: 23px;
  color: rgba(1, 154, 83, 0.9);
}
.star-rate ul li p i {
  color: rgba(1, 154, 83, 0.9);
  padding: 0 5px 0 0;
}
.row.sing-chat-area h1 {
  font-size: 20px;
  margin: 0 0 13px;
  color: rgba(1, 154, 83, 0.9);
}
.for-top p {
  font-size: 15px;
  margin: 0 0 14px;
}
.for-top ul li p {
  font-size: 14px;
  line-height: 22px;
}
.dwapp img {
  width: 62%;
}
.log-out i {
  color: #000 !important;
}
.gold-banner {
  position: relative;
}
.gold-banner img {
  width: 100%;
}
.banner-text h2 {
  color: #fff;
  font-size: 17px;
}
.banner-text p {
  color: #fff;
  font-size: 17px;
  width: 50%;
}
.banner-text {
  position: absolute;
  top: 36%;
  left: 7%;
  z-index: 99;
}
.bann-butt a {
  background: #fff;
  padding: 15px 37px;
  display: inline-block;
  font-size: 17px;
  border-radius: 50px;
  margin: 0 7px 0 0;
}
.bann-butt {
  margin: 30px 0 0;
}
.bann-butt a:nth-child(1) {
  background: #e5cc4e;
  color: #000;
}
.bann-butt a:nth-child(2) {
  background: #d92b2e;
  color: #fff;
}
.gold-login-area ul li a i {
  color: #fff !important;
  padding: 0 3px 0 0;
}
.dwapp img {
  width: 17%;
  text-align: center;
  margin: 32px auto 36px;
  display: block;
}
.row.flot-what {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
}
.row.flot-what a img {
  width: 4%;
  position: fixed;
  bottom: 10px;
  right: 11px;
  z-index: 9999;
}
.phone-num a {
  font-size: 25px;
  color: #fff;
  margin: 0;
  padding: 0;
}
.phone-num a span {
  color: #e5cc4e;
  margin: 0;
  padding: 0;
}
.phone-num a:hover {
  color: #fff;
}
.gold-logo img {
  width: 4%;
}
.pa-seo-text {
  border-top: 1px solid #a40919;
  padding: 20px 0 0;
  color: #fff;
}
.pa-seo-text p {
  line-height: 22px;
  text-align: justify;
  font-size: 13px;
  padding: 0 15px;
}
.foot-social-n ul li {
  display: inline-block;
  padding: 0 8px;
  font-size: 20px;
}
.foot-social-n ul li a {
  color: #fff;
  margin: 11px 0;
  display: block;
}
.pa-seo-text2 {
  border: 0;
  padding: 0;
}
.b-mob {
  display: none !important;
}
.b-dsk {
  display: block !important;
}
.sing-g-his {
  background: #e6cd4d;
  border-radius: 10px;
  padding: 30px 41px;
  margin: 71px 0 47px;
  position: relative;
  border: 3px solid rgba(1, 154, 83, 0.9);
}
.sing-g-his ul li p {
  background: #c01b2c;
  color: #fff;
  margin: 0 0 5px;
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 50px;
  font-size: 16px;
}
.count {
  background: rgba(1, 154, 83, 0.9);
  width: 40px;
  height: 40px;
  display: block;
  color: #fefa05;
  font-size: 17px;
  text-align: center;
  line-height: 40px;
  font-weight: 600;
  border-radius: 5px;
  transform: rotate(45deg);
  position: absolute;
  top: -19px;
  left: -19px;
}
.omp-main-area1,
.omp-main-area2 {
  background: #fff;
  box-shadow: 0 0 13px -4px;
  border-radius: 15px;
  border-left: 4px solid rgba(1, 154, 83, 0.9);
  padding: 20px;
  margin: 19px 0 0;
  border-right: 4px solid rgba(1, 154, 83, 0.9);
}
.omp-main-area2 {
  margin-bottom: 20px;
}
.omp-main-area1 h2,
.omp-main-area2 h2 {
  font-size: 20px;
  line-height: 27px;
  margin: 0 0 15px;
  color: rgba(1, 154, 83, 0.9);
}
.omp-main-area1 p,
.omp-main-area2 p {
  font-size: 15px;
  line-height: 23px;
}
.omp-main-area1 p span,
.omp-main-area2 p span {
  font-weight: 600;
}
.msmo-main {
  background: #fff;
  box-shadow: 0 0 13px -4px;
  border-radius: 15px;
  border-left: 4px solid rgba(1, 154, 83, 0.9);
  padding: 20px;
  margin: 19px 0 20px;
  border-right: 4px solid rgba(1, 154, 83, 0.9);
}
.msmo-main h2 {
  font-size: 20px;
  line-height: 27px;
  margin: 0 0 15px;
  color: rgba(1, 154, 83, 0.9);
}
.msmo-main p {
  font-size: 15px;
  line-height: 23px;
}
.msmo-main span {
  font-weight: 600;
}
.foot-cont p {
  font-size: 13px;
  line-height: 20px;
  padding: 0 0 6px;
}
.gcon-area h3 a {
  color: #fff;
}
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: 700;
}
dfn {
  font-style: italic;
}
h1 {
  margin: 0.67em 0;
  font-size: 2em;
}
mark {
  color: #000;
  background: #ff0;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  padding: 0.35em 0.625em 0.75em;
  margin: 0 2px;
  border: 1px solid silver;
}
legend {
  padding: 0;
  border: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: 700;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
td,
th {
  padding: 0;
}
@media print {
  *,
  :after,
  :before {
    color: #000 !important;
    text-shadow: none !important;
    background: 0 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  blockquote,
  pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  img,
  tr {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }
  .label {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #ddd !important;
  }
}
@font-face {
  font-family: glyphicons halflings;
  src: url();
  src: url() format("embedded-opentype"), url() format("woff2"),
    url() format("woff"), url() format("truetype"), url() format("svg");
}
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: glyphicons halflings;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.glyphicon-asterisk:before {
  content: "\002a";
}
.glyphicon-plus:before {
  content: "\002b";
}
.glyphicon-eur:before,
.glyphicon-euro:before {
  content: "\20ac";
}
.glyphicon-minus:before {
  content: "\2212";
}
.glyphicon-cloud:before {
  content: "\2601";
}
.glyphicon-envelope:before {
  content: "\2709";
}
.glyphicon-pencil:before {
  content: "\270f";
}
.glyphicon-glass:before {
  content: "\e001";
}
.glyphicon-music:before {
  content: "\e002";
}
.glyphicon-search:before {
  content: "\e003";
}
.glyphicon-heart:before {
  content: "\e005";
}
.glyphicon-star:before {
  content: "\e006";
}
.glyphicon-star-empty:before {
  content: "\e007";
}
.glyphicon-user:before {
  content: "\e008";
}
.glyphicon-film:before {
  content: "\e009";
}
.glyphicon-th-large:before {
  content: "\e010";
}
.glyphicon-th:before {
  content: "\e011";
}
.glyphicon-th-list:before {
  content: "\e012";
}
.glyphicon-ok:before {
  content: "\e013";
}
.glyphicon-remove:before {
  content: "\e014";
}
.glyphicon-zoom-in:before {
  content: "\e015";
}
.glyphicon-zoom-out:before {
  content: "\e016";
}
.glyphicon-off:before {
  content: "\e017";
}
.glyphicon-signal:before {
  content: "\e018";
}
.glyphicon-cog:before {
  content: "\e019";
}
.glyphicon-trash:before {
  content: "\e020";
}
.glyphicon-home:before {
  content: "\e021";
}
.glyphicon-file:before {
  content: "\e022";
}
.glyphicon-time:before {
  content: "\e023";
}
.glyphicon-road:before {
  content: "\e024";
}
.glyphicon-download-alt:before {
  content: "\e025";
}
.glyphicon-download:before {
  content: "\e026";
}
.glyphicon-upload:before {
  content: "\e027";
}
.glyphicon-inbox:before {
  content: "\e028";
}
.glyphicon-play-circle:before {
  content: "\e029";
}
.glyphicon-repeat:before {
  content: "\e030";
}
.glyphicon-refresh:before {
  content: "\e031";
}
.glyphicon-list-alt:before {
  content: "\e032";
}
.glyphicon-lock:before {
  content: "\e033";
}
.glyphicon-flag:before {
  content: "\e034";
}
.glyphicon-headphones:before {
  content: "\e035";
}
.glyphicon-volume-off:before {
  content: "\e036";
}
.glyphicon-volume-down:before {
  content: "\e037";
}
.glyphicon-volume-up:before {
  content: "\e038";
}
.glyphicon-qrcode:before {
  content: "\e039";
}
.glyphicon-barcode:before {
  content: "\e040";
}
.glyphicon-tag:before {
  content: "\e041";
}
.glyphicon-tags:before {
  content: "\e042";
}
.glyphicon-book:before {
  content: "\e043";
}
.glyphicon-bookmark:before {
  content: "\e044";
}
.glyphicon-print:before {
  content: "\e045";
}
.glyphicon-camera:before {
  content: "\e046";
}
.glyphicon-font:before {
  content: "\e047";
}
.glyphicon-bold:before {
  content: "\e048";
}
.glyphicon-italic:before {
  content: "\e049";
}
.glyphicon-text-height:before {
  content: "\e050";
}
.glyphicon-text-width:before {
  content: "\e051";
}
.glyphicon-align-left:before {
  content: "\e052";
}
.glyphicon-align-center:before {
  content: "\e053";
}
.glyphicon-align-right:before {
  content: "\e054";
}
.glyphicon-align-justify:before {
  content: "\e055";
}
.glyphicon-list:before {
  content: "\e056";
}
.glyphicon-indent-left:before {
  content: "\e057";
}
.glyphicon-indent-right:before {
  content: "\e058";
}
.glyphicon-facetime-video:before {
  content: "\e059";
}
.glyphicon-picture:before {
  content: "\e060";
}
.glyphicon-map-marker:before {
  content: "\e062";
}
.glyphicon-adjust:before {
  content: "\e063";
}
.glyphicon-tint:before {
  content: "\e064";
}
.glyphicon-edit:before {
  content: "\e065";
}
.glyphicon-share:before {
  content: "\e066";
}
.glyphicon-check:before {
  content: "\e067";
}
.glyphicon-move:before {
  content: "\e068";
}
.glyphicon-step-backward:before {
  content: "\e069";
}
.glyphicon-fast-backward:before {
  content: "\e070";
}
.glyphicon-backward:before {
  content: "\e071";
}
.glyphicon-play:before {
  content: "\e072";
}
.glyphicon-pause:before {
  content: "\e073";
}
.glyphicon-stop:before {
  content: "\e074";
}
.glyphicon-forward:before {
  content: "\e075";
}
.glyphicon-fast-forward:before {
  content: "\e076";
}
.glyphicon-step-forward:before {
  content: "\e077";
}
.glyphicon-eject:before {
  content: "\e078";
}
.glyphicon-chevron-left:before {
  content: "\e079";
}
.glyphicon-chevron-right:before {
  content: "\e080";
}
.glyphicon-plus-sign:before {
  content: "\e081";
}
.glyphicon-minus-sign:before {
  content: "\e082";
}
.glyphicon-remove-sign:before {
  content: "\e083";
}
.glyphicon-ok-sign:before {
  content: "\e084";
}
.glyphicon-question-sign:before {
  content: "\e085";
}
.glyphicon-info-sign:before {
  content: "\e086";
}
.glyphicon-screenshot:before {
  content: "\e087";
}
.glyphicon-remove-circle:before {
  content: "\e088";
}
.glyphicon-ok-circle:before {
  content: "\e089";
}
.glyphicon-ban-circle:before {
  content: "\e090";
}
.glyphicon-arrow-left:before {
  content: "\e091";
}
.glyphicon-arrow-right:before {
  content: "\e092";
}
.glyphicon-arrow-up:before {
  content: "\e093";
}
.glyphicon-arrow-down:before {
  content: "\e094";
}
.glyphicon-share-alt:before {
  content: "\e095";
}
.glyphicon-resize-full:before {
  content: "\e096";
}
.glyphicon-resize-small:before {
  content: "\e097";
}
.glyphicon-exclamation-sign:before {
  content: "\e101";
}
.glyphicon-gift:before {
  content: "\e102";
}
.glyphicon-leaf:before {
  content: "\e103";
}
.glyphicon-fire:before {
  content: "\e104";
}
.glyphicon-eye-open:before {
  content: "\e105";
}
.glyphicon-eye-close:before {
  content: "\e106";
}
.glyphicon-warning-sign:before {
  content: "\e107";
}
.glyphicon-plane:before {
  content: "\e108";
}
.glyphicon-calendar:before {
  content: "\e109";
}
.glyphicon-random:before {
  content: "\e110";
}
.glyphicon-comment:before {
  content: "\e111";
}
.glyphicon-magnet:before {
  content: "\e112";
}
.glyphicon-chevron-up:before {
  content: "\e113";
}
.glyphicon-chevron-down:before {
  content: "\e114";
}
.glyphicon-retweet:before {
  content: "\e115";
}
.glyphicon-shopping-cart:before {
  content: "\e116";
}
.glyphicon-folder-close:before {
  content: "\e117";
}
.glyphicon-folder-open:before {
  content: "\e118";
}
.glyphicon-resize-vertical:before {
  content: "\e119";
}
.glyphicon-resize-horizontal:before {
  content: "\e120";
}
.glyphicon-hdd:before {
  content: "\e121";
}
.glyphicon-bullhorn:before {
  content: "\e122";
}
.glyphicon-bell:before {
  content: "\e123";
}
.glyphicon-certificate:before {
  content: "\e124";
}
.glyphicon-thumbs-up:before {
  content: "\e125";
}
.glyphicon-thumbs-down:before {
  content: "\e126";
}
.glyphicon-hand-right:before {
  content: "\e127";
}
.glyphicon-hand-left:before {
  content: "\e128";
}
.glyphicon-hand-up:before {
  content: "\e129";
}
.glyphicon-hand-down:before {
  content: "\e130";
}
.glyphicon-circle-arrow-right:before {
  content: "\e131";
}
.glyphicon-circle-arrow-left:before {
  content: "\e132";
}
.glyphicon-circle-arrow-up:before {
  content: "\e133";
}
.glyphicon-circle-arrow-down:before {
  content: "\e134";
}
.glyphicon-globe:before {
  content: "\e135";
}
.glyphicon-wrench:before {
  content: "\e136";
}
.glyphicon-tasks:before {
  content: "\e137";
}
.glyphicon-filter:before {
  content: "\e138";
}
.glyphicon-briefcase:before {
  content: "\e139";
}
.glyphicon-fullscreen:before {
  content: "\e140";
}
.glyphicon-dashboard:before {
  content: "\e141";
}
.glyphicon-paperclip:before {
  content: "\e142";
}
.glyphicon-heart-empty:before {
  content: "\e143";
}
.glyphicon-link:before {
  content: "\e144";
}
.glyphicon-phone:before {
  content: "\e145";
}
.glyphicon-pushpin:before {
  content: "\e146";
}
.glyphicon-usd:before {
  content: "\e148";
}
.glyphicon-gbp:before {
  content: "\e149";
}
.glyphicon-sort:before {
  content: "\e150";
}
.glyphicon-sort-by-alphabet:before {
  content: "\e151";
}
.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152";
}
.glyphicon-sort-by-order:before {
  content: "\e153";
}
.glyphicon-sort-by-order-alt:before {
  content: "\e154";
}
.glyphicon-sort-by-attributes:before {
  content: "\e155";
}
.glyphicon-sort-by-attributes-alt:before {
  content: "\e156";
}
.glyphicon-unchecked:before {
  content: "\e157";
}
.glyphicon-expand:before {
  content: "\e158";
}
.glyphicon-collapse-down:before {
  content: "\e159";
}
.glyphicon-collapse-up:before {
  content: "\e160";
}
.glyphicon-log-in:before {
  content: "\e161";
}
.glyphicon-flash:before {
  content: "\e162";
}
.glyphicon-log-out:before {
  content: "\e163";
}
.glyphicon-new-window:before {
  content: "\e164";
}
.glyphicon-record:before {
  content: "\e165";
}
.glyphicon-save:before {
  content: "\e166";
}
.glyphicon-open:before {
  content: "\e167";
}
.glyphicon-saved:before {
  content: "\e168";
}
.glyphicon-import:before {
  content: "\e169";
}
.glyphicon-export:before {
  content: "\e170";
}
.glyphicon-send:before {
  content: "\e171";
}
.glyphicon-floppy-disk:before {
  content: "\e172";
}
.glyphicon-floppy-saved:before {
  content: "\e173";
}
.glyphicon-floppy-remove:before {
  content: "\e174";
}
.glyphicon-floppy-save:before {
  content: "\e175";
}
.glyphicon-floppy-open:before {
  content: "\e176";
}
.glyphicon-credit-card:before {
  content: "\e177";
}
.glyphicon-transfer:before {
  content: "\e178";
}
.glyphicon-cutlery:before {
  content: "\e179";
}
.glyphicon-header:before {
  content: "\e180";
}
.glyphicon-compressed:before {
  content: "\e181";
}
.glyphicon-earphone:before {
  content: "\e182";
}
.glyphicon-phone-alt:before {
  content: "\e183";
}
.glyphicon-tower:before {
  content: "\e184";
}
.glyphicon-stats:before {
  content: "\e185";
}
.glyphicon-sd-video:before {
  content: "\e186";
}
.glyphicon-hd-video:before {
  content: "\e187";
}
.glyphicon-subtitles:before {
  content: "\e188";
}
.glyphicon-sound-stereo:before {
  content: "\e189";
}
.glyphicon-sound-dolby:before {
  content: "\e190";
}
.glyphicon-sound-5-1:before {
  content: "\e191";
}
.glyphicon-sound-6-1:before {
  content: "\e192";
}
.glyphicon-sound-7-1:before {
  content: "\e193";
}
.glyphicon-copyright-mark:before {
  content: "\e194";
}
.glyphicon-registration-mark:before {
  content: "\e195";
}
.glyphicon-cloud-download:before {
  content: "\e197";
}
.glyphicon-cloud-upload:before {
  content: "\e198";
}
.glyphicon-tree-conifer:before {
  content: "\e199";
}
.glyphicon-tree-deciduous:before {
  content: "\e200";
}
.glyphicon-cd:before {
  content: "\e201";
}
.glyphicon-save-file:before {
  content: "\e202";
}
.glyphicon-open-file:before {
  content: "\e203";
}
.glyphicon-level-up:before {
  content: "\e204";
}
.glyphicon-copy:before {
  content: "\e205";
}
.glyphicon-paste:before {
  content: "\e206";
}
.glyphicon-alert:before {
  content: "\e209";
}
.glyphicon-equalizer:before {
  content: "\e210";
}
.glyphicon-king:before {
  content: "\e211";
}
.glyphicon-queen:before {
  content: "\e212";
}
.glyphicon-pawn:before {
  content: "\e213";
}
.glyphicon-bishop:before {
  content: "\e214";
}
.glyphicon-knight:before {
  content: "\e215";
}
.glyphicon-baby-formula:before {
  content: "\e216";
}
.glyphicon-tent:before {
  content: "\26fa";
}
.glyphicon-blackboard:before {
  content: "\e218";
}
.glyphicon-bed:before {
  content: "\e219";
}
.glyphicon-apple:before {
  content: "\f8ff";
}
.glyphicon-erase:before {
  content: "\e221";
}
.glyphicon-hourglass:before {
  content: "\231b";
}
.glyphicon-lamp:before {
  content: "\e223";
}
.glyphicon-duplicate:before {
  content: "\e224";
}
.glyphicon-piggy-bank:before {
  content: "\e225";
}
.glyphicon-scissors:before {
  content: "\e226";
}
.glyphicon-bitcoin:before {
  content: "\e227";
}
.glyphicon-btc:before {
  content: "\e227";
}
.glyphicon-xbt:before {
  content: "\e227";
}
.glyphicon-yen:before {
  content: "\00a5";
}
.glyphicon-jpy:before {
  content: "\00a5";
}
.glyphicon-ruble:before {
  content: "\20bd";
}
.glyphicon-rub:before {
  content: "\20bd";
}
.glyphicon-scale:before {
  content: "\e230";
}
.glyphicon-ice-lolly:before {
  content: "\e231";
}
.glyphicon-ice-lolly-tasted:before {
  content: "\e232";
}
.glyphicon-education:before {
  content: "\e233";
}
.glyphicon-option-horizontal:before {
  content: "\e234";
}
.glyphicon-option-vertical:before {
  content: "\e235";
}
.glyphicon-menu-hamburger:before {
  content: "\e236";
}
.glyphicon-modal-window:before {
  content: "\e237";
}
.glyphicon-oil:before {
  content: "\e238";
}
.glyphicon-grain:before {
  content: "\e239";
}
.glyphicon-sunglasses:before {
  content: "\e240";
}
.glyphicon-text-size:before {
  content: "\e241";
}
.glyphicon-text-color:before {
  content: "\e242";
}
.glyphicon-text-background:before {
  content: "\e243";
}
.glyphicon-object-align-top:before {
  content: "\e244";
}
.glyphicon-object-align-bottom:before {
  content: "\e245";
}
.glyphicon-object-align-horizontal:before {
  content: "\e246";
}
.glyphicon-object-align-left:before {
  content: "\e247";
}
.glyphicon-object-align-vertical:before {
  content: "\e248";
}
.glyphicon-object-align-right:before {
  content: "\e249";
}
.glyphicon-triangle-right:before {
  content: "\e250";
}
.glyphicon-triangle-left:before {
  content: "\e251";
}
.glyphicon-triangle-bottom:before {
  content: "\e252";
}
.glyphicon-triangle-top:before {
  content: "\e253";
}
.glyphicon-console:before {
  content: "\e254";
}
.glyphicon-superscript:before {
  content: "\e255";
}
.glyphicon-subscript:before {
  content: "\e256";
}
.glyphicon-menu-left:before {
  content: "\e257";
}
.glyphicon-menu-right:before {
  content: "\e258";
}
.glyphicon-menu-down:before {
  content: "\e259";
}
.glyphicon-menu-up:before {
  content: "\e260";
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
}
body {
  font-family: helvetica neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
a {
  color: #337ab7;
  text-decoration: none;
}
a:focus,
a:hover {
  color: #23527c;
  text-decoration: underline;
}
a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
figure {
  margin: 0;
}
img {
  vertical-align: middle;
}
.carousel-inner > .item > a > img,
.carousel-inner > .item > img,
.img-responsive,
.thumbnail a > img,
.thumbnail > img {
  display: block;
  max-width: 100%;
  height: auto;
}
.img-rounded {
  border-radius: 6px;
}
.img-thumbnail {
  display: inline-block;
  max-width: 100%;
  height: auto;
  padding: 4px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.img-circle {
  border-radius: 50%;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
[role="button"] {
  cursor: pointer;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
  font-weight: 400;
  line-height: 1;
  color: #777;
}
.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small {
  font-size: 65%;
}
.h4,
.h5,
.h6,
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
  font-size: 75%;
}
.h1,
h1 {
  font-size: 36px;
}
.h2,
h2 {
  font-size: 30px;
}
.h3,
h3 {
  font-size: 24px;
}
.h4,
h4 {
  font-size: 18px;
}
.h5,
h5 {
  font-size: 14px;
}
.h6,
h6 {
  font-size: 12px;
}
p {
  margin: 0 0 10px;
}
.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .lead {
    font-size: 21px;
  }
}
.small,
small {
  font-size: 85%;
}
.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-muted {
  color: #777;
}
.text-primary {
  color: #337ab7;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #286090;
}
.text-success {
  color: #3c763d;
}
a.text-success:focus,
a.text-success:hover {
  color: #2b542c;
}
.text-info {
  color: #31708f;
}
a.text-info:focus,
a.text-info:hover {
  color: #245269;
}
.text-warning {
  color: #8a6d3b;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #66512c;
}
.text-danger {
  color: #a94442;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #843534;
}
.bg-primary {
  color: #fff;
  background-color: #337ab7;
}
a.bg-primary:focus,
a.bg-primary:hover {
  background-color: #286090;
}
.bg-success {
  background-color: #dff0d8;
}
a.bg-success:focus,
a.bg-success:hover {
  background-color: #c1e2b3;
}
.bg-info {
  background-color: #d9edf7;
}
a.bg-info:focus,
a.bg-info:hover {
  background-color: #afd9ee;
}
.bg-warning {
  background-color: #fcf8e3;
}
a.bg-warning:focus,
a.bg-warning:hover {
  background-color: #f7ecb5;
}
.bg-danger {
  background-color: #f2dede;
}
a.bg-danger:focus,
a.bg-danger:hover {
  background-color: #e4b9b9;
}
.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}
ol,
ul {
  margin-top: 0;
  margin-bottom: 10px;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}
.list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}
dl {
  margin-top: 0;
  margin-bottom: 20px;
}
dd,
dt {
  line-height: 1.42857143;
}
dt {
  font-weight: 700;
}
dd {
  margin-left: 0;
}
@media (min-width: 768px) {
  .banner-text {
    top: 0;
  }
  .gold-banner img {
    height: 245px;
    object-fit: cover;
  }
  .dl-horizontal dt {
    float: left;
    width: 160px;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dl-horizontal dd {
    margin-left: 180px;
  }
}
abbr[data-original-title],
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #777;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}
blockquote ol:last-child,
blockquote p:last-child,
blockquote ul:last-child {
  margin-bottom: 0;
}
blockquote .small,
blockquote footer,
blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777;
}
blockquote .small:before,
blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}
.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid #eee;
  border-left: 0;
}
.blockquote-reverse .small:before,
.blockquote-reverse footer:before,
.blockquote-reverse small:before,
blockquote.pull-right .small:before,
blockquote.pull-right footer:before,
blockquote.pull-right small:before {
  content: "";
}
.blockquote-reverse .small:after,
.blockquote-reverse footer:after,
.blockquote-reverse small:after,
blockquote.pull-right .small:after,
blockquote.pull-right footer:after,
blockquote.pull-right small:after {
  content: "\00A0 \2014";
}
address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857143;
}
code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, courier new, monospace;
}
code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  -webkit-box-shadow: none;
  box-shadow: none;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  /* word-break: break-all; */
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0;
}
@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
}
table {
  background-color: transparent;
}
caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left;
}
th {
  text-align: left;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}
.table > caption + thead > tr:first-child > td,
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > td,
.table > thead:first-child > tr:first-child > th {
  border-top: 0;
}
.table > tbody + tbody {
  border-top: 2px solid #ddd;
}
.table .table {
  background-color: #fff;
}
.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
  padding: 5px;
}
.table-bordered {
  border: 1px solid #ddd;
}
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 1px solid #ddd;
}
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border-bottom-width: 2px;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}
.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}
table col[class*="col-"] {
  position: static;
  display: table-column;
  float: none;
}
table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  display: table-cell;
  float: none;
}
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > thead > tr > td.active,
.table > thead > tr > th.active {
  background-color: #f5f5f5;
}
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr.active:hover > th,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover {
  background-color: #e8e8e8;
}
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > thead > tr > td.success,
.table > thead > tr > th.success {
  background-color: #dff0d8;
}
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr.success:hover > th,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover {
  background-color: #d0e9c6;
}
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > thead > tr > td.info,
.table > thead > tr > th.info {
  background-color: #d9edf7;
}
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr.info:hover > th,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover {
  background-color: #c4e3f3;
}
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > thead > tr > td.warning,
.table > thead > tr > th.warning {
  background-color: #fcf8e3;
}
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr.warning:hover > th,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover {
  background-color: #faf2cc;
}
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > thead > tr > td.danger,
.table > thead > tr > th.danger {
  background-color: #f2dede;
}
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr.danger:hover > th,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover {
  background-color: #ebcccc;
}
.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}
@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > td,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > thead > tr > th {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > thead > tr > th:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > thead > tr > th:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th {
    border-bottom: 0;
  }
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input[type="checkbox"],
input[type="radio"] {
  margin: 4px 0 0;
  margin-top: 1px\9;
  line-height: normal;
}
input[type="file"] {
  display: block;
}
input[type="range"] {
  display: block;
  width: 100%;
}
select[multiple],
select[size] {
  height: auto;
}
input[type="file"]:focus,
input[type="checkbox"]:focus,
input[type="radio"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
}
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control::-webkit-input-placeholder {
  color: #999;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eee;
  opacity: 1;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}
textarea.form-control {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: none;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 34px;
  }
  .input-group-sm input[type="date"],
  .input-group-sm input[type="time"],
  .input-group-sm input[type="datetime-local"],
  .input-group-sm input[type="month"],
  input[type="date"].input-sm,
  input[type="time"].input-sm,
  input[type="datetime-local"].input-sm,
  input[type="month"].input-sm {
    line-height: 30px;
  }
  .input-group-lg input[type="date"],
  .input-group-lg input[type="time"],
  .input-group-lg input[type="datetime-local"],
  .input-group-lg input[type="month"],
  input[type="date"].input-lg,
  input[type="time"].input-lg,
  input[type="datetime-local"].input-lg,
  input[type="month"].input-lg {
    line-height: 46px;
  }
}
.form-group {
  margin-bottom: 15px;
}
.checkbox,
.radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.checkbox label,
.radio label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
}
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
  position: absolute;
  margin-top: 4px\9;
  margin-left: -20px;
}
.checkbox + .checkbox,
.radio + .radio {
  margin-top: -5px;
}
.checkbox-inline,
.radio-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
}
.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
  margin-top: 0;
  margin-left: 10px;
}
fieldset[disabled] input[type="checkbox"],
fieldset[disabled] input[type="radio"],
input[type="checkbox"].disabled,
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="radio"][disabled] {
  cursor: not-allowed;
}
.checkbox-inline.disabled,
.radio-inline.disabled,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .radio-inline {
  cursor: not-allowed;
}
.checkbox.disabled label,
.radio.disabled label,
fieldset[disabled] .checkbox label,
fieldset[disabled] .radio label {
  cursor: not-allowed;
}
.form-control-static {
  min-height: 34px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
}
.form-control-static.input-lg,
.form-control-static.input-sm {
  padding-right: 0;
  padding-left: 0;
}
.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
select.input-sm {
  height: 30px;
  line-height: 30px;
}
select[multiple].input-sm,
textarea.input-sm {
  height: auto;
}
.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px;
}
.form-group-sm select[multiple].form-control,
.form-group-sm textarea.form-control {
  height: auto;
}
.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}
select.input-lg {
  height: 46px;
  line-height: 46px;
}
select[multiple].input-lg,
textarea.input-lg {
  height: auto;
}
.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}
.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px;
}
.form-group-lg select[multiple].form-control,
.form-group-lg textarea.form-control {
  height: auto;
}
.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}
.has-feedback {
  position: relative;
}
.has-feedback .form-control {
  padding-right: 42.5px;
}
.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}
.form-group-lg .form-control + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.input-lg + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}
.form-group-sm .form-control + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.input-sm + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label {
  color: #3c763d;
}
.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}
.has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d;
}
.has-success .form-control-feedback {
  color: #3c763d;
}
.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label {
  color: #8a6d3b;
}
.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-warning .form-control:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}
.has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b;
}
.has-warning .form-control-feedback {
  color: #8a6d3b;
}
.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
  color: #a94442;
}
.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
.has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}
.has-error .form-control-feedback {
  color: #a94442;
}
.has-feedback label ~ .form-control-feedback {
  top: 25px;
}
.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}
.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}
@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-static {
    display: inline-block;
  }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .form-inline .input-group .form-control,
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn {
    width: auto;
  }
  .form-inline .input-group > .form-control {
    width: 100%;
  }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .checkbox,
  .form-inline .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .checkbox label,
  .form-inline .radio label {
    padding-left: 0;
  }
  .form-inline .checkbox input[type="checkbox"],
  .form-inline .radio input[type="radio"] {
    position: relative;
    margin-left: 0;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}
.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
}
.form-horizontal .checkbox,
.form-horizontal .radio {
  min-height: 27px;
}
.form-horizontal .form-group {
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
  }
}
.form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}
@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px;
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px;
  }
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn.focus,
.btn:focus,
.btn:hover {
  color: #333;
  text-decoration: none;
}
.btn.active,
.btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65;
}
a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default.focus,
.btn-default:focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}
.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default.active,
.btn-default:active,
.open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default.focus,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.btn-default.active,
.btn-default:active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled.focus,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled].focus,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
  background-color: #fff;
  border-color: #ccc;
}
.btn-default .badge {
  color: #fff;
  background-color: #333;
}
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}
.btn-primary:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled.focus,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled].focus,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn-primary .badge {
  color: #337ab7;
  background-color: #fff;
}
.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625;
}
.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled.focus,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success[disabled].focus,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:hover {
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}
.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85;
}
.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled.focus,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info[disabled].focus,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:hover {
  background-color: #5bc0de;
  border-color: #46b8da;
}
.btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}
.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning.focus,
.btn-warning:focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d;
}
.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning:active.focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled.focus,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning[disabled].focus,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:hover {
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}
.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled.focus,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger[disabled].focus,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:hover {
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}
.btn-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
}
.btn-link,
.btn-link.active,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
  border-color: transparent;
}
.btn-link:focus,
.btn-link:hover {
  color: #23527c;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:focus,
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:hover {
  color: #777;
  text-decoration: none;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-group-xs > .btn,
.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 0.35s;
  -o-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-property: height, visibility;
  -o-transition-property: height, visibility;
  transition-property: height, visibility;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdown,
.dropup {
  position: relative;
}
.dropdown-toggle:focus {
  outline: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:focus,
.dropdown-menu > .disabled > a:hover {
  color: #777;
}
.dropdown-menu > .disabled > a:focus,
.dropdown-menu > .disabled > a:hover {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.open > .dropdown-menu {
  display: block;
}
.open > a {
  outline: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777;
  white-space: nowrap;
}
.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}
@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  .navbar-right .dropdown-menu-left {
    right: auto;
    left: 0;
  }
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  float: left;
}
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 2;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px;
}
.btn-toolbar {
  margin-left: -5px;
}
.btn-toolbar .btn,
.btn-toolbar .btn-group,
.btn-toolbar .input-group {
  float: left;
}
.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
  margin-left: 5px;
}
.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}
.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group > .btn-group {
  float: left;
}
.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}
.btn-group > .btn + .dropdown-toggle {
  padding-right: 8px;
  padding-left: 8px;
}
.btn-group > .btn-lg + .dropdown-toggle {
  padding-right: 12px;
  padding-left: 12px;
}
.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn .caret {
  margin-left: 0;
}
.btn-lg .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0;
}
.dropup .btn-lg .caret {
  border-width: 0 5px 5px;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
}
.btn-group-vertical > .btn-group > .btn {
  float: none;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical
  > .btn-group:first-child:not(:last-child)
  > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical
  > .btn-group:last-child:not(:first-child)
  > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.btn-group-justified > .btn,
.btn-group-justified > .btn-group {
  display: table-cell;
  float: none;
  width: 1%;
}
.btn-group-justified > .btn-group .btn {
  width: 100%;
}
.btn-group-justified > .btn-group .dropdown-menu {
  left: auto;
}
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}
.input-group[class*="col-"] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.input-group .form-control:focus {
  z-index: 3;
}
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}
select.input-group-lg > .form-control,
select.input-group-lg > .input-group-addon,
select.input-group-lg > .input-group-btn > .btn {
  height: 46px;
  line-height: 46px;
}
select[multiple].input-group-lg > .form-control,
select[multiple].input-group-lg > .input-group-addon,
select[multiple].input-group-lg > .input-group-btn > .btn,
textarea.input-group-lg > .form-control,
textarea.input-group-lg > .input-group-addon,
textarea.input-group-lg > .input-group-btn > .btn {
  height: auto;
}
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
select.input-group-sm > .form-control,
select.input-group-sm > .input-group-addon,
select.input-group-sm > .input-group-btn > .btn {
  height: 30px;
  line-height: 30px;
}
select[multiple].input-group-sm > .form-control,
select[multiple].input-group-sm > .input-group-addon,
select[multiple].input-group-sm > .input-group-btn > .btn,
textarea.input-group-sm > .form-control,
textarea.input-group-sm > .input-group-addon,
textarea.input-group-sm > .input-group-btn > .btn {
  height: auto;
}
.input-group .form-control,
.input-group-addon,
.input-group-btn {
  display: table-cell;
}
.input-group .form-control:not(:first-child):not(:last-child),
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}
.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.input-group-addon.input-sm {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
}
.input-group-addon.input-lg {
  padding: 10px 16px;
  font-size: 18px;
  border-radius: 6px;
}
.input-group-addon input[type="checkbox"],
.input-group-addon input[type="radio"] {
  margin-top: 0;
}
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-addon:first-child {
  border-right: 0;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-addon:last-child {
  border-left: 0;
}
.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}
.input-group-btn > .btn {
  position: relative;
}
.input-group-btn > .btn + .btn {
  margin-left: -1px;
}
.input-group-btn > .btn:active,
.input-group-btn > .btn:focus,
.input-group-btn > .btn:hover {
  z-index: 2;
}
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
  margin-right: -1px;
}
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px;
}
.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav > li {
  position: relative;
  display: block;
}
.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}
.nav > li > a:focus,
.nav > li > a:hover {
  text-decoration: none;
  background-color: #eee;
}
.nav > li.disabled > a {
  color: #777;
}
.nav > li.disabled > a:focus,
.nav > li.disabled > a:hover {
  color: #777;
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: #eee;
  border-color: #337ab7;
}
.nav .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.nav > li > a > img {
  max-width: none;
}
.nav-tabs {
  border-bottom: 1px solid #ddd;
}
.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}
.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}
.nav-tabs > li > a:hover {
  border-color: #eee #eee #ddd;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}
.nav-tabs.nav-justified {
  width: 100%;
  border-bottom: 0;
}
.nav-tabs.nav-justified > li {
  float: none;
}
.nav-tabs.nav-justified > li > a {
  margin-bottom: 5px;
  text-align: center;
}
.nav-tabs.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}
@media (min-width: 768px) {
  .nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%;
  }
  .nav-tabs.nav-justified > li > a {
    margin-bottom: 0;
  }
}
.nav-tabs.nav-justified > li > a {
  margin-right: 0;
  border-radius: 4px;
}
.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:focus,
.nav-tabs.nav-justified > .active > a:hover {
  border: 1px solid #ddd;
}
@media (min-width: 768px) {
  .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
  }
  .nav-tabs.nav-justified > .active > a,
  .nav-tabs.nav-justified > .active > a:focus,
  .nav-tabs.nav-justified > .active > a:hover {
    border-bottom-color: #fff;
  }
}
.nav-pills > li {
  float: left;
}
.nav-pills > li > a {
  border-radius: 4px;
}
.nav-pills > li + li {
  margin-left: 2px;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  color: #fff;
  background-color: #337ab7;
}
.nav-stacked > li {
  float: none;
}
.nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0;
}
.nav-justified {
  width: 100%;
}
.nav-justified > li {
  float: none;
}
.nav-justified > li > a {
  margin-bottom: 5px;
  text-align: center;
}
.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}
@media (min-width: 768px) {
  .nav-justified > li {
    display: table-cell;
    width: 1%;
  }
  .nav-justified > li > a {
    margin-bottom: 0;
  }
}
.nav-tabs-justified {
  border-bottom: 0;
}
.nav-tabs-justified > li > a {
  margin-right: 0;
  border-radius: 4px;
}
.nav-tabs-justified > .active > a,
.nav-tabs-justified > .active > a:focus,
.nav-tabs-justified > .active > a:hover {
  border: 1px solid #ddd;
}
@media (min-width: 768px) {
  .nav-tabs-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
  }
  .nav-tabs-justified > .active > a,
  .nav-tabs-justified > .active > a:focus,
  .nav-tabs-justified > .active > a:hover {
    border-bottom-color: #fff;
  }
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}
@media (min-width: 768px) {
  .navbar {
    border-radius: 4px;
  }
}
@media (min-width: 768px) {
  .navbar-header {
    float: left;
  }
}
.navbar-collapse {
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}
.navbar-collapse.in {
  overflow-y: auto;
}
@media (min-width: 768px) {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  .navbar-collapse.in {
    overflow-y: visible;
  }
  .navbar-fixed-bottom .navbar-collapse,
  .navbar-fixed-top .navbar-collapse,
  .navbar-static-top .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }
}
.navbar-fixed-bottom .navbar-collapse,
.navbar-fixed-top .navbar-collapse {
  max-height: 340px;
}
@media (max-device-width: 480px) and (orientation: landscape) {
  .navbar-fixed-bottom .navbar-collapse,
  .navbar-fixed-top .navbar-collapse {
    max-height: 200px;
  }
}
.container-fluid > .navbar-collapse,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container > .navbar-header {
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 768px) {
  .container-fluid > .navbar-collapse,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container > .navbar-header {
    margin-right: 0;
    margin-left: 0;
  }
}
.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
}
@media (min-width: 768px) {
  .navbar-static-top {
    border-radius: 0;
  }
}
.navbar-fixed-bottom,
.navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}
@media (min-width: 768px) {
  .navbar-fixed-bottom,
  .navbar-fixed-top {
    border-radius: 0;
  }
}
.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}
.navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0;
}
.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px;
  font-size: 18px;
  line-height: 20px;
}
.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-brand > img {
  display: block;
}
@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: -15px;
  }
}
.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 8px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.navbar-toggle:focus {
  outline: 0;
}
.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}
.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}
@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
}
.navbar-nav {
  margin: 7.5px -15px;
}
.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}
@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .navbar-nav .open .dropdown-menu .dropdown-header,
  .navbar-nav .open .dropdown-menu > li > a {
    padding: 5px 15px 5px 25px;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }
  .navbar-nav .open .dropdown-menu > li > a:focus,
  .navbar-nav .open .dropdown-menu > li > a:hover {
    background-image: none;
  }
}
@media (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }
  .navbar-nav > li {
    float: left;
  }
  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.navbar-form {
  padding: 10px 15px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
    0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
    0 1px 0 rgba(255, 255, 255, 0.1);
  margin: 8px -15px;
}
@media (min-width: 768px) {
  .navbar-form .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .navbar-form .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .navbar-form .form-control-static {
    display: inline-block;
  }
  .navbar-form .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .navbar-form .input-group .form-control,
  .navbar-form .input-group .input-group-addon,
  .navbar-form .input-group .input-group-btn {
    width: auto;
  }
  .navbar-form .input-group > .form-control {
    width: 100%;
  }
  .navbar-form .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .navbar-form .checkbox,
  .navbar-form .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .navbar-form .checkbox label,
  .navbar-form .radio label {
    padding-left: 0;
  }
  .navbar-form .checkbox input[type="checkbox"],
  .navbar-form .radio input[type="radio"] {
    position: relative;
    margin-left: 0;
  }
  .navbar-form .has-feedback .form-control-feedback {
    top: 0;
  }
}
@media (max-width: 767px) {
  .navbar-form .form-group {
    margin-bottom: 5px;
  }
  .navbar-form .form-group:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .navbar-form {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  margin-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.navbar-btn {
  margin-top: 8px;
  margin-bottom: 8px;
}
.navbar-btn.btn-sm {
  margin-top: 10px;
  margin-bottom: 10px;
}
.navbar-btn.btn-xs {
  margin-top: 14px;
  margin-bottom: 14px;
}
.navbar-text {
  margin-top: 15px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .navbar-text {
    float: left;
    margin-right: 15px;
    margin-left: 15px;
  }
}
@media (min-width: 768px) {
  .navbar-left {
    float: left !important;
  }
  .navbar-right {
    float: right !important;
    margin-right: -15px;
  }
  .navbar-right ~ .navbar-right {
    margin-right: 0;
  }
}
.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
}
.navbar-default .navbar-brand {
  color: #777;
}
.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:hover {
  color: #5e5e5e;
  background-color: transparent;
}
.navbar-default .navbar-text {
  color: #777;
}
.navbar-default .navbar-nav > li > a {
  color: #777;
}
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: #333;
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  color: #555;
  background-color: #e7e7e7;
}
.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:focus,
.navbar-default .navbar-nav > .disabled > a:hover {
  color: #ccc;
  background-color: transparent;
}
.navbar-default .navbar-toggle {
  border-color: #ddd;
}
.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background-color: #ddd;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #888;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #e7e7e7;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  color: #555;
  background-color: #e7e7e7;
}
@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #777;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #333;
    background-color: transparent;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover {
    color: #555;
    background-color: #e7e7e7;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover {
    color: #ccc;
    background-color: transparent;
  }
}
.navbar-default .navbar-link {
  color: #777;
}
.navbar-default .navbar-link:hover {
  color: #333;
}
.navbar-default .btn-link {
  color: #777;
}
.navbar-default .btn-link:focus,
.navbar-default .btn-link:hover {
  color: #333;
}
.navbar-default .btn-link[disabled]:focus,
.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:focus,
fieldset[disabled] .navbar-default .btn-link:hover {
  color: #ccc;
}
.navbar-inverse {
  background-color: #222;
  border-color: #080808;
}
.navbar-inverse .navbar-brand {
  color: #9d9d9d;
}
.navbar-inverse .navbar-brand:focus,
.navbar-inverse .navbar-brand:hover {
  color: #fff;
  background-color: transparent;
}
.navbar-inverse .navbar-text {
  color: #9d9d9d;
}
.navbar-inverse .navbar-nav > li > a {
  color: #9d9d9d;
}
.navbar-inverse .navbar-nav > li > a:focus,
.navbar-inverse .navbar-nav > li > a:hover {
  color: #fff;
  background-color: transparent;
}
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .active > a:hover {
  color: #fff;
  background-color: #080808;
}
.navbar-inverse .navbar-nav > .disabled > a,
.navbar-inverse .navbar-nav > .disabled > a:focus,
.navbar-inverse .navbar-nav > .disabled > a:hover {
  color: #444;
  background-color: transparent;
}
.navbar-inverse .navbar-toggle {
  border-color: #333;
}
.navbar-inverse .navbar-toggle:focus,
.navbar-inverse .navbar-toggle:hover {
  background-color: #333;
}
.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff;
}
.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: #101010;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:focus,
.navbar-inverse .navbar-nav > .open > a:hover {
  color: #fff;
  background-color: #080808;
}
@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #080808;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #080808;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #9d9d9d;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus,
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #fff;
    background-color: transparent;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover {
    color: #fff;
    background-color: #080808;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover {
    color: #444;
    background-color: transparent;
  }
}
.navbar-inverse .navbar-link {
  color: #9d9d9d;
}
.navbar-inverse .navbar-link:hover {
  color: #fff;
}
.navbar-inverse .btn-link {
  color: #9d9d9d;
}
.navbar-inverse .btn-link:focus,
.navbar-inverse .btn-link:hover {
  color: #fff;
}
.navbar-inverse .btn-link[disabled]:focus,
.navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .navbar-inverse .btn-link:focus,
fieldset[disabled] .navbar-inverse .btn-link:hover {
  color: #444;
}
.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 4px;
}
.breadcrumb > li {
  display: inline-block;
}
.breadcrumb > li + li:before {
  padding: 0 5px;
  color: #ccc;
  content: "/\00a0";
}
.breadcrumb > .active {
  color: #777;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}
.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.pager {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;
}
.pager li {
  display: inline;
}
.pager li > a,
.pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
}
.pager li > a:focus,
.pager li > a:hover {
  text-decoration: none;
  background-color: #eee;
}
.pager .next > a,
.pager .next > span {
  float: right;
}
.pager .previous > a,
.pager .previous > span {
  float: left;
}
.pager .disabled > a,
.pager .disabled > a:focus,
.pager .disabled > a:hover,
.pager .disabled > span {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
}
.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
a.label:focus,
a.label:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.label:empty {
  display: none;
}
.btn .label {
  position: relative;
  top: -1px;
}
.label-default {
  background-color: #777;
}
.label-default[href]:focus,
.label-default[href]:hover {
  background-color: #5e5e5e;
}
.label-primary {
  background-color: #337ab7;
}
.label-primary[href]:focus,
.label-primary[href]:hover {
  background-color: #286090;
}
.label-success {
  background-color: #5cb85c;
}
.label-success[href]:focus,
.label-success[href]:hover {
  background-color: #449d44;
}
.label-info {
  background-color: #5bc0de;
}
.label-info[href]:focus,
.label-info[href]:hover {
  background-color: #31b0d5;
}
.label-warning {
  background-color: #f0ad4e;
}
.label-warning[href]:focus,
.label-warning[href]:hover {
  background-color: #ec971f;
}
.label-danger {
  background-color: #d9534f;
}
.label-danger[href]:focus,
.label-danger[href]:hover {
  background-color: #c9302c;
}
.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.btn-group-xs > .btn .badge,
.btn-xs .badge {
  top: 0;
  padding: 1px 5px;
}
a.badge:focus,
a.badge:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
  color: #337ab7;
  background-color: #fff;
}
.list-group-item > .badge {
  float: right;
}
.list-group-item > .badge + .badge {
  margin-right: 5px;
}
.nav-pills > li > a > .badge {
  margin-left: 3px;
}
.jumbotron {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  color: inherit;
  background-color: #eee;
}
.jumbotron .h1,
.jumbotron h1 {
  color: inherit;
}
.jumbotron p {
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200;
}
.jumbotron > hr {
  border-top-color: #d5d5d5;
}
.container .jumbotron,
.container-fluid .jumbotron {
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 6px;
}
.jumbotron .container {
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .container .jumbotron,
  .container-fluid .jumbotron {
    padding-right: 60px;
    padding-left: 60px;
  }
  .jumbotron .h1,
  .jumbotron h1 {
    font-size: 63px;
  }
}
.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}
.thumbnail a > img,
.thumbnail > img {
  margin-right: auto;
  margin-left: auto;
}
a.thumbnail.active,
a.thumbnail:focus,
a.thumbnail:hover {
  border-color: #337ab7;
}
.thumbnail .caption {
  padding: 9px;
  color: #333;
}
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert h4 {
  margin-top: 0;
  color: inherit;
}
.alert .alert-link {
  font-weight: 700;
}
.alert > p,
.alert > ul {
  margin-bottom: 0;
}
.alert > p + p {
  margin-top: 5px;
}
.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}
.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}
.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.alert-success hr {
  border-top-color: #c9e2b3;
}
.alert-success .alert-link {
  color: #2b542c;
}
.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.alert-info hr {
  border-top-color: #a6e1ec;
}
.alert-info .alert-link {
  color: #245269;
}
.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.alert-warning hr {
  border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
  color: #66512c;
}
.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.alert-danger hr {
  border-top-color: #e4b9c0;
}
.alert-danger .alert-link {
  color: #843534;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.progress-bar-striped,
.progress-striped .progress-bar {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}
.progress-bar.active,
.progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
.progress-bar-success {
  background-color: #5cb85c;
}
.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-info {
  background-color: #5bc0de;
}
.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-warning {
  background-color: #f0ad4e;
}
.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-danger {
  background-color: #d9534f;
}
.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.media {
  margin-top: 15px;
}
.media:first-child {
  margin-top: 0;
}
.media,
.media-body {
  overflow: hidden;
  zoom: 1;
}
.media-body {
  width: 10000px;
}
.media-object {
  display: block;
}
.media-object.img-thumbnail {
  max-width: none;
}
.media-right,
.media > .pull-right {
  padding-left: 10px;
}
.media-left,
.media > .pull-left {
  padding-right: 10px;
}
.media-body,
.media-left,
.media-right {
  display: table-cell;
  vertical-align: top;
}
.media-middle {
  vertical-align: middle;
}
.media-bottom {
  vertical-align: bottom;
}
.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}
.media-list {
  padding-left: 0;
  list-style: none;
}
.list-group {
  padding-left: 0;
  margin-bottom: 20px;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}
.list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
a.list-group-item,
button.list-group-item {
  color: #555;
}
a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
  color: #333;
}
a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
  color: #555;
  text-decoration: none;
  background-color: #f5f5f5;
}
button.list-group-item {
  width: 100%;
  text-align: left;
}
.list-group-item.disabled,
.list-group-item.disabled:focus,
.list-group-item.disabled:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #eee;
}
.list-group-item.disabled .list-group-item-heading,
.list-group-item.disabled:focus .list-group-item-heading,
.list-group-item.disabled:hover .list-group-item-heading {
  color: inherit;
}
.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text {
  color: #777;
}
.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  z-index: 2;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}
.list-group-item.active .list-group-item-heading,
.list-group-item.active .list-group-item-heading > .small,
.list-group-item.active .list-group-item-heading > small,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading > .small,
.list-group-item.active:focus .list-group-item-heading > small,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading > .small,
.list-group-item.active:hover .list-group-item-heading > small {
  color: inherit;
}
.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
  color: #c7ddef;
}
.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}
a.list-group-item-success,
button.list-group-item-success {
  color: #3c763d;
}
a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
  color: inherit;
}
a.list-group-item-success:focus,
a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
  color: #3c763d;
  background-color: #d0e9c6;
}
a.list-group-item-success.active,
a.list-group-item-success.active:focus,
a.list-group-item-success.active:hover,
button.list-group-item-success.active,
button.list-group-item-success.active:focus,
button.list-group-item-success.active:hover {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;
}
.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}
a.list-group-item-info,
button.list-group-item-info {
  color: #31708f;
}
a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
  color: inherit;
}
a.list-group-item-info:focus,
a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
  color: #31708f;
  background-color: #c4e3f3;
}
a.list-group-item-info.active,
a.list-group-item-info.active:focus,
a.list-group-item-info.active:hover,
button.list-group-item-info.active,
button.list-group-item-info.active:focus,
button.list-group-item-info.active:hover {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}
.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}
a.list-group-item-warning,
button.list-group-item-warning {
  color: #8a6d3b;
}
a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
  color: inherit;
}
a.list-group-item-warning:focus,
a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
  color: #8a6d3b;
  background-color: #faf2cc;
}
a.list-group-item-warning.active,
a.list-group-item-warning.active:focus,
a.list-group-item-warning.active:hover,
button.list-group-item-warning.active,
button.list-group-item-warning.active:focus,
button.list-group-item-warning.active:hover {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}
.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede;
}
a.list-group-item-danger,
button.list-group-item-danger {
  color: #a94442;
}
a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
  color: inherit;
}
a.list-group-item-danger:focus,
a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
  color: #a94442;
  background-color: #ebcccc;
}
a.list-group-item-danger.active,
a.list-group-item-danger.active:focus,
a.list-group-item-danger.active:hover,
button.list-group-item-danger.active,
button.list-group-item-danger.active:focus,
button.list-group-item-danger.active:hover {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;
}
.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}
.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}
.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.panel-body {
  padding: 15px;
}
.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}
.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}
.panel-title > .small,
.panel-title > .small > a,
.panel-title > a,
.panel-title > small,
.panel-title > small > a {
  color: inherit;
}
.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}
.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}
.panel > .list-group:first-child .list-group-item:first-child,
.panel
  > .panel-collapse
  > .list-group:first-child
  .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel
  > .panel-heading
  + .panel-collapse
  > .list-group
  .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}
.list-group + .panel-footer {
  border-top-width: 0;
}
.panel > .panel-collapse > .table,
.panel > .table,
.panel > .table-responsive > .table {
  margin-bottom: 0;
}
.panel > .panel-collapse > .table caption,
.panel > .table caption,
.panel > .table-responsive > .table caption {
  padding-right: 15px;
  padding-left: 15px;
}
.panel > .table-responsive:first-child > .table:first-child,
.panel > .table:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel
  > .table-responsive:first-child
  > .table:first-child
  > tbody:first-child
  > tr:first-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > thead:first-child
  > tr:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel
  > .table-responsive:first-child
  > .table:first-child
  > tbody:first-child
  > tr:first-child
  td:first-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > tbody:first-child
  > tr:first-child
  th:first-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > thead:first-child
  > tr:first-child
  td:first-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > thead:first-child
  > tr:first-child
  th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel
  > .table:first-child
  > thead:first-child
  > tr:first-child
  th:first-child {
  border-top-left-radius: 3px;
}
.panel
  > .table-responsive:first-child
  > .table:first-child
  > tbody:first-child
  > tr:first-child
  td:last-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > tbody:first-child
  > tr:first-child
  th:last-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > thead:first-child
  > tr:first-child
  td:last-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > thead:first-child
  > tr:first-child
  th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child {
  border-top-right-radius: 3px;
}
.panel > .table-responsive:last-child > .table:last-child,
.panel > .table:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tbody:last-child
  > tr:last-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tfoot:last-child
  > tr:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tbody:last-child
  > tr:last-child
  td:first-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tbody:last-child
  > tr:last-child
  th:first-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tfoot:last-child
  > tr:last-child
  td:first-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tfoot:last-child
  > tr:last-child
  th:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 3px;
}
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tbody:last-child
  > tr:last-child
  td:last-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tbody:last-child
  > tr:last-child
  th:last-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tfoot:last-child
  > tr:last-child
  td:last-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tfoot:last-child
  > tr:last-child
  th:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 3px;
}
.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
  border-top: 1px solid #ddd;
}
.panel > .table > tbody:first-child > tr:first-child td,
.panel > .table > tbody:first-child > tr:first-child th {
  border-top: 0;
}
.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0;
}
.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child {
  border-left: 0;
}
.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child {
  border-right: 0;
}
.panel > .table-bordered > tbody > tr:first-child > td,
.panel > .table-bordered > tbody > tr:first-child > th,
.panel > .table-bordered > thead > tr:first-child > td,
.panel > .table-bordered > thead > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > th,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > th {
  border-bottom: 0;
}
.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}
.panel > .table-responsive {
  margin-bottom: 0;
  border: 0;
}
.panel-group {
  margin-bottom: 20px;
}
.panel-group .panel {
  margin-bottom: 0;
  border-radius: 4px;
}
.panel-group .panel + .panel {
  margin-top: 5px;
}
.panel-group .panel-heading {
  border-bottom: 0;
}
.panel-group .panel-heading + .panel-collapse > .list-group,
.panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: 1px solid #ddd;
}
.panel-group .panel-footer {
  border-top: 0;
}
.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}
.panel-default {
  border-color: #ddd;
}
.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}
.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #333;
}
.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}
.panel-primary {
  border-color: #337ab7;
}
.panel-primary > .panel-heading {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}
.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #337ab7;
}
.panel-primary > .panel-heading .badge {
  color: #337ab7;
  background-color: #fff;
}
.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #337ab7;
}
.panel-success {
  border-color: #d6e9c6;
}
.panel-success > .panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d6e9c6;
}
.panel-success > .panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d;
}
.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d6e9c6;
}
.panel-info {
  border-color: #bce8f1;
}
.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bce8f1;
}
.panel-info > .panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f;
}
.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bce8f1;
}
.panel-warning {
  border-color: #faebcc;
}
.panel-warning > .panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #faebcc;
}
.panel-warning > .panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b;
}
.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #faebcc;
}
.panel-danger {
  border-color: #ebccd1;
}
.panel-danger > .panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ebccd1;
}
.panel-danger > .panel-heading .badge {
  color: #f2dede;
  background-color: #a94442;
}
.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ebccd1;
}
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}
.embed-responsive-4by3 {
  padding-bottom: 75%;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15);
}
.well-lg {
  padding: 24px;
  border-radius: 6px;
}
.well-sm {
  padding: 9px;
  border-radius: 3px;
}
.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
}
.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.5;
}
button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0;
  border: 0;
}
.modal-open {
  overflow: hidden;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
}
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}
.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.modal-header .close {
  margin-top: -2px;
}
.modal-title {
  margin: 0;
  line-height: 1.42857143;
}
.modal-body {
  position: relative;
  padding: 15px;
}
.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: helvetica neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  filter: alpha(opacity=0);
  opacity: 0;
  line-break: auto;
}
.tooltip.in {
  filter: alpha(opacity=90);
  opacity: 0.9;
}
.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}
.tooltip.right {
  padding: 0 5px;
  margin-left: 3px;
}
.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}
.tooltip.left {
  padding: 0 5px;
  margin-left: -3px;
}
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.top-left .tooltip-arrow {
  right: 5px;
  bottom: 0;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: helvetica neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;
}
.popover.top {
  margin-top: -10px;
}
.popover.right {
  margin-left: 10px;
}
.popover.bottom {
  margin-top: 10px;
}
.popover.left {
  margin-left: -10px;
}
.popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}
.popover-content {
  padding: 9px 14px;
}
.popover > .arrow,
.popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.popover > .arrow {
  border-width: 11px;
}
.popover > .arrow:after {
  content: "";
  border-width: 10px;
}
.popover.top > .arrow {
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, 0.25);
  border-bottom-width: 0;
}
.popover.top > .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  content: " ";
  border-top-color: #fff;
  border-bottom-width: 0;
}
.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, 0.25);
  border-left-width: 0;
}
.popover.right > .arrow:after {
  bottom: -10px;
  left: 1px;
  content: " ";
  border-right-color: #fff;
  border-left-width: 0;
}
.popover.bottom > .arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.popover.bottom > .arrow:after {
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
}
.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.popover.left > .arrow:after {
  right: 1px;
  bottom: -10px;
  content: " ";
  border-right-width: 0;
  border-left-color: #fff;
}
.carousel {
  position: relative;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner > .item {
  position: relative;
  display: none;
  -webkit-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}
.carousel-inner > .item > a > img,
.carousel-inner > .item > img {
  line-height: 1;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    -o-transition: -o-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }
  .carousel-inner > .item.active.right,
  .carousel-inner > .item.next {
    left: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  .carousel-inner > .item.active.left,
  .carousel-inner > .item.prev {
    left: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  .carousel-inner > .item.active,
  .carousel-inner > .item.next.left,
  .carousel-inner > .item.prev.right {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}
.carousel-inner > .active {
  left: 0;
}
.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.carousel-inner > .next {
  left: 100%;
}
.carousel-inner > .prev {
  left: -100%;
}
.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}
.carousel-inner > .active.left {
  left: -100%;
}
.carousel-inner > .active.right {
  left: 100%;
}
.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: transparent;
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.carousel-control.left {
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0.0001) 100%
  );
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0.0001) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0.0001))
  );
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0.0001) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
  background-repeat: repeat-x;
}
.carousel-control.right {
  right: 0;
  left: auto;
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.0001) 0,
    rgba(0, 0, 0, 0.5) 100%
  );
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0.0001) 0,
    rgba(0, 0, 0, 0.5) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.0001)),
    to(rgba(0, 0, 0, 0.5))
  );
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.0001) 0,
    rgba(0, 0, 0, 0.5) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
  background-repeat: repeat-x;
}
.carousel-control:focus,
.carousel-control:hover {
  color: #fff;
  text-decoration: none;
  filter: alpha(opacity=90);
  outline: 0;
  opacity: 0.9;
}
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next,
.carousel-control .icon-prev {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
}
.carousel-control .glyphicon-chevron-left,
.carousel-control .icon-prev {
  left: 50%;
  margin-left: -10px;
}
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next {
  right: 50%;
  margin-right: -10px;
}
.carousel-control .icon-next,
.carousel-control .icon-prev {
  width: 20px;
  height: 20px;
  font-family: serif;
  line-height: 1;
}
.carousel-control .icon-prev:before {
  content: "\2039";
}
.carousel-control .icon-next:before {
  content: "\203a";
}
.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
}
.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid #fff;
  border-radius: 10px;
}
.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #fff;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.carousel-caption .btn {
  text-shadow: none;
}
@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next,
  .carousel-control .icon-prev {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px;
  }
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -10px;
  }
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -10px;
  }
  .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px;
  }
  .carousel-indicators {
    bottom: 20px;
  }
}
.btn-group-vertical > .btn-group:after,
.btn-group-vertical > .btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
  display: table;
  content: " ";
}
.btn-group-vertical > .btn-group:after,
.btn-toolbar:after,
.clearfix:after,
.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.modal-footer:after,
.modal-header:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.panel-body:after,
.row:after {
  clear: both;
}
.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
}
.affix {
  position: fixed;
}
.visible-lg,
.visible-md,
.visible-sm,
.visible-xs {
  display: none !important;
}
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  td.visible-xs,
  th.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  td.visible-sm,
  th.visible-sm {
    display: table-cell !important;
  }
  .visible-sm-block {
    display: block !important;
  }
  .visible-sm-inline {
    display: inline !important;
  }
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  td.visible-md,
  th.visible-md {
    display: table-cell !important;
  }
  .visible-md-block {
    display: block !important;
  }
  .visible-md-inline {
    display: inline !important;
  }
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  td.visible-lg,
  th.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  td.visible-print,
  th.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
@media (min-width: 2280px) {
  .container {
    width: 2100px;
  }
}
@media (min-width: 1900px) {
  .container {
    width: 1700px;
  }
}
@media (min-width: 1600px) {
  .container {
    width: 1400px;
  }
}
@media screen and (max-width: 800px) {
  .b-dsk {
    display: none !important;
  }
  .b-mob {
    display: block !important;
  }
  .main-login-pop h2 {
    color: rgba(1, 154, 83, 0.9);
    text-align: center;
  }
  .main-login-pop form input[type="text"],
  .main-login-pop form input[type="password"] {
    padding: 14px 2px !important;
    font-size: 15px;
    margin: 0 0 10px;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
  }
  .main-login-pop form input[type="submit"] {
    width: 50%;
    padding: 22px 30px;
    border-radius: 50px;
    border: 0;
    font-size: 17px;
    color: #fff;
    margin: 18px 0 15px !important;
    background: rgba(1, 154, 83, 0.9) !important;
  }
  .login-with ul li img {
    width: 97%;
    text-align: center;
    margin: 11px 0;
  }
  .login-with ul {
    display: flex;
  }
  .main-login-pop h3 a {
    color: #000;
    margin: 0 0 24px;
    display: block;
    font-size: 14px;
    text-align: right;
  }
  .main-login-pop h4 {
    color: #000 !important;
    font-size: 14px;
    margin: 0 0 5px;
    text-align: center;
  }
  .main-login-pop h4 a {
    color: #00ad76;
    padding: 0 5px;
  }
  .gold-menu a {
    display: inline-block;
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    padding: 4px 6px;
  }
  .m1:hover {
    background: #000;
    color: #fff;
    border-radius: 50px;
  }
  .m1.active {
    background: #000;
    border-radius: 50px;
    color: #fff;
  }
  .m2:hover {
    background: #ffcf00;
    color: #000;
    border-radius: 50px;
  }
  .m2.active {
    background: #ffcf00;
    border-radius: 50px;
    color: #000;
  }
  .m3:hover {
    background: #69ff00;
    color: #000;
    border-radius: 50px;
  }
  .m3.active {
    background: #69ff00;
    border-radius: 50px;
    color: #000;
  }
  .m4:hover {
    background: #00ffdb;
    color: #000;
    border-radius: 50px;
  }
  .m4.active {
    background: #00ffdb;
    border-radius: 50px;
    color: #000;
  }
  .m5:hover {
    background: #b45ef2;
    color: #fff;
    border-radius: 50px;
  }
  .m5.active {
    background: #b45ef2;
    border-radius: 50px;
    color: #fff;
  }
  .m6:hover {
    background: #f641c1;
    color: #fff;
    border-radius: 50px;
  }
  .m6.active {
    background: #f641c1;
    border-radius: 50px;
    color: #fff;
  }
  .m7:hover {
    background: #dec196;
    color: #000;
    border-radius: 50px;
  }
  .m7.active {
    background: #dec196;
    border-radius: 50px;
    color: #000;
  }
  .m8:hover {
    background: #00ffdb;
    color: #000;
    border-radius: 50px;
  }
  .m8.active {
    background: #00ffdb;
    border-radius: 50px;
    color: #000;
  }
  .m9:hover {
    background: #000;
    color: #fff;
    border-radius: 50px;
  }
  .m9.active {
    background: #000;
    border-radius: 50px;
    color: #fff;
  }
  .m10:hover {
    background: #f641c1;
    color: #fff;
    border-radius: 50px;
  }
  .m10.active {
    background: #f641c1;
    border-radius: 50px;
    color: #fff;
  }
  .main-menu {
    display: none;
    position: absolute;
    background: #022450;
    left: 0;
    width: 78%;
    top: 0;
    position: fixed;
    padding: 23px 15px 0 12px;
    height: 100%;
    z-index: 999;
  }
  .menu-toggle {
    display: block;
    position: absolute;
    top: 11px;
    font-size: 28px;
    color: #fff;
    cursor: pointer;
  }
  .sele-date-area select {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    border: 1px solid #ccc;
    padding: 0 17px;
    margin: 9px 0 0;
    border-radius: 50px;
  }
  .main-menu ul li {
    width: 100%;
    text-align: left;
  }
  .main-header {
    background: rgba(1, 154, 83, 0.9);
    padding: 10px 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: auto;
    right: 0;
    margin: 0 auto;
  }
  .left-slide-close {
    position: absolute;
    right: 23px;
    font-size: 26px;
    color: #fff;
    display: block;
    cursor: pointer;
  }
  .login-area {
    right: 26px;
  }
  .head-banner img {
    height: auto;
    object-fit: cover;
    height: 164px;
  }
  .client {
    left: 9px;
  }
  .client img {
    width: 41%;
  }
  .client-deta h2 {
    font-size: 14px;
    margin: 0 0 4px;
  }
  .client-deta a {
    font-size: 20px;
  }
  .client-deta a:hover {
    color: #000;
  }
  .client-deta a i {
    font-size: 20px;
  }
  .client-deta h2 i {
    font-size: 17px;
  }
  .offer-area {
    padding: 12px 0;
  }
  .how-left {
    padding: 30px 18px;
    margin: 0 0 30px;
  }
  .how-left h2 {
    font-size: 22px;
    text-align: center;
  }
  .how-left h3 {
    font-size: 18px;
    line-height: 29px;
  }
  .how-right h2 {
    font-size: 22px;
  }
  .how-right ul li p {
    font-size: 16px;
  }
  .how-right {
    padding: 30px 14px;
  }
  .single-result h2 {
    font-size: 15px;
  }
  .single-result h2 span {
    font-size: 11px;
    padding: 0;
  }
  .single-result ul li p {
    font-size: 20px;
    padding: 6px 24px;
  }
  .bold-sec {
    font-size: 30px !important;
    font-weight: 600;
  }
  .win-left h2 {
    font-size: 25px;
    padding: 17px 26px;
    text-align: center;
  }

  .time-heading {
    background: #000;
    padding: 11px 0;
    height: 146px;
    text-align: center;
    font-size: 20px;
    color: #fff;
    line-height: 34px;
  }
  .win-right {
    margin: 24px 0 0;
  }
  .win-right ul li p {
    font-size: 16px;
  }
  .foot-left p {
    text-align: center;
    padding: 0;
  }
  .foot-right a {
    text-align: center;
    margin: 22px 0;
  }
  .win-all {
    padding: 15px 11px;
    border-radius: 8px;
    background: rgba(1, 154, 83, 0.9);
    width: 100%;
  }
  .win-all h2 {
    font-size: 17px;
  }
  .foot-mid {
    text-align: center;
  }
  .big-win-main {
    width: 100% !important;
    padding: 20px 15px;
    box-shadow: 0 0 34px -9px;
  }
  .online-m-king h2 {
    padding: 27px 0 0;
    font-size: 24px;
  }
  .online-m-king h2::after {
    top: 13px;
    left: 72px;
  }
  .online-m-king p {
    padding: 0;
  }
  .online-m-king h3 {
    margin: 10px 0 16px;
    padding: 0;
  }
  .main-login-pop {
    width: 100%;
    margin: 4px auto;
    padding: 16px 15px;
  }
  .pop-close {
    right: 30px;
    top: 18px;
  }
  .main-login-pop h2 {
    font-size: 23px;
    margin: 0 0 28px;
  }
  .main-login-pop form input[type="text"],
  .main-login-pop form input[type="password"] {
    padding: 14px 30px;
    font-size: 17px;
    margin: 0 0 8px;
  }
  .main-login-pop form input[type="submit"] {
    width: 100%;
    padding: 16px 30px;
    margin: 7px 0 15px;
  }
  .main-login-pop h3 {
    margin: 0;
    font-size: 19px;
  }
  .main-login-pop h4 {
    color: #fff;
    font-size: 14px;
    margin: 0 0 5px;
  }
  .online-m-king img {
    width: 48% !important;
    margin: 0 auto;
  }
  .row.big-win h1 {
    font-size: 30px;
    color: #000;
  }
  .foot-mid p {
    color: #fff;
    font-size: 22px;
    text-align: center;
    padding: 0 0 8px;
    margin: 0;
  }
  .matka-terms {
    width: 100%;
    margin: 13px auto;
    background: #ece9e9;
    padding: 18px 20px;
  }
  .cut {
    padding: 0;
  }
  .matka-terms h2 {
    margin: 0 0 17px;
    font-size: 23px;
  }
  .matka-terms p {
    font-size: 14px;
    line-height: 24px;
  }
  .ref-top {
    padding: 30px 15px;
    width: 100%;
  }
  .ref-top h2 {
    font-size: 21px;
  }
  .ref-top h2 span i {
    font-size: 25px;
  }
  .ref-top h3 {
    margin: 20px 0 23px;
    font-size: 19px;
    line-height: 29px;
  }
  .ref-top h5 {
    font-size: 19px;
    line-height: 28px;
  }
  .invi-area {
    width: 100%;
    padding: 30px 15px;
  }
  .invi-area h2 {
    font-size: 21px;
  }
  .invi-area form input[type="text"] {
    padding: 18px 30px;
    font-size: 13px;
  }
  .invi-area form input[type="submit"] {
    width: 100%;
    padding: 16px 30px;
    font-size: 18px;
  }
  .stat-area {
    width: 100%;
    padding: 30px 15px;
  }
  .stat-area h2 {
    font-size: 22px;
    margin: 0 0 15px;
  }
  .for-top h2 {
    font-size: 25px;
  }
  .chat-user {
    width: 100%;
  }
  .chat-user h2 span {
    float: none;
    padding: 6px 0 0;
    display: block;
    text-align: center;
  }
  .chat-user h2 {
    text-align: center;
  }
  .chat {
    width: 100%;
    padding: 30px 20px;
  }
  .chat form input[type="submit"] {
    padding: 17px 32px;
    width: 100%;
  }
  .sing-post {
    width: 100%;
    padding: 15px;
  }
  .sing-post p {
    font-size: 16px;
  }
  .copy-area {
    width: 100%;
    padding: 30px 15px;
  }
  .copy-area h2 {
    font-size: 21px;
  }
  .copy-area form textarea {
    padding: 17px 10px;
    font-size: 12px;
  }
  ul.tsc_pagination {
    padding: 0;
  }
  ul.tsc_pagination li a {
    padding: 7px 14px;
  }
  .Regis-area {
    right: 19px;
    top: 149px;
  }
  .pop-login-area {
    background: #000 url() no-repeat;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    height: 100%;
    display: none;
    position: absolute;
    z-index: 999;
    height: auto;
  }
  .login-with ul li img {
    margin: 0 0 8px;
  }
  .pop-register-area {
    background: #000 url() no-repeat;
    top: 0;
    left: 0;
    z-index: 9999;
    right: 0;
    margin: 0 auto;
    text-align: center;
    height: auto;
    display: none;
    position: absolute;
  }
  #fexample1_filter label input {
    width: 77%;
  }
  #fexample1_filter label {
    float: left;
    margin: 0 0 22px;
  }
  #fexample1_length label {
    padding: 0 0 12px;
  }
  #fexample1 {
    width: auto;
    display: block;
    overflow-x: auto;
  }
  #fexample1 tbody tr td {
    padding: 11px 5px;
    font-size: 13px;
  }
  .star-line-home .single-star-game {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 640px) {
  .sing-g-his {
    background: #e6cd4d;
    border-radius: 10px;
    padding: 27px 17px;
    margin: 46px 0 47px;
    position: relative;
    border: 3px solid rgba(1, 154, 83, 0.9);
  }
  .count {
    background: rgba(1, 154, 83, 0.9);
    width: 40px;
    height: 40px;
    display: block;
    color: #fefa05;
    font-size: 17px;
    text-align: center;
    line-height: 40px;
    font-weight: 600;
    border-radius: 5px;
    transform: rotate(45deg);
    position: absolute;
    top: -19px;
    left: -19px;
  }
  .sing-g-his ul li p {
    font-size: 14px;
  }
  .row.flot-what a img {
    width: 12%;
  }
  .star-rate {
    padding: 20px 16px;
  }
  .star-new {
    padding: 12px 10px;
  }
  .client-deta {
    padding: 5px 0;
  }
  .star-left img {
    width: 100%;
    padding: 39px 0 0;
  }
  .star-tab button {
    margin: 0 9px 8px;
    display: inline-block;
    float: left;
    width: 45%;
  }
  .bann-img2 {
    position: absolute;
    top: 23px;
    left: 16px;
    width: 37%;
    display: block;
  }
  .banner-text2 {
    position: absolute;
    top: 19px;
    left: 169px;
    z-index: 999;
  }
  .jodi-chat-single table {
    width: 100%;
    margin: 0 0 45px;
    overflow-y: scroll;
    display: inline-block;
  }
  .jodi-chat-single table tbody tr th {
    padding: 19px 11px;
  }
  .all-option2 {
    width: 100%;
    box-shadow: 0 0 16px -5px;
    border-top: 5px solid rgba(1, 154, 83, 0.9);
    border-bottom: 5px solid rgba(1, 154, 83, 0.9);
    padding: 30px 21px;
    margin: 20px auto;
    background: #fff;
  }
  .number-area ul li {
    border-bottom: 1px solid rgba(1, 154, 83, 0.9);
  }
  .number-area {
    border: 1px solid rgba(1, 154, 83, 0.9);
  }
  .show-input .b-num {
    border-radius: 4px 0 0 4px;
  }
  .show-input .d-box {
    border-radius: 0 4px 4px 0;
  }
  .all-option2 h4 {
    text-align: center;
    margin: 43px 0 6px;
    text-transform: capitalize;
    color: #000;
    font-size: 23px;
  }
  .inn-banner-area {
    position: inherit !important;
    background: #fb0;
  }
  .offer-area h1 {
    font-size: 18px;
    margin: 10px 0 28px;
  }
  .offer-area h2 span {
    font-weight: 600;
  }
  .sele-area select {
    width: 100%;
    padding: 0 4px;
  }
  .all-option2 h2 {
    font-size: 24px;
    color: rgba(1, 154, 83, 0.9);
  }
  .all-option2 h3 {
    padding: 0 0 11px;
  }
  .sele-area select {
    margin: 9px 0 3px;
    border-radius: 50px;
    border: 1px solid #ccc;
  }
  .sele-date-area {
    margin: 0 0 24px;
  }
  .gold-top-hed {
    background: rgba(1, 154, 83, 0.9);
  }
  .gold-logo img {
    width: 22%;
  }
  .gold-login-area ul li {
    display: inline-block;
    color: #fff;
    padding: 0;
    font-size: 14px;
  }
  .gold-login-area ul {
    margin: 0;
    text-align: right;
  }
  .gold-login-area ul li a i {
    padding: 0 5px 0 0;
    color: #fb0;
  }
  .gold-login-area ul li a {
    color: #fff;
    padding: 0 0 0 9px;
    font-size: 14px;
  }
  .gold-logo h2 {
    color: #fb0;
    margin: 0;
    font-size: 17px;
    font-weight: 600;
    text-align: left;
    font-weight: 700;
  }
  .gold-top-hed {
    padding: 12px 0;
  }
  .row.gold-menu-area {
    padding: 12px 0;
    background: #00ad76;
    position: inherit;
    margin-top: 55px;
  }
  .gold-menu ul li {
    display: inline-block;
    text-transform: capitalize;
    padding: 0;
  }
  .gold-menu ul li a {
    color: #fff;
  }
  .gold-menu ul {
    margin: 0;
  }
  .gold-notice {
    background: url();
    background: url();
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    padding: 16px 0 11px;
  }
  .g-presult h2 {
    font-size: 21px;
    text-align: center;
    background: rgba(1, 154, 83, 0.9);
    color: #fff;
    padding: 11px 0;
    border-radius: 4px;
    margin: 0 0 10px;
  }
  .g-presult ul li p i {
    padding: 0 14px 0 0;
    color: rgba(1, 154, 83, 0.9);
  }
  .g-presult ul li p {
    border-top: 2px solid rgba(1, 154, 83, 0.9);
    font-size: 16px;
    text-align: center;
    margin: 0 0 10px;
    padding: 13px 0;
    border-radius: 4px;
    background: #fff;
    color: #000;
    border-bottom: 2px solid rgba(1, 154, 83, 0.9);
    border-left: 0;
    border-right: 0;
    box-shadow: 0 4px 7px -4px;
  }
  .row.gold-presult {
    padding: 14px 0 9px;
  }
  .g-notice h2 {
    background: #e5cc4e;
    text-align: center;
    font-size: 21px;
    color: #000;
    padding: 11px 0;
    border-radius: 4px;
    margin: 0 0 10px;
  }
  .g-notice p {
    background: #fff;
    padding: 8px 15px;
    font-size: 13px;
    text-align: center;
    line-height: 21px;
    border-radius: 4px;
    box-shadow: 0 0 8px -4px;
  }
  .g-notice h3 {
    border-top: 2px solid rgba(1, 154, 83, 0.9);
    font-size: 16px;
    text-align: center;
    margin: 0 0 10px;
    padding: 13px 0;
    border-radius: 4px;
    background: #fff;
    color: #000;
    border-bottom: 2px solid rgba(1, 154, 83, 0.9);
    border-left: 0;
    border-right: 0;
    box-shadow: 0 4px 7px -4px;
  }
  .blinking {
    animation: blinkingText 0.8s infinite;
  }
}
@keyframes blinkingText {
  0% {
    color: #000;
  }
  49% {
    color: #9b0000;
  }
  50% {
    color: red;
  }
  99% {
    color: tomato;
  }
  100% {
    color: red;
  }
}
.row.gold-payment-type h2 {
  background: rgba(1, 154, 83, 0.9);
  margin: 0 15px 8px;
  color: #fff;
  font-size: 21px;
  text-align: center;
  padding: 11px 0;
  border-radius: 4px;
}
.row.all-gold-pam {
  margin: 3px 0 7px;
  padding: 4px 15px;
  background: #fff;
  border-radius: 4px;
  border-left: 4px solid rgba(1, 154, 83, 0.9);
  border-right: 5px solid rgba(1, 154, 83, 0.9);
}
.main-gold-pam-det h3 {
  font-size: 20px;
  color: #000;
  margin: 3px 0 9px;
  text-align: center;
}
.main-gold-pam-det h4 {
  margin: 0;
  font-size: 34px;
  font-weight: 600;
  color: #20c000;
  text-align: center;
}
.main-gold-pam-det span {
  background: #000;
  color: #fff;
  text-align: center;
  padding: 5px 20px;
  font-size: 13px;
  margin: 12px 0 0;
  display: inline-block;
}
.dt-g p {
  margin: 15px 0 9px;
  text-align: left;
  padding: 0;
  display: block;
  color: #000;
}
.row.gold-top-palayer h2 {
  text-align: center;
  margin: 5px 14px;
  color: #fae18f;
  font-size: 25px;
  padding: 11px 0;
}
.sing-player {
  background: #fff;
  border-radius: 6px;
  padding: 0 12px;
  border-left: 4px solid #0e50a2;
  border-right: 4px solid #0e50a2;
}
.g-foot img {
  width: 15%;
  padding: 16px 0;
}
.sing-player img {
  position: absolute;
  top: 0;
  width: 16%;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.sing-player h3 {
  text-align: center;
  margin: 0;
  padding: 8px 0;
  font-weight: 600;
  color: #000;
}
.sing-player ul {
  padding: 0;
  margin: 0;
}
.sing-player ul li {
  padding: 7px 18px;
  margin: 0 0 6px;
  font-size: 15px;
  color: #fff;
  background: rgba(1, 154, 83, 0.9);
  border-radius: 4px;
}
.sing-player ul li p {
  margin: 0;
}
.sing-player ul li p span {
  float: right;
  font-weight: 600;
  color: #fff;
}
.sing-player2 {
  margin-bottom: 0;
}
.g-result-main h2 {
  text-align: center;
  font-size: 19px;
  margin: 13px 0 5px;
  color: #fff;
}
.gold-res-area {
  background-size: cover;
  padding: 0 0 12px;
  background-attachment: fixed;
}
.g-main-area h2 {
  background: rgba(1, 154, 83, 0.9);
  text-align: center;
  font-size: 21px;
  color: #fff;
  padding: 12px 0;
  border-radius: 4px;
}
.g-sing-result {
  background: #fff;
  border-left: 5px solid rgba(1, 154, 83, 0.9);
  border-radius: 6px;
  border-right: 5px solid rgba(1, 154, 83, 0.9);
}
.star-line-home .main-sing-play h3 {
  font-size: 18px;
  font-weight: 600;
  color: rgba(1, 154, 83, 0.9);
  padding: 11px 0 0;
}
.g-sing-result h3 {
  margin: 0;
  text-align: center;
  font-size: 18px;
  color: rgba(1, 154, 83, 0.9);
  padding: 12px 0 0;
}
.g-sing-result ul li {
  display: inline-block;
  padding: 0 17px;
}
.g-sing-result ul {
  text-align: center;
  margin: 11px 0;
}
.g-sing-result ul li span {
  font-size: 27px;
}
.g-rtime p {
  padding: 0 15px 11px;
  color: #000;
}
.g-rtime p span {
  float: right;
}
.row.gold-cont {
  background: rgba(1, 154, 83, 0.9);
}
.gcon-area h2 {
  text-align: center;
  font-size: 22px;
  color: #fff;
  margin: 16px 0 6px;
}
.gcon-area p {
  text-align: center;
  font-size: 15px;
  color: #fff;
}
.gcon-area h3 {
  text-align: center;
  font-size: 24px;
  margin: 0 0 16px;
  color: #ffff;
}
.gold-jchat {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 16px 0 11px;
}
.g-jchat h2 {
  text-align: center;
  font-size: 20px;
  background: rgba(1, 154, 83, 0.9);
  color: #fff;
  padding: 12px 0;
  border-radius: 4px;
  margin: 0 0 11px;
}
.g-jchat a {
  width: 100%;
  border-top: 2px solid rgba(1, 154, 83, 0.9);
  font-size: 13px;
  display: block;
  background: #fff;
  color: #000;
  padding: 10px 18px;
  margin: 0 0 5px;
  border-radius: 4px;
  border-bottom: 2px solid rgba(1, 154, 83, 0.9);
  border-left: 0;
  border-right: 0;
  box-shadow: 0 4px 7px -4px;
  text-align: left;
}
.g-jchat a span {
  color: #9b0000;
}
.gold-payment-type {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 16px 0 11px;
}
.dt-g p span {
  float: right;
  background: rgba(1, 154, 83, 0.9);
  color: #fff;
  padding: 3px 12px;
  font-size: 12px;
  border-radius: 2px;
}
.gold-time-table {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 16px 0 13px;
}
.gcon-table h2 {
  text-align: center;
  background: rgba(1, 154, 83, 0.9);
  font-size: 21px;
  padding: 11px 0;
  border-radius: 4px;
  color: #fff;
  margin: 0 0 11px;
}
.gcon-table table {
  width: 100%;
}
.gcon-table table tr th {
  background: #9b0000;
  color: #fff;
  padding: 8px 0;
  border: 1px solid #920202;
  text-align: center;
}
.gcon-table table tr td {
  border: 1px solid #9b0000;
  text-align: center;
  padding: 7px 0;
  font-size: 12px;
  background: #fff;
}
.gold-top-palayer {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 16px 0 13px;
}
.row.gold-top-palayer h2 {
  background: rgba(1, 154, 83, 0.9);
  color: #fff;
  font-size: 21px;
  border-radius: 4px;
}
.sing-player ul {
  padding: 10px 0 15px;
  margin: 0 0 6px;
}
.gold-footer {
  background: rgba(1, 154, 83, 0.9);
  text-align: center;
  text-transform: capitalize;
  color: #fff;
}
.g-foot h2 {
  margin: 12px 0 2px;
}
.g-jchat a span {
  color: rgba(1, 154, 83, 0.9);
  float: right;
}
.gcon-table table {
  width: 100%;
}
.dwapp img {
  width: 62%;
  text-align: center;
  margin: 0 auto 13px;
  display: block;
}
.gold-banner img {
  width: 100%;
  overflow: hidden;
}
.gold-banner {
  overflow: hidden;
  width: 100%;
  margin: 0;
}
@media screen and (max-width: 480px) {
  .logo-area h1 {
    margin: 0 0 3px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    font-size: 24px;
  }
  .logo-area h2 {
    text-align: center;
    margin: 4px 0 6px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
  }
  .single-result ul li p {
    padding: 6px 31px;
  }
  .win-right h2 {
    font-size: 28px;
  }
  .win-right ul li p {
    font-size: 19px;
  }
  .offer-area {
    text-align: center;
    padding: 0;
  }
  .offer-area h2 {
    color: #fff;
    font-size: 21px;
    text-align: center;
    line-height: 43px;
    margin: 0;
  }
  .social-ara ul li {
    display: inline-block;
    width: 13%;
    padding: 0 1px 0 0;
    margin: 0 5px;
  }
  .client-deta {
    margin: 6px auto 4px;
    display: flex;
  }
  .login-area {
    text-align: center;
    padding: 6px 0 8px;
    position: absolute;
    top: 87px;
    right: 20px;
  }
  .head-banner img {
    height: auto;
    object-fit: cover;
    height: 225px;
  }
  #demo {
    color: #fff;
    font-size: 21px;
    color: #fb0;
    text-align: center;
    padding: 5px 0 0;
  }
  .client-deta a {
    color: #000;
    padding: 0 1px;
  }
  .login-area a {
    background: #fb0;
    color: #000;
  }
  .time-table table {
    margin: 12px 11px;
    /* width: 93%; */
    table-layout: fixed;
    width: 100%;  
  }
  .time-table table tr th {
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    color: #01439a;
    border: 1px solid #bfbfbf;
    width: 59px;
    padding: 10px 0;
  }
  .time-table table tr td {
    text-align: center;
    color: #000;
    font-size: 14px;
    border: 1px solid #bfbfbf;
    padding: 9px 3px;

    /* @media (max-width: 600px) {
      .time-table table tr th{ 
          width: 100% !important;
          overflow-x: scroll; 
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
      }
    
      th, td {min-width: 40px; }
    } */
  }
  .time-table table th,td {
    border-style: solid;
    border-width: 5px;
    border-color: #BCBCBC;
    word-wrap: break-word;
}

  .win-left {
    height: 100%;
    padding: 0 0 16px;
    width: 100%;
  }
  .how-right ul li p i {
    color: #01439a;
    padding: 0 6px 0 0;
  }
  .g-vider iframe {
    height: auto;
    margin: 0 0 20px;
  }
  .how-to-p {
    margin: 30px 0 29px;
  }
  .main-forgot-pop {
    width: 82%;
    padding: 36px 15px;
  }
  .main-forgot-pop h2 {
    font-size: 20px;
  }
  .main-forgot-pop form input[type="text"],
  .main-forgot-pop form input[type="password"] {
    padding: 13px 27px;
    border-radius: 4px;
  }
  .main-forgot-pop form input[type="submit"] {
    width: 100%;
    padding: 14px 20px;
    border-radius: 4px;
  }
  .vid {
    padding: 0 !important;
  }
  .logout1 {
    background: #f7d800;
    text-align: center;
    padding: 9px 0;
    border-radius: 50px;
    width: 54%;
    margin: 13px auto 11px;
    position: inherit;
  }
  .logout1 a i {
    padding: 0 4px;
  }
  .logout1 a {
    color: #000;
  }
}
@media screen and (max-width: 414px) {
  banner-text h2 {
    font-size: 17px;
  }
  .gold-banner img {
    height: 277px;
    object-fit: cover;
  }
  .main-sing-play {
    margin: 11px 0 0 13px;
  }
  .client-deta ul {
    top: 18px;
    right: 0;
  }
  .client-deta ul li {
    padding: 14px 2px 0 0;
  }
  .win-right ul li p {
    font-size: 20px;
  }
  .main-register-pop {
    width: 85%;
    padding: 15px 12px;
    border: 1px solid #fff;
    border-radius: 30px;
    margin: 6px auto;
    padding: 25px 12px;
  }
  .main-register-pop h2 {
    margin: 0 0 30px;
    font-size: 19px;
    color: rgba(1, 154, 83, 0.9);
    text-align: center;
  }
  .pop-back {
    right: 7px !important;
  }
  .main-register-pop form input[type="text"],
  .main-register-pop form input[type="password"] {
    padding: 12px 3px;
    margin: 0 0 10px;
    font-size: 15px;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
  }
  .main-register-pop p {
    margin: 9px 0;
    color: #000 !important;
  }
  .main-register-pop p a {
    color: rgba(1, 154, 83, 0.9);
  }
  .main-register-pop form input[type="submit"] {
    width: 100%;
    padding: 15px 20px;
    margin: 15px 0 !important;
    background: rgba(1, 154, 83, 0.9) !important;
    color: #fff;
  }
  .sing1 span {
    padding: 0;
    width: 35px;
    height: 35px;
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    line-height: 35px;
    font-size: 14px;
  }
  .nc-ch.single_box,
  .nc-ch.single_patti_box,
  .nc-ch.double_patti_box {
    width: 90px;
    border-radius: 4px;
    margin: 0 0 8px 5px;
    padding: 8px 7px 8px 9px;
  }
  .sing1 input {
    padding: 13px 8px 9px 9px;
  }
  .play-butt input[type="submit"] {
    width: 100%;
  }
  .sele-area form select {
    width: 100%;
  }
  .all-option h2 {
    font-size: 24px;
  }
  .all-option h3 {
    text-align: center;
    font-size: 20px;
  }
  .sing1 input {
    width: 24%;
  }
}
@media screen and (max-width: 414px) {
  .gold-login-area ul li {
    color: #fff;
    padding: 0 1px !important;
  }
  .show-input form input[type="number"] {
    width: 21%;
    padding: 10px 2px 8px 7px;
  }
  .main-login-pop {
    padding: 60px 15px;
  }
  .main-register-pop {
    padding: 81px 12px;
  }
  .show-input p:nth-child(2) {
    display: none;
  }
  .show-input input[type="number"] {
    background: #fff;
    border: 1px solid #9b0000;
    padding: 9px 2px 7px 7px;
    margin: 0 0 9px;
    width: 24%;
  }
  .forum-area {
    margin-top: 0;
  }
  .row.all-rec-area {
    margin-top: 0;
    margin-top: 0;
  }
  .row.star-play-rate {
    margin-top: 0;
  }
  .gold-banner img {
    height: 245px;
    object-fit: cover;
  }
  .banner-text h2 {
    color: #fff;
    font-size: 17px;
  }
  .banner-text p {
    font-size: 14px;
    width: 90%;
  }
  .banner-text {
    top: 28px;
  }
  .bann-butt a:nth-child(1) {
    font-size: 15px;
  }
  .bann-butt a:nth-child(2) {
    font-size: 15px;
    margin: 8px 0 0;
  }
  .bann-butt {
    margin: 19px 0 0;
  }
}
@media screen and (max-width: 384px) {
  .sing1 span {
    padding: 10px 13px;
  }
  .sele-area form select {
    width: 100%;
  }
  .show-input form input[type="number"] {
    width: 18%;
  }
}
@media screen and (max-width: 375px) {
  .main-register-pop {
    padding: 47px 12px;
  }
  .main-login-pop {
    padding: 28px 15px;
  }
  .win-right ul li p {
    font-size: 16px;
    font-size: 20px;
    color: #000;
  }
  .sing-gam {
    height: 149px;
  }
  .sing-gam p {
    font-size: 12px;
  }
  .game-time {
    font-size: 10px;
  }
  .sing-gam {
    height: 141px;
  }
  .all-option {
    padding: 15px;
    margin: 16px 0;
  }
  .all-option h2 {
    font-size: 21px;
    padding: 12px 0 0;
    color: rgba(1, 154, 83, 0.9);
  }
  .all-option h3 {
    color: #000;
    font-size: 20px;
    text-align: center;
    padding: 0 0 13px;
  }
  .play-butt input[type="submit"] {
    width: 97%;
    padding: 13px 0;
    font-size: 17px;
    background: rgba(1, 154, 83, 0.9);
    color: #fff;
    border-radius: 50px;
    border: 0;
    margin: 20px 0 15px;
  }
  .play {
    padding: 0 !important;
  }
  .sele-area form select {
    width: 98%;
  }
  .sing1 span {
    padding: 0;
    width: 35px;
    height: 35px;
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    line-height: 35px;
    font-size: 14px;
  }
  .nc-ch.single_box,
  .nc-ch.single_patti_box,
  .nc-ch.double_patti_box {
    width: 90px;
    border-radius: 4px;
    margin: 0 0 8px 5px;
    padding: 8px 7px 8px 9px;
  }
  .sing1 input {
    width: 24%;
  }
  .show-input form .b-num {
    padding: 11px 9px;
  }
  .logout {
    right: 19px !important;
    z-index: 99;
  }
  .show-input input[type="number"] {
    background: #fff;
    border: 1px solid #9b0000;
    padding: 11px 2px 7px 7px;
    margin: 0 0 9px;
    width: 26%;
  }
}
@media screen and (max-width: 360px) {
  .main-sing-play {
    margin: 14px 0 0 13px;
  }
  .show-input input[type="number"] {
    width: 23%;
    padding: 10px 2px 8px 7px;
  }
  .single-result ul li p {
    padding: 6px 31px;
  }
  .win-right ul li p {
    font-size: 16px;
  }
  .sing1 input {
    width: 34%;
  }
  .sing4 input {
    width: 35% !important;
  }
  .sing1 span {
    padding: 0;
    width: 35px;
    height: 35px;
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    line-height: 35px;
    font-size: 14px;
  }
  .popup {
    width: 90%;
    padding: 17px 15px;
    z-index: 999;
  }
  .popup h2 {
    font-size: 23px;
  }
  .popup p {
    font-size: 15px;
  }
  .show-input form input[type="number"] {
    padding: 10px 2px 8px 7px;
  }
  .show-input form .b-num {
    padding: 10px 9px;
  }
  .s-p-tit {
    border: 1px solid #ccc;
    padding: 10px 14px;
    border-radius: 4px;
    margin: 0 0 8px;
  }
  .nc-ch.single_box,
  .nc-ch.single_patti_box,
  .nc-ch.double_patti_box {
    width: 83px;
    border-radius: 4px;
    margin: 0 0 8px 5px;
    padding: 8px 7px 8px 9px;
  }
  .single6 input {
    width: 30% !important;
    border-radius: 50px;
    border: 1px solid rgba(1, 154, 83, 0.9);
    margin: 0 0 12px 4px;
    padding: 8px 10px;
  }
  .sing4 input {
    width: 32% !important;
  }
}
@media screen and (max-width: 320px) {
  .row.gold-menu-area {
    margin-top: 69px;
  }
  .single-result ul li p {
    padding: 6px 25px;
  }
  .win-right ul li p {
    font-size: 13px;
  }
  .sing1 span {
    padding: 10px 16px;
  }
  .sing1 input {
    width: 28%;
  }
  .show-input form .b-num {
    padding: 10px 9px;
  }
  .show-input input[type="number"] {
    width: 20%;
  }
  .star-tab button {
    padding: 11px 17px;
    font-size: 16px;
  }
  .main-sing-play p {
    font-size: 17px;
  }
  .star-rate ul li p {
    font-size: 15px;
  }
  .star-rate h1 {
    margin: 0;
  }
  .all-option2 h2 {
    font-size: 18px;
    color: rgba(1, 154, 83, 0.9);
  }
  .nc-ch.single_box,
  .nc-ch.single_patti_box,
  .nc-ch.double_patti_box {
    width: 69px;
  }
  .sing1 span {
    padding: 0;
  }
  .single6 input {
    width: 27% !important;
  }
}
.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}
.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
}
.owl-carousel .owl-stage:after {
  /* content: "."; */
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}
.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  transition: height 0.5s ease-in-out;
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url() no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}
@font-face {
  font-family: fontawesome;
  src: url();
  src: url() format("embedded-opentype"), url() format("woff2"),
    url() format("woff"), url() format("truetype"), url() format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-fw {
  width: 1.28571429em;
  text-align: center;
}
.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}
.fa-li.fa-lg {
  left: -1.85714286em;
}
.fa-border {
  padding: 0.2em 0.25em 0.15em;
  border: solid 0.08em #eee;
  border-radius: 0.1em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right {
  margin-left: 0.3em;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.fa.pull-left {
  margin-right: 0.3em;
}
.fa.pull-right {
  margin-left: 0.3em;
}
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fa-rotate-90 {
  -ms-filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}
.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
}
.fa-glass:before {
  content: "\f000";
}
.fa-music:before {
  content: "\f001";
}
.fa-search:before {
  content: "\f002";
}
.fa-envelope-o:before {
  content: "\f003";
}
.fa-heart:before {
  content: "\f004";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-o:before {
  content: "\f006";
}
.fa-user:before {
  content: "\f007";
}
.fa-film:before {
  content: "\f008";
}
.fa-th-large:before {
  content: "\f009";
}
.fa-th:before {
  content: "\f00a";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-check:before {
  content: "\f00c";
}
.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: "\f00d";
}
.fa-search-plus:before {
  content: "\f00e";
}
.fa-search-minus:before {
  content: "\f010";
}
.fa-power-off:before {
  content: "\f011";
}
.fa-signal:before {
  content: "\f012";
}
.fa-gear:before,
.fa-cog:before {
  content: "\f013";
}
.fa-trash-o:before {
  content: "\f014";
}
.fa-home:before {
  content: "\f015";
}
.fa-file-o:before {
  content: "\f016";
}
.fa-clock-o:before {
  content: "\f017";
}
.fa-road:before {
  content: "\f018";
}
.fa-download:before {
  content: "\f019";
}
.fa-arrow-circle-o-down:before {
  content: "\f01a";
}
.fa-arrow-circle-o-up:before {
  content: "\f01b";
}
.fa-inbox:before {
  content: "\f01c";
}
.fa-play-circle-o:before {
  content: "\f01d";
}
.fa-rotate-right:before,
.fa-repeat:before {
  content: "\f01e";
}
.fa-refresh:before {
  content: "\f021";
}
.fa-list-alt:before {
  content: "\f022";
}
.fa-lock:before {
  content: "\f023";
}
.fa-flag:before {
  content: "\f024";
}
.fa-headphones:before {
  content: "\f025";
}
.fa-volume-off:before {
  content: "\f026";
}
.fa-volume-down:before {
  content: "\f027";
}
.fa-volume-up:before {
  content: "\f028";
}
.fa-qrcode:before {
  content: "\f029";
}
.fa-barcode:before {
  content: "\f02a";
}
.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}
.fa-book:before {
  content: "\f02d";
}
.fa-bookmark:before {
  content: "\f02e";
}
.fa-print:before {
  content: "\f02f";
}
.fa-camera:before {
  content: "\f030";
}
.fa-font:before {
  content: "\f031";
}
.fa-bold:before {
  content: "\f032";
}
.fa-italic:before {
  content: "\f033";
}
.fa-text-height:before {
  content: "\f034";
}
.fa-text-width:before {
  content: "\f035";
}
.fa-align-left:before {
  content: "\f036";
}
.fa-align-center:before {
  content: "\f037";
}
.fa-align-right:before {
  content: "\f038";
}
.fa-align-justify:before {
  content: "\f039";
}
.fa-list:before {
  content: "\f03a";
}
.fa-dedent:before,
.fa-outdent:before {
  content: "\f03b";
}
.fa-indent:before {
  content: "\f03c";
}
.fa-video-camera:before {
  content: "\f03d";
}
.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: "\f03e";
}
.fa-pencil:before {
  content: "\f040";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-adjust:before {
  content: "\f042";
}
.fa-tint:before {
  content: "\f043";
}
.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\f044";
}
.fa-share-square-o:before {
  content: "\f045";
}
.fa-check-square-o:before {
  content: "\f046";
}
.fa-arrows:before {
  content: "\f047";
}
.fa-step-backward:before {
  content: "\f048";
}
.fa-fast-backward:before {
  content: "\f049";
}
.fa-backward:before {
  content: "\f04a";
}
.fa-play:before {
  content: "\f04b";
}
.fa-pause:before {
  content: "\f04c";
}
.fa-stop:before {
  content: "\f04d";
}
.fa-forward:before {
  content: "\f04e";
}
.fa-fast-forward:before {
  content: "\f050";
}
.fa-step-forward:before {
  content: "\f051";
}
.fa-eject:before {
  content: "\f052";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-minus-circle:before {
  content: "\f056";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-crosshairs:before {
  content: "\f05b";
}
.fa-times-circle-o:before {
  content: "\f05c";
}
.fa-check-circle-o:before {
  content: "\f05d";
}
.fa-ban:before {
  content: "\f05e";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-mail-forward:before,
.fa-share:before {
  content: "\f064";
}
.fa-expand:before {
  content: "\f065";
}
.fa-compress:before {
  content: "\f066";
}
.fa-plus:before {
  content: "\f067";
}
.fa-minus:before {
  content: "\f068";
}
.fa-asterisk:before {
  content: "\f069";
}
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-gift:before {
  content: "\f06b";
}
.fa-leaf:before {
  content: "\f06c";
}
.fa-fire:before {
  content: "\f06d";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-warning:before,
.fa-exclamation-triangle:before {
  content: "\f071";
}
.fa-plane:before {
  content: "\f072";
}
.fa-calendar:before {
  content: "\f073";
}
.fa-random:before {
  content: "\f074";
}
.fa-comment:before {
  content: "\f075";
}
.fa-magnet:before {
  content: "\f076";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-retweet:before {
  content: "\f079";
}
.fa-shopping-cart:before {
  content: "\f07a";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-arrows-v:before {
  content: "\f07d";
}
.fa-arrows-h:before {
  content: "\f07e";
}
.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: "\f080";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-facebook-square:before {
  content: "\f082";
}
.fa-camera-retro:before {
  content: "\f083";
}
.fa-key:before {
  content: "\f084";
}
.fa-gears:before,
.fa-cogs:before {
  content: "\f085";
}
.fa-comments:before {
  content: "\f086";
}
.fa-thumbs-o-up:before {
  content: "\f087";
}
.fa-thumbs-o-down:before {
  content: "\f088";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-heart-o:before {
  content: "\f08a";
}
.fa-sign-out:before {
  content: "\f08b";
}
.fa-linkedin-square:before {
  content: "\f08c";
}
.fa-thumb-tack:before {
  content: "\f08d";
}
.fa-external-link:before {
  content: "\f08e";
}
.fa-sign-in:before {
  content: "\f090";
}
.fa-trophy:before {
  content: "\f091";
}
.fa-github-square:before {
  content: "\f092";
}
.fa-upload:before {
  content: "\f093";
}
.fa-lemon-o:before {
  content: "\f094";
}
.fa-phone:before {
  content: "\f095";
}
.fa-square-o:before {
  content: "\f096";
}
.fa-bookmark-o:before {
  content: "\f097";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f09a";
}
.fa-github:before {
  content: "\f09b";
}
.fa-unlock:before {
  content: "\f09c";
}
.fa-credit-card:before {
  content: "\f09d";
}
.fa-feed:before,
.fa-rss:before {
  content: "\f09e";
}
.fa-hdd-o:before {
  content: "\f0a0";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-bell:before {
  content: "\f0f3";
}
.fa-certificate:before {
  content: "\f0a3";
}
.fa-hand-o-right:before {
  content: "\f0a4";
}
.fa-hand-o-left:before {
  content: "\f0a5";
}
.fa-hand-o-up:before {
  content: "\f0a6";
}
.fa-hand-o-down:before {
  content: "\f0a7";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-wrench:before {
  content: "\f0ad";
}
.fa-tasks:before {
  content: "\f0ae";
}
.fa-filter:before {
  content: "\f0b0";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-group:before,
.fa-users:before {
  content: "\f0c0";
}
.fa-chain:before,
.fa-link:before {
  content: "\f0c1";
}
.fa-cloud:before {
  content: "\f0c2";
}
.fa-flask:before {
  content: "\f0c3";
}
.fa-cut:before,
.fa-scissors:before {
  content: "\f0c4";
}
.fa-copy:before,
.fa-files-o:before {
  content: "\f0c5";
}
.fa-paperclip:before {
  content: "\f0c6";
}
.fa-save:before,
.fa-floppy-o:before {
  content: "\f0c7";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: "\f0c9";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-list-ol:before {
  content: "\f0cb";
}
.fa-strikethrough:before {
  content: "\f0cc";
}
.fa-underline:before {
  content: "\f0cd";
}
.fa-table:before {
  content: "\f0ce";
}
.fa-magic:before {
  content: "\f0d0";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-pinterest-square:before {
  content: "\f0d3";
}
.fa-google-plus-square:before {
  content: "\f0d4";
}
.fa-google-plus:before {
  content: "\f0d5";
}
.fa-money:before {
  content: "\f0d6";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-columns:before {
  content: "\f0db";
}
.fa-unsorted:before,
.fa-sort:before {
  content: "\f0dc";
}
.fa-sort-down:before,
.fa-sort-desc:before {
  content: "\f0dd";
}
.fa-sort-up:before,
.fa-sort-asc:before {
  content: "\f0de";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-linkedin:before {
  content: "\f0e1";
}
.fa-rotate-left:before,
.fa-undo:before {
  content: "\f0e2";
}
.fa-legal:before,
.fa-gavel:before {
  content: "\f0e3";
}
.fa-dashboard:before,
.fa-tachometer:before {
  content: "\f0e4";
}
.fa-comment-o:before {
  content: "\f0e5";
}
.fa-comments-o:before {
  content: "\f0e6";
}
.fa-flash:before,
.fa-bolt:before {
  content: "\f0e7";
}
.fa-sitemap:before {
  content: "\f0e8";
}
.fa-umbrella:before {
  content: "\f0e9";
}
.fa-paste:before,
.fa-clipboard:before {
  content: "\f0ea";
}
.fa-lightbulb-o:before {
  content: "\f0eb";
}
.fa-exchange:before {
  content: "\f0ec";
}
.fa-cloud-download:before {
  content: "\f0ed";
}
.fa-cloud-upload:before {
  content: "\f0ee";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-stethoscope:before {
  content: "\f0f1";
}
.fa-suitcase:before {
  content: "\f0f2";
}
.fa-bell-o:before {
  content: "\f0a2";
}
.fa-coffee:before {
  content: "\f0f4";
}
.fa-cutlery:before {
  content: "\f0f5";
}
.fa-file-text-o:before {
  content: "\f0f6";
}
.fa-building-o:before {
  content: "\f0f7";
}
.fa-hospital-o:before {
  content: "\f0f8";
}
.fa-ambulance:before {
  content: "\f0f9";
}
.fa-medkit:before {
  content: "\f0fa";
}
.fa-fighter-jet:before {
  content: "\f0fb";
}
.fa-beer:before {
  content: "\f0fc";
}
.fa-h-square:before {
  content: "\f0fd";
}
.fa-plus-square:before {
  content: "\f0fe";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-angle-double-down:before {
  content: "\f103";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-desktop:before {
  content: "\f108";
}
.fa-laptop:before {
  content: "\f109";
}
.fa-tablet:before {
  content: "\f10a";
}
.fa-mobile-phone:before,
.fa-mobile:before {
  content: "\f10b";
}
.fa-circle-o:before {
  content: "\f10c";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-spinner:before {
  content: "\f110";
}
.fa-circle:before {
  content: "\f111";
}
.fa-mail-reply:before,
.fa-reply:before {
  content: "\f112";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-folder-o:before {
  content: "\f114";
}
.fa-folder-open-o:before {
  content: "\f115";
}
.fa-smile-o:before {
  content: "\f118";
}
.fa-frown-o:before {
  content: "\f119";
}
.fa-meh-o:before {
  content: "\f11a";
}
.fa-gamepad:before {
  content: "\f11b";
}
.fa-keyboard-o:before {
  content: "\f11c";
}
.fa-flag-o:before {
  content: "\f11d";
}
.fa-flag-checkered:before {
  content: "\f11e";
}
.fa-terminal:before {
  content: "\f120";
}
.fa-code:before {
  content: "\f121";
}
.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: "\f122";
}
.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "\f123";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-crop:before {
  content: "\f125";
}
.fa-code-fork:before {
  content: "\f126";
}
.fa-unlink:before,
.fa-chain-broken:before {
  content: "\f127";
}
.fa-question:before {
  content: "\f128";
}
.fa-info:before {
  content: "\f129";
}
.fa-exclamation:before {
  content: "\f12a";
}
.fa-superscript:before {
  content: "\f12b";
}
.fa-subscript:before {
  content: "\f12c";
}
.fa-eraser:before {
  content: "\f12d";
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.fa-microphone:before {
  content: "\f130";
}
.fa-microphone-slash:before {
  content: "\f131";
}
.fa-shield:before {
  content: "\f132";
}
.fa-calendar-o:before {
  content: "\f133";
}
.fa-fire-extinguisher:before {
  content: "\f134";
}
.fa-rocket:before {
  content: "\f135";
}
.fa-maxcdn:before {
  content: "\f136";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-html5:before {
  content: "\f13b";
}
.fa-css3:before {
  content: "\f13c";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-unlock-alt:before {
  content: "\f13e";
}
.fa-bullseye:before {
  content: "\f140";
}
.fa-ellipsis-h:before {
  content: "\f141";
}
.fa-ellipsis-v:before {
  content: "\f142";
}
.fa-rss-square:before {
  content: "\f143";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-ticket:before {
  content: "\f145";
}
.fa-minus-square:before {
  content: "\f146";
}
.fa-minus-square-o:before {
  content: "\f147";
}
.fa-level-up:before {
  content: "\f148";
}
.fa-level-down:before {
  content: "\f149";
}
.fa-check-square:before {
  content: "\f14a";
}
.fa-pencil-square:before {
  content: "\f14b";
}
.fa-external-link-square:before {
  content: "\f14c";
}
.fa-share-square:before {
  content: "\f14d";
}
.fa-compass:before {
  content: "\f14e";
}
.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: "\f150";
}
.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: "\f151";
}
.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: "\f152";
}
.fa-euro:before,
.fa-eur:before {
  content: "\f153";
}
.fa-gbp:before {
  content: "\f154";
}
.fa-dollar:before,
.fa-usd:before {
  content: "\f155";
}
.fa-rupee:before,
.fa-inr:before {
  content: "\f156";
}
.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: "\f157";
}
.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: "\f158";
}
.fa-won:before,
.fa-krw:before {
  content: "\f159";
}
.fa-bitcoin:before,
.fa-btc:before {
  content: "\f15a";
}
.fa-file:before {
  content: "\f15b";
}
.fa-file-text:before {
  content: "\f15c";
}
.fa-sort-alpha-asc:before {
  content: "\f15d";
}
.fa-sort-alpha-desc:before {
  content: "\f15e";
}
.fa-sort-amount-asc:before {
  content: "\f160";
}
.fa-sort-amount-desc:before {
  content: "\f161";
}
.fa-sort-numeric-asc:before {
  content: "\f162";
}
.fa-sort-numeric-desc:before {
  content: "\f163";
}
.fa-thumbs-up:before {
  content: "\f164";
}
.fa-thumbs-down:before {
  content: "\f165";
}
.fa-youtube-square:before {
  content: "\f166";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-xing:before {
  content: "\f168";
}
.fa-xing-square:before {
  content: "\f169";
}
.fa-youtube-play:before {
  content: "\f16a";
}
.fa-dropbox:before {
  content: "\f16b";
}
.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-adn:before {
  content: "\f170";
}
.fa-bitbucket:before {
  content: "\f171";
}
.fa-bitbucket-square:before {
  content: "\f172";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-tumblr-square:before {
  content: "\f174";
}
.fa-long-arrow-down:before {
  content: "\f175";
}
.fa-long-arrow-up:before {
  content: "\f176";
}
.fa-long-arrow-left:before {
  content: "\f177";
}
.fa-long-arrow-right:before {
  content: "\f178";
}
.fa-apple:before {
  content: "\f179";
}
.fa-windows:before {
  content: "\f17a";
}
.fa-android:before {
  content: "\f17b";
}
.fa-linux:before {
  content: "\f17c";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-skype:before {
  content: "\f17e";
}
.fa-foursquare:before {
  content: "\f180";
}
.fa-trello:before {
  content: "\f181";
}
.fa-female:before {
  content: "\f182";
}
.fa-male:before {
  content: "\f183";
}
.fa-gittip:before,
.fa-gratipay:before {
  content: "\f184";
}
.fa-sun-o:before {
  content: "\f185";
}
.fa-moon-o:before {
  content: "\f186";
}
.fa-archive:before {
  content: "\f187";
}
.fa-bug:before {
  content: "\f188";
}
.fa-vk:before {
  content: "\f189";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-renren:before {
  content: "\f18b";
}
.fa-pagelines:before {
  content: "\f18c";
}
.fa-stack-exchange:before {
  content: "\f18d";
}
.fa-arrow-circle-o-right:before {
  content: "\f18e";
}
.fa-arrow-circle-o-left:before {
  content: "\f190";
}
.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: "\f191";
}
.fa-dot-circle-o:before {
  content: "\f192";
}
.fa-wheelchair:before {
  content: "\f193";
}
.fa-vimeo-square:before {
  content: "\f194";
}
.fa-turkish-lira:before,
.fa-try:before {
  content: "\f195";
}
.fa-plus-square-o:before {
  content: "\f196";
}
.fa-space-shuttle:before {
  content: "\f197";
}
.fa-slack:before {
  content: "\f198";
}
.fa-envelope-square:before {
  content: "\f199";
}
.fa-wordpress:before {
  content: "\f19a";
}
.fa-openid:before {
  content: "\f19b";
}
.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: "\f19c";
}
.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: "\f19d";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-reddit:before {
  content: "\f1a1";
}
.fa-reddit-square:before {
  content: "\f1a2";
}
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.fa-stumbleupon:before {
  content: "\f1a4";
}
.fa-delicious:before {
  content: "\f1a5";
}
.fa-digg:before {
  content: "\f1a6";
}
.fa-pied-piper-pp:before {
  content: "\f1a7";
}
.fa-pied-piper-alt:before {
  content: "\f1a8";
}
.fa-drupal:before {
  content: "\f1a9";
}
.fa-joomla:before {
  content: "\f1aa";
}
.fa-language:before {
  content: "\f1ab";
}
.fa-fax:before {
  content: "\f1ac";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-child:before {
  content: "\f1ae";
}
.fa-paw:before {
  content: "\f1b0";
}
.fa-spoon:before {
  content: "\f1b1";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-cubes:before {
  content: "\f1b3";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-behance-square:before {
  content: "\f1b5";
}
.fa-steam:before {
  content: "\f1b6";
}
.fa-steam-square:before {
  content: "\f1b7";
}
.fa-recycle:before {
  content: "\f1b8";
}
.fa-automobile:before,
.fa-car:before {
  content: "\f1b9";
}
.fa-cab:before,
.fa-taxi:before {
  content: "\f1ba";
}
.fa-tree:before {
  content: "\f1bb";
}
.fa-spotify:before {
  content: "\f1bc";
}
.fa-deviantart:before {
  content: "\f1bd";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-file-pdf-o:before {
  content: "\f1c1";
}
.fa-file-word-o:before {
  content: "\f1c2";
}
.fa-file-excel-o:before {
  content: "\f1c3";
}
.fa-file-powerpoint-o:before {
  content: "\f1c4";
}
.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: "\f1c5";
}
.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: "\f1c6";
}
.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: "\f1c7";
}
.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: "\f1c8";
}
.fa-file-code-o:before {
  content: "\f1c9";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-jsfiddle:before {
  content: "\f1cc";
}
.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: "\f1cd";
}
.fa-circle-o-notch:before {
  content: "\f1ce";
}
.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
  content: "\f1d0";
}
.fa-ge:before,
.fa-empire:before {
  content: "\f1d1";
}
.fa-git-square:before {
  content: "\f1d2";
}
.fa-git:before {
  content: "\f1d3";
}
.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: "\f1d4";
}
.fa-tencent-weibo:before {
  content: "\f1d5";
}
.fa-qq:before {
  content: "\f1d6";
}
.fa-wechat:before,
.fa-weixin:before {
  content: "\f1d7";
}
.fa-send:before,
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-send-o:before,
.fa-paper-plane-o:before {
  content: "\f1d9";
}
.fa-history:before {
  content: "\f1da";
}
.fa-circle-thin:before {
  content: "\f1db";
}
.fa-header:before {
  content: "\f1dc";
}
.fa-paragraph:before {
  content: "\f1dd";
}
.fa-sliders:before {
  content: "\f1de";
}
.fa-share-alt:before {
  content: "\f1e0";
}
.fa-share-alt-square:before {
  content: "\f1e1";
}
.fa-bomb:before {
  content: "\f1e2";
}
.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: "\f1e3";
}
.fa-tty:before {
  content: "\f1e4";
}
.fa-binoculars:before {
  content: "\f1e5";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-slideshare:before {
  content: "\f1e7";
}
.fa-twitch:before {
  content: "\f1e8";
}
.fa-yelp:before {
  content: "\f1e9";
}
.fa-newspaper-o:before {
  content: "\f1ea";
}
.fa-wifi:before {
  content: "\f1eb";
}
.fa-calculator:before {
  content: "\f1ec";
}
.fa-paypal:before {
  content: "\f1ed";
}
.fa-google-wallet:before {
  content: "\f1ee";
}
.fa-cc-visa:before {
  content: "\f1f0";
}
.fa-cc-mastercard:before {
  content: "\f1f1";
}
.fa-cc-discover:before {
  content: "\f1f2";
}
.fa-cc-amex:before {
  content: "\f1f3";
}
.fa-cc-paypal:before {
  content: "\f1f4";
}
.fa-cc-stripe:before {
  content: "\f1f5";
}
.fa-bell-slash:before {
  content: "\f1f6";
}
.fa-bell-slash-o:before {
  content: "\f1f7";
}
.fa-trash:before {
  content: "\f1f8";
}
.fa-copyright:before {
  content: "\f1f9";
}
.fa-at:before {
  content: "\f1fa";
}
.fa-eyedropper:before {
  content: "\f1fb";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-birthday-cake:before {
  content: "\f1fd";
}
.fa-area-chart:before {
  content: "\f1fe";
}
.fa-pie-chart:before {
  content: "\f200";
}
.fa-line-chart:before {
  content: "\f201";
}
.fa-lastfm:before {
  content: "\f202";
}
.fa-lastfm-square:before {
  content: "\f203";
}
.fa-toggle-off:before {
  content: "\f204";
}
.fa-toggle-on:before {
  content: "\f205";
}
.fa-bicycle:before {
  content: "\f206";
}
.fa-bus:before {
  content: "\f207";
}
.fa-ioxhost:before {
  content: "\f208";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-cc:before {
  content: "\f20a";
}
.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: "\f20b";
}
.fa-meanpath:before {
  content: "\f20c";
}
.fa-buysellads:before {
  content: "\f20d";
}
.fa-connectdevelop:before {
  content: "\f20e";
}
.fa-dashcube:before {
  content: "\f210";
}
.fa-forumbee:before {
  content: "\f211";
}
.fa-leanpub:before {
  content: "\f212";
}
.fa-sellsy:before {
  content: "\f213";
}
.fa-shirtsinbulk:before {
  content: "\f214";
}
.fa-simplybuilt:before {
  content: "\f215";
}
.fa-skyatlas:before {
  content: "\f216";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-diamond:before {
  content: "\f219";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-motorcycle:before {
  content: "\f21c";
}
.fa-street-view:before {
  content: "\f21d";
}
.fa-heartbeat:before {
  content: "\f21e";
}
.fa-venus:before {
  content: "\f221";
}
.fa-mars:before {
  content: "\f222";
}
.fa-mercury:before {
  content: "\f223";
}
.fa-intersex:before,
.fa-transgender:before {
  content: "\f224";
}
.fa-transgender-alt:before {
  content: "\f225";
}
.fa-venus-double:before {
  content: "\f226";
}
.fa-mars-double:before {
  content: "\f227";
}
.fa-venus-mars:before {
  content: "\f228";
}
.fa-mars-stroke:before {
  content: "\f229";
}
.fa-mars-stroke-v:before {
  content: "\f22a";
}
.fa-mars-stroke-h:before {
  content: "\f22b";
}
.fa-neuter:before {
  content: "\f22c";
}
.fa-genderless:before {
  content: "\f22d";
}
.fa-facebook-official:before {
  content: "\f230";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-server:before {
  content: "\f233";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-hotel:before,
.fa-bed:before {
  content: "\f236";
}
.fa-viacoin:before {
  content: "\f237";
}
.fa-train:before {
  content: "\f238";
}
.fa-subway:before {
  content: "\f239";
}
.fa-medium:before {
  content: "\f23a";
}
.fa-yc:before,
.fa-y-combinator:before {
  content: "\f23b";
}
.fa-optin-monster:before {
  content: "\f23c";
}
.fa-opencart:before {
  content: "\f23d";
}
.fa-expeditedssl:before {
  content: "\f23e";
}
.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
  content: "\f240";
}
.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: "\f241";
}
.fa-battery-2:before,
.fa-battery-half:before {
  content: "\f242";
}
.fa-battery-1:before,
.fa-battery-quarter:before {
  content: "\f243";
}
.fa-battery-0:before,
.fa-battery-empty:before {
  content: "\f244";
}
.fa-mouse-pointer:before {
  content: "\f245";
}
.fa-i-cursor:before {
  content: "\f246";
}
.fa-object-group:before {
  content: "\f247";
}
.fa-object-ungroup:before {
  content: "\f248";
}
.fa-sticky-note:before {
  content: "\f249";
}
.fa-sticky-note-o:before {
  content: "\f24a";
}
.fa-cc-jcb:before {
  content: "\f24b";
}
.fa-cc-diners-club:before {
  content: "\f24c";
}
.fa-clone:before {
  content: "\f24d";
}
.fa-balance-scale:before {
  content: "\f24e";
}
.fa-hourglass-o:before {
  content: "\f250";
}
.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: "\f251";
}
.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: "\f252";
}
.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: "\f253";
}
.fa-hourglass:before {
  content: "\f254";
}
.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: "\f255";
}
.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: "\f256";
}
.fa-hand-scissors-o:before {
  content: "\f257";
}
.fa-hand-lizard-o:before {
  content: "\f258";
}
.fa-hand-spock-o:before {
  content: "\f259";
}
.fa-hand-pointer-o:before {
  content: "\f25a";
}
.fa-hand-peace-o:before {
  content: "\f25b";
}
.fa-trademark:before {
  content: "\f25c";
}
.fa-registered:before {
  content: "\f25d";
}
.fa-creative-commons:before {
  content: "\f25e";
}
.fa-gg:before {
  content: "\f260";
}
.fa-gg-circle:before {
  content: "\f261";
}
.fa-tripadvisor:before {
  content: "\f262";
}
.fa-odnoklassniki:before {
  content: "\f263";
}
.fa-odnoklassniki-square:before {
  content: "\f264";
}
.fa-get-pocket:before {
  content: "\f265";
}
.fa-wikipedia-w:before {
  content: "\f266";
}
.fa-safari:before {
  content: "\f267";
}
.fa-chrome:before {
  content: "\f268";
}
.fa-firefox:before {
  content: "\f269";
}
.fa-opera:before {
  content: "\f26a";
}
.fa-internet-explorer:before {
  content: "\f26b";
}
.fa-tv:before,
.fa-television:before {
  content: "\f26c";
}
.fa-contao:before {
  content: "\f26d";
}
.fa-500px:before {
  content: "\f26e";
}
.fa-amazon:before {
  content: "\f270";
}
.fa-calendar-plus-o:before {
  content: "\f271";
}
.fa-calendar-minus-o:before {
  content: "\f272";
}
.fa-calendar-times-o:before {
  content: "\f273";
}
.fa-calendar-check-o:before {
  content: "\f274";
}
.fa-industry:before {
  content: "\f275";
}
.fa-map-pin:before {
  content: "\f276";
}
.fa-map-signs:before {
  content: "\f277";
}
.fa-map-o:before {
  content: "\f278";
}
.fa-map:before {
  content: "\f279";
}
.fa-commenting:before {
  content: "\f27a";
}
.fa-commenting-o:before {
  content: "\f27b";
}
.fa-houzz:before {
  content: "\f27c";
}
.fa-vimeo:before {
  content: "\f27d";
}
.fa-black-tie:before {
  content: "\f27e";
}
.fa-fonticons:before {
  content: "\f280";
}
.fa-reddit-alien:before {
  content: "\f281";
}
.fa-edge:before {
  content: "\f282";
}
.fa-credit-card-alt:before {
  content: "\f283";
}
.fa-codiepie:before {
  content: "\f284";
}
.fa-modx:before {
  content: "\f285";
}
.fa-fort-awesome:before {
  content: "\f286";
}
.fa-usb:before {
  content: "\f287";
}
.fa-product-hunt:before {
  content: "\f288";
}
.fa-mixcloud:before {
  content: "\f289";
}
.fa-scribd:before {
  content: "\f28a";
}
.fa-pause-circle:before {
  content: "\f28b";
}
.fa-pause-circle-o:before {
  content: "\f28c";
}
.fa-stop-circle:before {
  content: "\f28d";
}
.fa-stop-circle-o:before {
  content: "\f28e";
}
.fa-shopping-bag:before {
  content: "\f290";
}
.fa-shopping-basket:before {
  content: "\f291";
}
.fa-hashtag:before {
  content: "\f292";
}
.fa-bluetooth:before {
  content: "\f293";
}
.fa-bluetooth-b:before {
  content: "\f294";
}
.fa-percent:before {
  content: "\f295";
}
.fa-gitlab:before {
  content: "\f296";
}
.fa-wpbeginner:before {
  content: "\f297";
}
.fa-wpforms:before {
  content: "\f298";
}
.fa-envira:before {
  content: "\f299";
}
.fa-universal-access:before {
  content: "\f29a";
}
.fa-wheelchair-alt:before {
  content: "\f29b";
}
.fa-question-circle-o:before {
  content: "\f29c";
}
.fa-blind:before {
  content: "\f29d";
}
.fa-audio-description:before {
  content: "\f29e";
}
.fa-volume-control-phone:before {
  content: "\f2a0";
}
.fa-braille:before {
  content: "\f2a1";
}
.fa-assistive-listening-systems:before {
  content: "\f2a2";
}
.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
  content: "\f2a4";
}
.fa-glide:before {
  content: "\f2a5";
}
.fa-glide-g:before {
  content: "\f2a6";
}
.fa-signing:before,
.fa-sign-language:before {
  content: "\f2a7";
}
.fa-low-vision:before {
  content: "\f2a8";
}
.fa-viadeo:before {
  content: "\f2a9";
}
.fa-viadeo-square:before {
  content: "\f2aa";
}
.fa-snapchat:before {
  content: "\f2ab";
}
.fa-snapchat-ghost:before {
  content: "\f2ac";
}
.fa-snapchat-square:before {
  content: "\f2ad";
}
.fa-pied-piper:before {
  content: "\f2ae";
}
.fa-first-order:before {
  content: "\f2b0";
}
.fa-yoast:before {
  content: "\f2b1";
}
.fa-themeisle:before {
  content: "\f2b2";
}
.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: "\f2b3";
}
.fa-fa:before,
.fa-font-awesome:before {
  content: "\f2b4";
}
.fa-handshake-o:before {
  content: "\f2b5";
}
.fa-envelope-open:before {
  content: "\f2b6";
}
.fa-envelope-open-o:before {
  content: "\f2b7";
}
.fa-linode:before {
  content: "\f2b8";
}
.fa-address-book:before {
  content: "\f2b9";
}
.fa-address-book-o:before {
  content: "\f2ba";
}
.fa-vcard:before,
.fa-address-card:before {
  content: "\f2bb";
}
.fa-vcard-o:before,
.fa-address-card-o:before {
  content: "\f2bc";
}
.fa-user-circle:before {
  content: "\f2bd";
}
.fa-user-circle-o:before {
  content: "\f2be";
}
.fa-user-o:before {
  content: "\f2c0";
}
.fa-id-badge:before {
  content: "\f2c1";
}
.fa-drivers-license:before,
.fa-id-card:before {
  content: "\f2c2";
}
.fa-drivers-license-o:before,
.fa-id-card-o:before {
  content: "\f2c3";
}
.fa-quora:before {
  content: "\f2c4";
}
.fa-free-code-camp:before {
  content: "\f2c5";
}
.fa-telegram:before {
  content: "\f2c6";
}
.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
  content: "\f2c7";
}
.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}
.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: "\f2c9";
}
.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: "\f2ca";
}
.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: "\f2cb";
}
.fa-shower:before {
  content: "\f2cc";
}
.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
  content: "\f2cd";
}
.fa-podcast:before {
  content: "\f2ce";
}
.fa-window-maximize:before {
  content: "\f2d0";
}
.fa-window-minimize:before {
  content: "\f2d1";
}
.fa-window-restore:before {
  content: "\f2d2";
}
.fa-times-rectangle:before,
.fa-window-close:before {
  content: "\f2d3";
}
.fa-times-rectangle-o:before,
.fa-window-close-o:before {
  content: "\f2d4";
}
.fa-bandcamp:before {
  content: "\f2d5";
}
.fa-grav:before {
  content: "\f2d6";
}
.fa-etsy:before {
  content: "\f2d7";
}
.fa-imdb:before {
  content: "\f2d8";
}
.fa-ravelry:before {
  content: "\f2d9";
}
.fa-eercast:before {
  content: "\f2da";
}
.fa-microchip:before {
  content: "\f2db";
}
.fa-snowflake-o:before {
  content: "\f2dc";
}
.fa-superpowers:before {
  content: "\f2dd";
}
.fa-wpexplorer:before {
  content: "\f2de";
}
.fa-meetup:before {
  content: "\f2e0";
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
.gold-top-hed {
  padding: 12px 0;
  position: fixed;
  width: 100%;
  z-index: 999;
}
.foot-one-link ul li {
  display: inline-block;
}
.foot-one-link ul li a {
  color: #e6cd4d;
  padding: 0 7px;
}
.gold-login-area ul li {
  color: #fff;
  padding: 0 5px;
}
.gold-banner img {
  height: 245px;
}
.gold-banner-king img {
  height: 100px !important;
  height: 100px !important;
  object-fit: cover;
  width: 100% !important;
  overflow: hidden;
}
.banner-text {
  top: 24%;
}
.banner-text h2 {
  color: #fff;
  font-size: 36px;
}
@media screen and (max-width: 414px) {
  .banner-text h2 {
    color: #000;
    font-size: 17px;
  }
  .banner-text h3 {
    color: #000;
    font-size: 17px;
  }
  .banner-text h1 {
    color: #000;
    font-size: 17px;
  }
  .banner-text p {
    color: #fff;
    font-size: 13px;
    width: 88%;
  }
  .gold-banner img {
    height: 280px;
  }
  .gold-banner-king {
    height: 300px !important;
  }
  .banner-text {
    top: 9%;
  }
}
.king-background {
  background-image: url();
  border-top: 4px rgba(1, 154, 83, 0.9);
  border-style: solid;
}
.border-blue-bottom {
  border-bottom: 4px #00ad76;
  border-style: solid;
}

.button-1 {
  background-color: blue;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  line-height: 10px;
  list-style: none;
  position: initial;
  margin: 20px;
  outline: 0;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-1:first-child {
  float: right;
  margin-right: 10px;
}

.button-1:last-child {
  float: right;
  margin-left: 10px;
}

@media only screen and (max-width: 460px) {
  .button-1 {
    background-color: blue;
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 30px;
    line-height: 10px;
    list-style: none;
    position: initial;
    margin: 20px;
    outline: 0;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    transition: color 100ms;
    vertical-align: baseline;
    user-select: none;
  }
}

.icon-button__badge {
  position: absolute;
  top: 152px;
  right: 15px;
  width: 25px;
  height: 25px;
  background: white;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
}

@media only screen and (max-width: 800px) {
  .icon-button__badge {
    top: 117px;
    right: 15px;
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
}

.matka-results {
  background-color: #00ad76;
  padding: 10px 15px;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
}
.matka-results h2 {
  color: #fb0;
  flex-grow: 1;
  font-size: 36px;
  font-weight: 600;
  margin-top: 10px;
}
.matka-results button {
  border-radius: 5px;
  margin: 1px 2px;
}

.img-btn {
  border-radius: 10px;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  height: 36px;
  width: 36px;
  margin-left: 5px;
}
.add-fatafat-btn {
  border-radius: 18px;
  padding: 5px 5px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  height: 36px;
  width: 36px;
  font-size: 24px;
  font-weight: bold;
  color: #5cb85c;
  justify-content: center;
  align-items: center;
  display: flex;
}
.save-btn {
  border-radius: 10px;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  height: 36px;
  min-width: 36px;
  margin-left: 5px;
  font-size: 24px;
  font-weight: bold;
  color: #5cb85c;
  justify-content: center;
  align-items: center;
  display: flex;
}